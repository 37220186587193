export default function AuthSvg(props) {
    return <svg {...props} 
    width="711" height="690" viewBox="0 0 711 690" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_15_4316)">
<path d="M614.742 642.154H598.627V642.618H614.742V642.154Z" fill="#EBEBEB"/>
<path d="M177.884 623.017H97.7891V623.481H177.884V623.017Z" fill="#EBEBEB"/>
<path d="M206.145 623.017H194.406V623.481H206.145V623.017Z" fill="#EBEBEB"/>
<path d="M418.038 630.843H244.311V631.306H418.038V630.843Z" fill="#EBEBEB"/>
<path d="M440.032 524.563H81.9338C76.0922 524.563 71.3447 519.815 71.3447 513.974V10.6139C71.3447 4.77235 76.0922 0.0249023 81.9338 0.0249023H440.032C445.874 0.0249023 450.622 4.77235 450.622 10.6139V513.974C450.622 519.815 445.874 524.563 440.032 524.563ZM81.9338 0.488521C76.3518 0.488521 71.8083 5.03198 71.8083 10.6139V513.974C71.8083 519.555 76.3518 524.099 81.9338 524.099H440.032C445.614 524.099 450.158 519.555 450.158 513.974V10.6139C450.158 5.03198 445.614 0.488521 440.032 0.488521H81.9338Z" fill="#EBEBEB"/>
<path d="M841.155 524.563H483.056C477.214 524.563 472.467 519.815 472.467 513.974V10.6139C472.467 4.77235 477.214 0.0249023 483.056 0.0249023H841.155C846.996 0.0249023 851.744 4.77235 851.744 10.6139V513.974C851.744 519.815 846.996 524.563 841.155 524.563ZM483.056 0.488521C477.474 0.488521 472.93 5.03198 472.93 10.6139V513.974C472.93 519.555 477.474 524.099 483.056 524.099H841.155C846.737 524.099 851.28 519.555 851.28 513.974V10.6139C851.28 5.03198 846.737 0.488521 841.155 0.488521H483.056Z" fill="#EBEBEB"/>
<path d="M927.74 607.272H0.503418V607.736H927.74V607.272Z" fill="#EBEBEB"/>
<path d="M277.153 348.035H235.613V524.136H277.153V348.035Z" fill="#E6E6E6"/>
<path d="M346.399 287.988H304.858V524.136H346.399V287.988Z" fill="#E0E0E0"/>
<path d="M415.646 348.035H374.106V524.136H415.646V348.035Z" fill="#EBEBEB"/>
<path d="M138.661 408.083H97.1211V524.136H138.661V408.083Z" fill="#F0F0F0"/>
<path d="M207.907 379.098H166.367V524.136H207.907V379.098Z" fill="#EBEBEB"/>
<path d="M484.892 408.083H443.352V524.136H484.892V408.083Z" fill="#F5F5F5"/>
<path d="M554.137 348.035H512.597V524.136H554.137V348.035Z" fill="#F0F0F0"/>
<path d="M692.629 319.05H651.089V524.136H692.629V319.05Z" fill="#EBEBEB"/>
<path d="M623.383 379.098H581.843V524.136H623.383V379.098Z" fill="#F0F0F0"/>
<path d="M154.65 298.658C158.327 283.078 148.678 267.467 133.098 263.79C117.517 260.112 101.906 269.762 98.229 285.342C94.5518 300.922 104.201 316.533 119.781 320.21C135.361 323.887 150.972 314.238 154.65 298.658Z" fill="#E0E0E0"/>
<path d="M150.429 312.498C161.748 301.179 161.748 282.826 150.429 271.507C139.109 260.187 120.757 260.187 109.437 271.507C98.1177 282.826 98.1177 301.179 109.437 312.498C120.757 323.818 139.109 323.818 150.429 312.498Z" fill="#E6E6E6"/>
<path d="M129.928 313.709C141.911 313.709 151.625 303.995 151.625 292.012C151.625 280.028 141.911 270.314 129.928 270.314C117.945 270.314 108.23 280.028 108.23 292.012C108.23 303.995 117.945 313.709 129.928 313.709Z" fill="#F0F0F0"/>
<path d="M139.458 296.629C140.293 301.432 136.992 304.64 131.076 305.141L130.983 309.109H127.385L127.478 305.141C123.992 304.826 120.784 303.731 118.707 302.359L120.338 297.89C122.49 299.299 125.067 300.189 127.608 300.449L127.775 294.348C124.251 293.346 120.338 292.011 119.578 287.635C118.744 282.832 122.1 279.605 128.164 279.16L128.257 275.191H131.855L131.762 279.197C134.358 279.456 136.843 280.18 138.753 281.255L137.344 285.725C135.601 284.686 133.635 284.074 131.651 283.852L131.502 290.194C134.989 291.177 138.79 292.475 139.514 296.61L139.458 296.629ZM127.849 289.192L127.979 284C125.995 284.389 125.03 285.521 125.234 286.893C125.438 288.006 126.44 288.655 127.831 289.192H127.849ZM133.783 297.556C133.598 296.518 132.652 295.869 131.317 295.386L131.206 300.319C133.079 299.93 134.024 298.91 133.783 297.538V297.556Z" fill="white"/>
<path d="M816.564 47.3699H665.628C657.957 47.3699 651.738 53.5886 651.738 61.2599V118.303C651.738 125.975 657.957 132.193 665.628 132.193H816.564C824.235 132.193 830.454 125.975 830.454 118.303V61.2599C830.454 53.5886 824.235 47.3699 816.564 47.3699Z" fill="#F0F0F0"/>
<path d="M698.136 58.9241H685.563C684.825 58.9241 684.228 59.5219 684.228 60.2593V120.493C684.228 121.23 684.825 121.828 685.563 121.828H698.136C698.874 121.828 699.471 121.23 699.471 120.493V60.2593C699.471 59.5219 698.874 58.9241 698.136 58.9241Z" fill="white"/>
<path d="M794.996 58.5533C794.291 58.201 793.457 58.5162 793.16 59.2395L770.925 107.53C770.498 108.55 769.089 108.661 768.532 107.715L747.818 76.2821C747.429 75.6145 746.557 75.429 745.908 75.8555L720.094 86.2406C719.686 86.5188 719.129 86.5373 718.703 86.2962L690.404 57.7559C689.717 57.385 688.864 57.6632 688.531 58.3679L688.456 58.5162C688.16 59.1467 688.419 59.9071 689.031 60.2594L718.814 89.6157C719.259 89.8568 719.797 89.8383 720.205 89.5601L745.073 79.8056C745.722 79.379 746.576 79.5645 746.984 80.2321L768.94 113.742C769.515 114.688 770.925 114.595 771.333 113.557L795.626 60.2409C795.886 59.5918 795.626 58.8315 794.977 58.5348L794.996 58.5533Z" fill="#E0E0E0"/>
<path d="M410.713 130.099C427.418 130.099 440.96 116.557 440.96 99.8522C440.96 83.1475 427.418 69.6057 410.713 69.6057C394.009 69.6057 380.467 83.1475 380.467 99.8522C380.467 116.557 394.009 130.099 410.713 130.099Z" fill="#E0E0E0"/>
<path opacity="0.8" d="M410.361 124.127C423.768 124.127 434.637 113.258 434.637 99.8517C434.637 86.445 423.768 75.5767 410.361 75.5767C396.955 75.5767 386.086 86.445 386.086 99.8517C386.086 113.258 396.955 124.127 410.361 124.127Z" fill="white"/>
<path d="M410.286 104.321C411.648 104.321 412.753 103.216 412.753 101.854C412.753 100.492 411.648 99.3877 410.286 99.3877C408.924 99.3877 407.82 100.492 407.82 101.854C407.82 103.216 408.924 104.321 410.286 104.321Z" fill="#E0E0E0"/>
<path d="M410.008 102.764L399.567 92.9356L400.587 91.86L410.045 100.761L426.995 85.7959L427.978 86.89L410.008 102.764Z" fill="#E0E0E0"/>
<path d="M464.123 690C662.704 690 823.687 680.601 823.687 669.007C823.687 657.413 662.704 648.015 464.123 648.015C265.541 648.015 104.559 657.413 104.559 669.007C104.559 680.601 265.541 690 464.123 690Z" fill="#F5F5F5"/>
<path d="M525.728 348.202H403.296V670.38H525.728V348.202Z" fill="#248C9A"/>
<path opacity="0.5" d="M526.117 348.202H434.822V670.38H526.117V348.202Z" fill="black"/>
<path d="M685.676 458.044H563.244V670.362H685.676V458.044Z" fill="#248C9A"/>
<path opacity="0.3" d="M685.677 457.524H594.381V670.362H685.677V457.524Z" fill="black"/>
<path d="M365.002 405.652H242.569V670.36H365.002V405.652Z" fill="#248C9A"/>
<path opacity="0.4" d="M365.002 405.004H273.706V670.36H365.002V405.004Z" fill="black"/>
<path d="M290.228 670.716C290.061 674.666 302.541 678.393 318.1 679.042C333.659 679.71 346.418 677.039 346.585 673.108C346.752 669.158 334.271 665.43 318.712 664.781C303.153 664.114 290.395 666.784 290.228 670.716Z" fill="#248C9A"/>
<path d="M290.511 663.558L290.208 670.692L346.57 673.083L346.873 665.95L290.511 663.558Z" fill="#248C9A"/>
<path d="M290.525 663.574C290.358 667.525 302.838 671.252 318.397 671.901C333.956 672.569 346.715 669.898 346.882 665.967C347.049 662.017 334.568 658.289 319.009 657.64C303.45 656.973 290.692 659.643 290.525 663.574Z" fill="#248C9A"/>
<path opacity="0.4" d="M290.525 663.574C290.358 667.525 302.838 671.252 318.397 671.901C333.956 672.569 346.715 669.898 346.882 665.967C347.049 662.017 334.568 658.289 319.009 657.64C303.45 656.973 290.692 659.643 290.525 663.574Z" fill="white"/>
<path d="M294.121 663.446C294.121 663.668 294.195 663.965 294.362 664.15C296.254 661.832 306.546 660.478 318.86 660.998C331.211 661.517 341.355 663.761 343.005 666.227C343.172 666.042 343.302 665.782 343.302 665.541C343.413 662.871 332.509 660.2 318.915 659.625C305.359 659.05 294.232 660.794 294.121 663.464V663.446Z" fill="#248C9A"/>
<path opacity="0.4" d="M294.121 663.446C294.121 663.668 294.195 663.965 294.362 664.15C296.254 661.832 306.546 660.478 318.86 660.998C331.211 661.517 341.355 663.761 343.005 666.227C343.172 666.042 343.302 665.782 343.302 665.541C343.413 662.871 332.509 660.2 318.915 659.625C305.359 659.05 294.232 660.794 294.121 663.464V663.446Z" fill="#248C9A"/>
<path opacity="0.4" d="M294.362 664.15C296.05 666.616 306.194 668.842 318.508 669.379C330.858 669.899 341.151 668.545 343.005 666.227C341.355 663.76 331.211 661.535 318.86 660.997C306.546 660.478 296.254 661.832 294.362 664.15Z" fill="#248C9A"/>
<path d="M284.311 659.903C284.144 663.853 296.625 667.58 312.184 668.229C327.743 668.897 340.502 666.226 340.669 662.295C340.836 658.345 328.355 654.617 312.796 653.968C297.237 653.301 284.478 655.971 284.311 659.903Z" fill="#248C9A"/>
<path d="M284.612 652.783L284.31 659.917L340.672 662.308L340.975 655.175L284.612 652.783Z" fill="#248C9A"/>
<path d="M284.609 652.764C284.442 656.714 296.922 660.442 312.481 661.091C328.04 661.758 340.799 659.088 340.966 655.156C341.133 651.206 328.652 647.479 313.093 646.83C297.534 646.162 284.775 648.833 284.609 652.764Z" fill="#248C9A"/>
<path opacity="0.4" d="M284.609 652.764C284.442 656.714 296.922 660.442 312.481 661.091C328.04 661.758 340.799 659.088 340.966 655.156C341.133 651.206 328.652 647.479 313.093 646.83C297.534 646.162 284.775 648.833 284.609 652.764Z" fill="white"/>
<path d="M288.205 652.653C288.205 652.875 288.279 653.172 288.446 653.358C290.338 651.039 300.63 649.686 312.944 650.205C325.295 650.724 335.439 652.968 337.089 655.435C337.256 655.249 337.386 654.989 337.386 654.748C337.497 652.078 326.593 649.408 312.999 648.833C299.443 648.258 288.316 650.001 288.205 652.671V652.653Z" fill="#248C9A"/>
<path opacity="0.4" d="M288.205 652.653C288.205 652.875 288.279 653.172 288.446 653.358C290.338 651.039 300.63 649.686 312.944 650.205C325.295 650.724 335.439 652.968 337.089 655.435C337.256 655.249 337.386 654.989 337.386 654.748C337.497 652.078 326.593 649.408 312.999 648.833C299.443 648.258 288.316 650.001 288.205 652.671V652.653Z" fill="#248C9A"/>
<path opacity="0.4" d="M288.446 653.357C290.133 655.823 300.277 658.049 312.591 658.586C324.942 659.106 335.234 657.752 337.089 655.434C335.438 652.967 325.294 650.742 312.943 650.204C300.63 649.685 290.337 651.039 288.446 653.357Z" fill="#248C9A"/>
<path d="M285.071 646.885C284.905 650.835 297.385 654.562 312.944 655.211C328.503 655.879 341.262 653.208 341.429 649.277C341.596 645.327 329.115 641.599 313.556 640.95C297.997 640.283 285.238 642.953 285.071 646.885Z" fill="#248C9A"/>
<path d="M285.362 639.751L285.059 646.884L341.421 649.276L341.724 642.143L285.362 639.751Z" fill="#248C9A"/>
<path d="M285.369 639.743C285.202 643.693 297.683 647.421 313.242 648.07C328.801 648.738 341.559 646.067 341.726 642.136C341.893 638.186 329.413 634.458 313.854 633.809C298.295 633.141 285.536 635.812 285.369 639.743Z" fill="#248C9A"/>
<path opacity="0.4" d="M285.369 639.743C285.202 643.693 297.683 647.421 313.242 648.07C328.801 648.738 341.559 646.067 341.726 642.136C341.893 638.186 329.413 634.458 313.854 633.809C298.295 633.141 285.536 635.812 285.369 639.743Z" fill="white"/>
<path d="M288.965 639.633C288.965 639.855 289.039 640.152 289.206 640.338C291.098 638.019 301.39 636.666 313.704 637.185C326.055 637.704 336.199 639.948 337.849 642.415C338.016 642.229 338.146 641.969 338.146 641.728C338.257 639.058 327.353 636.388 313.76 635.813C300.203 635.238 289.077 636.981 288.965 639.651V639.633Z" fill="#248C9A"/>
<path opacity="0.4" d="M288.965 639.633C288.965 639.855 289.039 640.152 289.206 640.338C291.098 638.019 301.39 636.666 313.704 637.185C326.055 637.704 336.199 639.948 337.849 642.415C338.016 642.229 338.146 641.969 338.146 641.728C338.257 639.058 327.353 636.388 313.76 635.813C300.203 635.238 289.077 636.981 288.965 639.651V639.633Z" fill="#248C9A"/>
<path opacity="0.4" d="M289.207 640.319C290.894 642.785 301.038 645.011 313.352 645.548C325.703 646.068 335.995 644.714 337.849 642.396C336.199 639.929 326.055 637.704 313.704 637.166C301.39 636.647 291.098 638.001 289.207 640.319Z" fill="#248C9A"/>
<path d="M279.155 636.073C278.989 640.023 291.469 643.751 307.028 644.4C322.587 645.067 335.346 642.397 335.513 638.466C335.68 634.515 323.199 630.788 307.64 630.139C292.081 629.471 279.322 632.142 279.155 636.073Z" fill="#248C9A"/>
<path d="M279.446 628.903L279.143 636.036L335.505 638.428L335.808 631.295L279.446 628.903Z" fill="#248C9A"/>
<path d="M279.452 628.933C279.285 632.883 291.766 636.611 307.325 637.26C322.884 637.927 335.642 635.257 335.809 631.325C335.976 627.375 323.496 623.648 307.937 622.999C292.378 622.331 279.619 625.002 279.452 628.933Z" fill="#248C9A"/>
<path opacity="0.4" d="M279.452 628.933C279.285 632.883 291.766 636.611 307.325 637.26C322.884 637.927 335.642 635.257 335.809 631.325C335.976 627.375 323.496 623.648 307.937 622.999C292.378 622.331 279.619 625.002 279.452 628.933Z" fill="white"/>
<path d="M283.049 628.822C283.049 629.044 283.124 629.341 283.29 629.527C285.182 627.208 295.474 625.855 307.788 626.374C320.139 626.893 330.283 629.137 331.933 631.604C332.1 631.418 332.23 631.158 332.23 630.917C332.341 628.247 321.437 625.576 307.844 625.002C294.287 624.427 283.161 626.17 283.049 628.84V628.822Z" fill="#248C9A"/>
<path opacity="0.4" d="M283.049 628.822C283.049 629.044 283.124 629.341 283.29 629.527C285.182 627.208 295.474 625.855 307.788 626.374C320.139 626.893 330.283 629.137 331.933 631.604C332.1 631.418 332.23 631.158 332.23 630.917C332.341 628.247 321.437 625.576 307.844 625.002C294.287 624.427 283.161 626.17 283.049 628.84V628.822Z" fill="#248C9A"/>
<path opacity="0.4" d="M283.29 629.526C284.978 631.992 295.122 634.218 307.435 634.755C319.786 635.275 330.078 633.921 331.933 631.603C330.282 629.136 320.138 626.911 307.788 626.373C295.474 625.854 285.182 627.208 283.29 629.526Z" fill="#248C9A"/>
<path d="M288.186 626.912C288.019 630.862 300.5 634.589 316.059 635.238C331.618 635.906 344.377 633.236 344.544 629.304C344.711 625.354 332.23 621.627 316.671 620.978C301.112 620.31 288.353 622.98 288.186 626.912Z" fill="#248C9A"/>
<path d="M288.497 619.789L288.194 626.922L344.556 629.314L344.859 622.181L288.497 619.789Z" fill="#248C9A"/>
<path d="M288.502 619.773C288.335 623.723 300.816 627.451 316.375 628.1C331.934 628.767 344.693 626.097 344.86 622.166C345.026 618.215 332.546 614.488 316.987 613.839C301.428 613.171 288.669 615.842 288.502 619.773Z" fill="#248C9A"/>
<path opacity="0.4" d="M288.502 619.773C288.335 623.723 300.816 627.451 316.375 628.1C331.934 628.767 344.693 626.097 344.86 622.166C345.026 618.215 332.546 614.488 316.987 613.839C301.428 613.171 288.669 615.842 288.502 619.773Z" fill="white"/>
<path d="M292.08 619.662C292.08 619.884 292.154 620.181 292.321 620.367C294.213 618.049 304.505 616.695 316.819 617.214C329.17 617.733 339.314 619.977 340.964 622.444C341.131 622.258 341.261 621.999 341.261 621.757C341.372 619.087 330.468 616.417 316.874 615.842C303.318 615.267 292.191 617.01 292.08 619.68V619.662Z" fill="#248C9A"/>
<path opacity="0.4" d="M292.08 619.662C292.08 619.884 292.154 620.181 292.321 620.367C294.213 618.049 304.505 616.695 316.819 617.214C329.17 617.733 339.314 619.977 340.964 622.444C341.131 622.258 341.261 621.999 341.261 621.757C341.372 619.087 330.468 616.417 316.874 615.842C303.318 615.267 292.191 617.01 292.08 619.68V619.662Z" fill="#248C9A"/>
<path opacity="0.4" d="M292.321 620.366C294.009 622.832 304.153 625.058 316.467 625.595C328.817 626.115 339.11 624.761 340.964 622.443C339.314 619.976 329.17 617.751 316.819 617.213C304.505 616.694 294.213 618.048 292.321 620.366Z" fill="#248C9A"/>
<path d="M282.27 616.12C282.103 620.07 294.584 623.797 310.143 624.446C325.702 625.114 338.461 622.443 338.628 618.512C338.795 614.562 326.314 610.834 310.755 610.185C295.196 609.518 282.437 612.188 282.27 616.12Z" fill="#248C9A"/>
<path d="M282.562 608.959L282.259 616.092L338.622 618.484L338.924 611.351L282.562 608.959Z" fill="#248C9A"/>
<path d="M282.567 608.978C282.4 612.929 294.881 616.656 310.44 617.305C325.999 617.973 338.758 615.302 338.925 611.371C339.091 607.421 326.611 603.693 311.052 603.044C295.493 602.377 282.734 605.047 282.567 608.978Z" fill="#248C9A"/>
<path opacity="0.4" d="M282.567 608.978C282.4 612.929 294.881 616.656 310.44 617.305C325.999 617.973 338.758 615.302 338.925 611.371C339.091 607.421 326.611 603.693 311.052 603.044C295.493 602.377 282.734 605.047 282.567 608.978Z" fill="white"/>
<path d="M286.164 608.849C286.164 609.072 286.238 609.369 286.405 609.554C288.297 607.236 298.589 605.882 310.903 606.402C323.254 606.921 333.397 609.165 335.048 611.631C335.215 611.446 335.345 611.186 335.345 610.945C335.456 608.275 324.552 605.604 310.958 605.029C297.402 604.454 286.275 606.198 286.164 608.868V608.849Z" fill="#248C9A"/>
<path opacity="0.4" d="M286.164 608.849C286.164 609.072 286.238 609.369 286.405 609.554C288.297 607.236 298.589 605.882 310.903 606.402C323.254 606.921 333.397 609.165 335.048 611.631C335.215 611.446 335.345 611.186 335.345 610.945C335.456 608.275 324.552 605.604 310.958 605.029C297.402 604.454 286.275 606.198 286.164 608.868V608.849Z" fill="#248C9A"/>
<path opacity="0.4" d="M286.405 609.554C288.092 612.02 298.236 614.245 310.55 614.783C322.901 615.302 333.193 613.949 335.048 611.631C333.397 609.164 323.253 606.939 310.902 606.401C298.589 605.882 288.296 607.236 286.405 609.554Z" fill="#248C9A"/>
<path d="M206.182 667.264C206.015 671.214 218.496 674.941 234.055 675.59C249.614 676.258 262.372 673.587 262.539 669.656C262.706 665.706 250.226 661.978 234.667 661.329C219.107 660.662 206.349 663.332 206.182 667.264Z" fill="#248C9A"/>
<path d="M206.465 660.09L206.162 667.223L262.524 669.615L262.827 662.482L206.465 660.09Z" fill="#248C9A"/>
<path d="M206.477 660.125C206.31 664.075 218.79 667.803 234.349 668.452C249.909 669.119 262.667 666.449 262.834 662.517C263.001 658.567 250.52 654.84 234.961 654.191C219.402 653.523 206.644 656.194 206.477 660.125Z" fill="#248C9A"/>
<path opacity="0.4" d="M206.477 660.125C206.31 664.075 218.79 667.803 234.349 668.452C249.909 669.119 262.667 666.449 262.834 662.517C263.001 658.567 250.52 654.84 234.961 654.191C219.402 653.523 206.644 656.194 206.477 660.125Z" fill="white"/>
<path d="M210.076 659.996C210.076 660.219 210.15 660.515 210.317 660.701C212.209 658.383 222.501 657.029 234.815 657.548C247.166 658.067 257.31 660.311 258.96 662.778C259.127 662.592 259.257 662.333 259.257 662.092C259.368 659.421 248.464 656.751 234.87 656.176C221.314 655.601 210.187 657.344 210.076 660.015V659.996Z" fill="#248C9A"/>
<path opacity="0.4" d="M210.076 659.996C210.076 660.219 210.15 660.515 210.317 660.701C212.209 658.383 222.501 657.029 234.815 657.548C247.166 658.067 257.31 660.311 258.96 662.778C259.127 662.592 259.257 662.333 259.257 662.092C259.368 659.421 248.464 656.751 234.87 656.176C221.314 655.601 210.187 657.344 210.076 660.015V659.996Z" fill="#248C9A"/>
<path opacity="0.4" d="M210.317 660.7C212.004 663.167 222.148 665.392 234.462 665.93C246.813 666.449 257.105 665.095 258.96 662.777C257.309 660.311 247.165 658.085 234.814 657.548C222.501 657.028 212.208 658.382 210.317 660.7Z" fill="#248C9A"/>
<path d="M230.698 660.291C229.789 664.129 241.324 670.156 256.475 673.773C271.626 677.37 284.645 677.185 285.572 673.346C286.481 669.507 274.946 663.48 259.795 659.864C244.644 656.266 231.607 656.452 230.698 660.291Z" fill="#248C9A"/>
<path d="M232.347 653.388L230.695 660.333L285.577 673.388L287.229 666.442L232.347 653.388Z" fill="#248C9A"/>
<path d="M232.348 653.356C231.439 657.195 242.974 663.222 258.125 666.838C273.276 670.436 286.294 670.251 287.221 666.412C288.13 662.573 276.595 656.546 261.444 652.93C246.293 649.332 233.256 649.517 232.348 653.356Z" fill="#248C9A"/>
<path opacity="0.4" d="M232.348 653.356C231.439 657.195 242.974 663.222 258.125 666.838C273.276 670.436 286.294 670.251 287.221 666.412C288.13 662.573 276.595 656.546 261.444 652.93C246.293 649.332 233.256 649.517 232.348 653.356Z" fill="white"/>
<path d="M235.891 653.912C235.835 654.135 235.872 654.432 235.984 654.654C238.265 652.744 248.65 653.356 260.629 656.212C272.646 659.068 282.197 663.185 283.347 665.929C283.551 665.781 283.718 665.54 283.773 665.317C284.385 662.721 274.186 658.011 260.963 654.877C247.759 651.743 236.503 651.335 235.891 653.931V653.912Z" fill="#248C9A"/>
<path opacity="0.4" d="M235.891 653.912C235.835 654.135 235.872 654.432 235.984 654.654C238.265 652.744 248.65 653.356 260.629 656.212C272.646 659.068 282.197 663.185 283.347 665.929C283.551 665.781 283.718 665.54 283.773 665.317C284.385 662.721 274.186 658.011 260.963 654.877C247.759 651.743 236.503 651.335 235.891 653.931V653.912Z" fill="#248C9A"/>
<path opacity="0.4" d="M236.001 654.654C237.188 657.398 246.72 661.515 258.718 664.371C270.735 667.227 281.12 667.857 283.364 665.929C282.214 663.184 272.664 659.067 260.647 656.211C248.667 653.355 238.282 652.743 236.001 654.654Z" fill="#248C9A"/>
<path d="M302.226 613.761C316.257 613.761 327.632 602.387 327.632 588.355C327.632 574.324 316.257 562.949 302.226 562.949C288.194 562.949 276.819 574.324 276.819 588.355C276.819 602.387 288.194 613.761 302.226 613.761Z" fill="#248C9A"/>
<path d="M305.266 613.761C319.298 613.761 330.672 602.387 330.672 588.355C330.672 574.324 319.298 562.949 305.266 562.949C291.235 562.949 279.86 574.324 279.86 588.355C279.86 602.387 291.235 613.761 305.266 613.761Z" fill="#248C9A"/>
<path opacity="0.5" d="M305.266 613.761C319.298 613.761 330.672 602.387 330.672 588.355C330.672 574.324 319.298 562.949 305.266 562.949C291.235 562.949 279.86 574.324 279.86 588.355C279.86 602.387 291.235 613.761 305.266 613.761Z" fill="white"/>
<path d="M318.732 601.817C326.162 594.386 326.162 582.339 318.732 574.909C311.301 567.478 299.254 567.478 291.824 574.909C284.393 582.339 284.393 594.386 291.824 601.817C299.254 609.247 311.301 609.247 318.732 601.817Z" fill="#248C9A"/>
<path opacity="0.3" d="M316.096 586.54C315.633 584.945 314.854 583.499 313.871 582.275L314.891 581.551L316.653 580.309L312.777 574.504L310.348 576.173L313.407 580.012L313.092 580.216L312.295 580.717C310.848 579.53 309.142 578.733 307.269 578.491V581.422C308.141 581.57 308.975 581.885 309.736 582.349L307.269 583.888V602.229C308.308 602.099 309.309 601.784 310.218 601.32C312.981 599.966 315.132 597.351 316.078 594.125C316.467 592.808 316.152 591.399 315.225 590.379C316.152 589.34 316.467 587.894 316.078 586.577L316.096 586.54ZM314.001 591.454C313.686 594.236 312.258 596.61 310.236 598.019V597.852L310.162 593.327L310.069 587.3L310.032 585.001L311.646 583.869C312.907 585.26 313.778 587.115 314.001 589.192C314.038 589.563 311.164 588.71 311.164 590.323C311.164 592.252 314.038 591.083 314.001 591.454ZM297.051 590.323C296.142 591.362 295.808 592.771 296.198 594.088C297.162 597.314 299.313 599.948 302.058 601.283C302.985 601.747 303.987 602.043 305.007 602.192V583.851L302.54 582.312C303.301 581.867 304.117 581.551 305.007 581.384V578.454C303.134 578.714 301.428 579.493 299.981 580.68L299.184 580.179L298.868 579.975L301.928 576.136L299.499 574.467L295.623 580.272L297.385 581.514L298.405 582.238C297.422 583.443 296.643 584.908 296.179 586.503C295.79 587.819 296.105 589.266 297.032 590.286L297.051 590.323ZM301.131 590.323C301.131 588.71 298.256 589.563 298.293 589.192C298.535 587.115 299.388 585.26 300.649 583.869L302.262 585.001L302.225 587.3L302.132 593.327L302.058 597.852V598.019C300.037 596.61 298.609 594.217 298.293 591.454C298.256 591.083 301.131 592.252 301.131 590.323Z" fill="white"/>
<path d="M661.196 674.182C661.047 678.133 648.326 680.877 632.748 680.302C617.189 679.746 604.671 676.093 604.82 672.143C604.968 668.193 617.69 665.448 633.267 666.004C648.826 666.561 661.344 670.214 661.196 674.164V674.182Z" fill="#248C9A"/>
<path d="M605.025 664.968L604.77 672.103L661.146 674.121L661.402 666.986L605.025 664.968Z" fill="#248C9A"/>
<path d="M661.455 667.042C661.307 670.992 648.585 673.737 633.007 673.181C617.448 672.624 604.931 668.971 605.079 665.021C605.227 661.071 617.949 658.326 633.527 658.901C649.086 659.458 661.603 663.111 661.455 667.061V667.042Z" fill="#248C9A"/>
<path opacity="0.4" d="M661.455 667.042C661.307 670.992 648.585 673.737 633.007 673.181C617.448 672.624 604.931 668.971 605.079 665.021C605.227 661.071 617.949 658.326 633.527 658.901C649.086 659.458 661.603 663.111 661.455 667.061V667.042Z" fill="white"/>
<path d="M657.875 666.653C657.875 666.875 657.764 667.154 657.579 667.339C655.872 664.873 645.71 662.721 633.396 662.276C621.045 661.831 610.753 663.259 608.917 665.596C608.75 665.41 608.658 665.132 608.676 664.891C608.769 662.221 619.859 660.403 633.452 660.885C647.008 661.368 657.968 663.982 657.875 666.653Z" fill="#248C9A"/>
<path opacity="0.4" d="M657.875 666.653C657.875 666.875 657.764 667.154 657.579 667.339C655.872 664.873 645.71 662.721 633.396 662.276C621.045 661.831 610.753 663.259 608.917 665.596C608.75 665.41 608.658 665.132 608.676 664.891C608.769 662.221 619.859 660.403 633.452 660.885C647.008 661.368 657.968 663.982 657.875 666.653Z" fill="#248C9A"/>
<path opacity="0.4" d="M657.58 667.321C655.707 669.657 645.414 671.085 633.101 670.64C620.75 670.195 610.587 668.025 608.918 665.578C610.754 663.241 621.047 661.813 633.398 662.258C645.711 662.703 655.874 664.854 657.58 667.321Z" fill="#248C9A"/>
<path d="M667.945 663.869C667.796 667.819 655.075 670.564 639.497 669.989C623.938 669.433 611.42 665.779 611.569 661.829C611.717 657.879 624.439 655.135 640.016 655.691C655.575 656.247 668.093 659.901 667.945 663.851V663.869Z" fill="#248C9A"/>
<path d="M611.851 654.757L611.595 661.893L667.971 663.92L668.228 656.785L611.851 654.757Z" fill="#248C9A"/>
<path d="M668.204 656.73C668.056 660.68 655.334 663.424 639.756 662.868C624.197 662.312 611.68 658.658 611.828 654.708C611.976 650.758 624.698 648.014 640.276 648.57C655.835 649.126 668.352 652.78 668.204 656.73Z" fill="#248C9A"/>
<path opacity="0.4" d="M668.204 656.73C668.056 660.68 655.334 663.424 639.756 662.868C624.197 662.312 611.68 658.658 611.828 654.708C611.976 650.758 624.698 648.014 640.276 648.57C655.835 649.126 668.352 652.78 668.204 656.73Z" fill="white"/>
<path d="M664.625 656.34C664.625 656.563 664.514 656.841 664.328 657.027C662.622 654.56 652.46 652.409 640.146 651.964C627.795 651.519 617.503 652.947 615.667 655.283C615.5 655.098 615.407 654.82 615.426 654.579C615.518 651.908 626.608 650.091 640.201 650.573C653.758 651.055 664.718 653.67 664.625 656.34Z" fill="#248C9A"/>
<path opacity="0.4" d="M664.625 656.34C664.625 656.563 664.514 656.841 664.328 657.027C662.622 654.56 652.46 652.409 640.146 651.964C627.795 651.519 617.503 652.947 615.667 655.283C615.5 655.098 615.407 654.82 615.426 654.579C615.518 651.908 626.608 650.091 640.201 650.573C653.758 651.055 664.718 653.67 664.625 656.34Z" fill="#248C9A"/>
<path opacity="0.4" d="M664.329 657.009C662.456 659.345 652.164 660.773 639.85 660.328C627.5 659.883 617.337 657.732 615.668 655.265C617.504 652.929 627.796 651.501 640.147 651.946C652.461 652.391 662.623 654.542 664.329 657.009Z" fill="#248C9A"/>
<path d="M668.205 650.831C668.057 654.781 655.335 657.526 639.758 656.951C624.199 656.395 611.681 652.741 611.829 648.791C611.978 644.841 624.699 642.097 640.277 642.653C655.836 643.209 668.354 646.863 668.205 650.813V650.831Z" fill="#248C9A"/>
<path d="M612.112 641.714L611.855 648.849L668.232 650.877L668.489 643.742L612.112 641.714Z" fill="#248C9A"/>
<path d="M668.465 643.692C668.316 647.642 655.595 650.386 640.017 649.83C624.458 649.274 611.94 645.62 612.089 641.67C612.237 637.72 624.959 634.976 640.536 635.55C656.095 636.107 668.613 639.76 668.465 643.71V643.692Z" fill="#248C9A"/>
<path opacity="0.4" d="M668.465 643.692C668.316 647.642 655.595 650.386 640.017 649.83C624.458 649.274 611.94 645.62 612.089 641.67C612.237 637.72 624.959 634.976 640.536 635.55C656.095 636.107 668.613 639.76 668.465 643.71V643.692Z" fill="white"/>
<path d="M664.886 643.284C664.886 643.507 664.774 643.785 664.589 643.971C662.883 641.504 652.72 639.353 640.407 638.908C628.056 638.463 617.763 639.891 615.927 642.227C615.761 642.042 615.668 641.764 615.686 641.523C615.779 638.852 626.869 637.035 640.462 637.517C654.018 637.999 664.978 640.614 664.886 643.284Z" fill="#248C9A"/>
<path opacity="0.4" d="M664.886 643.284C664.886 643.507 664.774 643.785 664.589 643.971C662.883 641.504 652.72 639.353 640.407 638.908C628.056 638.463 617.763 639.891 615.927 642.227C615.761 642.042 615.668 641.764 615.686 641.523C615.779 638.852 626.869 637.035 640.462 637.517C654.018 637.999 664.978 640.614 664.886 643.284Z" fill="#248C9A"/>
<path opacity="0.4" d="M664.59 643.971C662.717 646.307 652.425 647.735 640.111 647.29C627.76 646.845 617.598 644.675 615.929 642.227C617.765 639.891 628.057 638.463 640.408 638.908C652.721 639.353 662.884 641.504 664.59 643.971Z" fill="#248C9A"/>
<path d="M674.955 640.521C674.807 644.471 662.085 647.215 646.507 646.641C630.948 646.084 618.431 642.431 618.579 638.481C618.727 634.531 631.449 631.786 647.027 632.343C662.586 632.899 675.103 636.552 674.955 640.502V640.521Z" fill="#248C9A"/>
<path d="M618.779 631.317L618.523 638.452L674.9 640.47L675.156 633.335L618.779 631.317Z" fill="#248C9A"/>
<path d="M675.196 633.382C675.048 637.332 662.326 640.077 646.749 639.521C631.189 638.964 618.672 635.311 618.82 631.361C618.969 627.411 631.69 624.666 647.268 625.241C662.827 625.797 675.344 629.451 675.196 633.401V633.382Z" fill="#248C9A"/>
<path opacity="0.4" d="M675.196 633.382C675.048 637.332 662.326 640.077 646.749 639.521C631.189 638.964 618.672 635.311 618.82 631.361C618.969 627.411 631.69 624.666 647.268 625.241C662.827 625.797 675.344 629.451 675.196 633.401V633.382Z" fill="white"/>
<path d="M671.638 632.975C671.638 633.198 671.526 633.476 671.341 633.661C669.635 631.195 659.472 629.044 647.159 628.599C634.808 628.153 624.515 629.581 622.679 631.918C622.513 631.733 622.42 631.454 622.438 631.213C622.531 628.543 633.621 626.726 647.214 627.208C660.77 627.69 671.73 630.305 671.638 632.975Z" fill="#248C9A"/>
<path opacity="0.4" d="M671.638 632.975C671.638 633.198 671.526 633.476 671.341 633.661C669.635 631.195 659.472 629.044 647.159 628.599C634.808 628.153 624.515 629.581 622.679 631.918C622.513 631.733 622.42 631.454 622.438 631.213C622.531 628.543 633.621 626.726 647.214 627.208C660.77 627.69 671.73 630.305 671.638 632.975Z" fill="#248C9A"/>
<path opacity="0.4" d="M671.34 633.661C669.467 635.998 659.174 637.426 646.861 636.981C634.51 636.535 624.347 634.366 622.678 631.918C624.514 629.581 634.806 628.153 647.157 628.598C659.471 629.043 669.633 631.195 671.34 633.661Z" fill="#248C9A"/>
<path d="M451.085 666.263C450.918 670.213 463.398 673.94 478.957 674.59C494.516 675.257 507.275 672.587 507.442 668.655C507.609 664.705 495.128 660.978 479.569 660.329C464.01 659.661 451.252 662.331 451.085 666.263Z" fill="#248C9A"/>
<path d="M451.368 659.1L451.065 666.233L507.427 668.625L507.73 661.492L451.368 659.1Z" fill="#248C9A"/>
<path d="M451.382 659.123C451.215 663.073 463.695 666.801 479.254 667.468C494.813 668.136 507.572 665.466 507.739 661.534C507.906 657.584 495.425 653.857 479.866 653.208C464.307 652.54 451.548 655.21 451.382 659.142V659.123Z" fill="#248C9A"/>
<path opacity="0.4" d="M451.382 659.123C451.215 663.073 463.695 666.801 479.254 667.468C494.813 668.136 507.572 665.466 507.739 661.534C507.906 657.584 495.425 653.857 479.866 653.208C464.307 652.54 451.548 655.21 451.382 659.142V659.123Z" fill="white"/>
<path d="M454.978 658.992C454.978 659.215 455.052 659.512 455.219 659.697C457.111 657.379 467.403 656.025 479.717 656.545C492.067 657.064 502.211 659.308 503.862 661.774C504.029 661.589 504.159 661.329 504.159 661.088C504.27 658.418 493.366 655.747 479.772 655.172C466.216 654.597 455.089 656.341 454.978 659.011V658.992Z" fill="#248C9A"/>
<path opacity="0.4" d="M454.978 658.992C454.978 659.215 455.052 659.512 455.219 659.697C457.111 657.379 467.403 656.025 479.717 656.545C492.067 657.064 502.211 659.308 503.862 661.774C504.029 661.589 504.159 661.329 504.159 661.088C504.27 658.418 493.366 655.747 479.772 655.172C466.216 654.597 455.089 656.341 454.978 659.011V658.992Z" fill="#248C9A"/>
<path opacity="0.4" d="M455.219 659.699C456.907 662.165 467.051 664.391 479.364 664.929C491.715 665.448 502.008 664.094 503.862 661.776C502.212 659.309 492.068 657.084 479.717 656.546C467.403 656.027 457.111 657.381 455.219 659.699Z" fill="#248C9A"/>
<path d="M445.149 655.454C444.982 659.404 457.462 663.131 473.021 663.78C488.58 664.448 501.339 661.777 501.506 657.846C501.673 653.896 489.192 650.168 473.633 649.519C458.074 648.852 445.316 651.522 445.149 655.454Z" fill="#248C9A"/>
<path d="M445.45 648.345L445.147 655.479L501.509 657.87L501.812 650.737L445.45 648.345Z" fill="#248C9A"/>
<path d="M445.465 648.312C445.298 652.263 457.778 655.99 473.337 656.639C488.896 657.307 501.655 654.636 501.822 650.705C501.989 646.755 489.508 643.027 473.949 642.378C458.39 641.711 445.631 644.381 445.465 648.312Z" fill="#248C9A"/>
<path opacity="0.4" d="M445.465 648.312C445.298 652.263 457.778 655.99 473.337 656.639C488.896 657.307 501.655 654.636 501.822 650.705C501.989 646.755 489.508 643.027 473.949 642.378C458.39 641.711 445.631 644.381 445.465 648.312Z" fill="white"/>
<path d="M449.042 648.184C449.042 648.406 449.117 648.703 449.284 648.888C451.175 646.57 461.467 645.216 473.781 645.736C486.132 646.255 496.276 648.499 497.926 650.965C498.093 650.78 498.223 650.52 498.223 650.279C498.334 647.609 487.43 644.938 473.837 644.363C460.281 643.789 449.154 645.532 449.042 648.202V648.184Z" fill="#248C9A"/>
<path opacity="0.4" d="M449.042 648.184C449.042 648.406 449.117 648.703 449.284 648.888C451.175 646.57 461.467 645.216 473.781 645.736C486.132 646.255 496.276 648.499 497.926 650.965C498.093 650.78 498.223 650.52 498.223 650.279C498.334 647.609 487.43 644.938 473.837 644.363C460.281 643.789 449.154 645.532 449.042 648.202V648.184Z" fill="#248C9A"/>
<path opacity="0.4" d="M449.286 648.887C450.973 651.354 461.117 653.579 473.431 654.117C485.782 654.636 496.074 653.282 497.928 650.964C496.278 648.498 486.134 646.273 473.783 645.735C461.47 645.216 451.177 646.569 449.286 648.887Z" fill="#248C9A"/>
<path d="M445.929 642.433C445.762 646.383 458.243 650.11 473.802 650.759C489.361 651.427 502.12 648.756 502.287 644.825C502.454 640.875 489.973 637.147 474.414 636.498C458.855 635.831 446.096 638.501 445.929 642.433Z" fill="#248C9A"/>
<path d="M446.22 635.293L445.917 642.427L502.279 644.818L502.582 637.685L446.22 635.293Z" fill="#248C9A"/>
<path d="M446.227 635.293C446.06 639.244 458.54 642.971 474.099 643.62C489.659 644.288 502.417 641.617 502.584 637.686C502.751 633.736 490.27 630.008 474.711 629.359C459.152 628.692 446.394 631.362 446.227 635.293Z" fill="#248C9A"/>
<path opacity="0.4" d="M446.227 635.293C446.06 639.244 458.54 642.971 474.099 643.62C489.659 644.288 502.417 641.617 502.584 637.686C502.751 633.736 490.27 630.008 474.711 629.359C459.152 628.692 446.394 631.362 446.227 635.293Z" fill="white"/>
<path d="M449.823 635.165C449.823 635.387 449.897 635.684 450.064 635.869C451.956 633.551 462.248 632.197 474.562 632.717C486.913 633.236 497.057 635.48 498.707 637.946C498.874 637.761 499.004 637.501 499.004 637.26C499.115 634.59 488.211 631.919 474.618 631.344C461.061 630.769 449.935 632.513 449.823 635.183V635.165Z" fill="#248C9A"/>
<path opacity="0.4" d="M449.823 635.165C449.823 635.387 449.897 635.684 450.064 635.869C451.956 633.551 462.248 632.197 474.562 632.717C486.913 633.236 497.057 635.48 498.707 637.946C498.874 637.761 499.004 637.501 499.004 637.26C499.115 634.59 488.211 631.919 474.618 631.344C461.061 630.769 449.935 632.513 449.823 635.183V635.165Z" fill="#248C9A"/>
<path opacity="0.4" d="M450.066 635.868C451.754 638.335 461.898 640.56 474.212 641.098C486.562 641.617 496.855 640.263 498.709 637.945C497.059 635.479 486.915 633.253 474.564 632.715C462.25 632.196 451.958 633.55 450.066 635.868Z" fill="#248C9A"/>
<path d="M439.996 631.621C439.829 635.571 452.309 639.299 467.869 639.948C483.428 640.616 496.186 637.945 496.353 634.014C496.52 630.064 484.04 626.336 468.481 625.687C452.921 625.019 440.163 627.69 439.996 631.621Z" fill="#248C9A"/>
<path d="M440.266 624.462L439.963 631.595L496.326 633.987L496.628 626.854L440.266 624.462Z" fill="#248C9A"/>
<path d="M440.31 624.481C440.143 628.431 452.624 632.159 468.183 632.808C483.742 633.475 496.501 630.805 496.668 626.873C496.835 622.923 484.354 619.196 468.795 618.547C453.236 617.879 440.477 620.55 440.31 624.481Z" fill="#248C9A"/>
<path opacity="0.4" d="M440.31 624.481C440.143 628.431 452.624 632.159 468.183 632.808C483.742 633.475 496.501 630.805 496.668 626.873C496.835 622.923 484.354 619.196 468.795 618.547C453.236 617.879 440.477 620.55 440.31 624.481Z" fill="white"/>
<path d="M443.908 624.37C443.908 624.592 443.982 624.889 444.149 625.075C446.04 622.757 456.333 621.403 468.646 621.922C480.997 622.441 491.141 624.685 492.792 627.152C492.958 626.966 493.088 626.707 493.088 626.465C493.2 623.795 482.295 621.125 468.702 620.55C455.146 619.975 444.019 621.718 443.908 624.388V624.37Z" fill="#248C9A"/>
<path opacity="0.4" d="M443.908 624.37C443.908 624.592 443.982 624.889 444.149 625.075C446.04 622.757 456.333 621.403 468.646 621.922C480.997 622.441 491.141 624.685 492.792 627.152C492.958 626.966 493.088 626.707 493.088 626.465C493.2 623.795 482.295 621.125 468.702 620.55C455.146 619.975 444.019 621.718 443.908 624.388V624.37Z" fill="#248C9A"/>
<path opacity="0.4" d="M444.13 625.056C445.818 627.523 455.962 629.748 468.276 630.286C480.626 630.805 490.919 629.451 492.773 627.133C491.123 624.667 480.979 622.441 468.628 621.903C456.314 621.384 446.022 622.738 444.13 625.056Z" fill="#248C9A"/>
<path d="M449.046 622.46C448.879 626.41 461.359 630.138 476.918 630.787C492.477 631.454 505.236 628.784 505.403 624.852C505.57 620.902 493.089 617.175 477.53 616.526C461.971 615.858 449.213 618.529 449.046 622.46Z" fill="#248C9A"/>
<path d="M449.337 615.33L449.035 622.463L505.397 624.855L505.7 617.722L449.337 615.33Z" fill="#248C9A"/>
<path d="M449.342 615.32C449.175 619.27 461.656 622.998 477.215 623.665C492.774 624.333 505.533 621.662 505.699 617.731C505.866 613.781 493.386 610.053 477.827 609.404C462.268 608.737 449.509 611.407 449.342 615.339V615.32Z" fill="#248C9A"/>
<path opacity="0.4" d="M449.342 615.32C449.175 619.27 461.656 622.998 477.215 623.665C492.774 624.333 505.533 621.662 505.699 617.731C505.866 613.781 493.386 610.053 477.827 609.404C462.268 608.737 449.509 611.407 449.342 615.339V615.32Z" fill="white"/>
<path d="M452.941 615.21C452.941 615.432 453.015 615.729 453.182 615.914C455.074 613.596 465.366 612.243 477.68 612.762C490.03 613.281 500.174 615.525 501.825 617.991C501.992 617.806 502.122 617.546 502.122 617.305C502.233 614.635 491.328 611.964 477.735 611.39C464.179 610.815 453.052 612.558 452.941 615.228V615.21Z" fill="#248C9A"/>
<path opacity="0.4" d="M452.941 615.21C452.941 615.432 453.015 615.729 453.182 615.914C455.074 613.596 465.366 612.243 477.68 612.762C490.03 613.281 500.174 615.525 501.825 617.991C501.992 617.806 502.122 617.546 502.122 617.305C502.233 614.635 491.328 611.964 477.735 611.39C464.179 610.815 453.052 612.558 452.941 615.228V615.21Z" fill="#248C9A"/>
<path opacity="0.4" d="M453.182 615.896C454.869 618.362 465.013 620.588 477.327 621.126C489.678 621.645 499.97 620.291 501.824 617.973C500.174 615.507 490.03 613.281 477.679 612.743C465.366 612.224 455.073 613.578 453.182 615.896Z" fill="#248C9A"/>
<path d="M443.128 611.648C442.961 615.598 455.442 619.325 471.001 619.975C486.56 620.642 499.319 617.972 499.486 614.04C499.652 610.09 487.172 606.363 471.613 605.714C456.054 605.046 443.295 607.717 443.128 611.648Z" fill="#248C9A"/>
<path d="M443.42 604.5L443.118 611.633L499.48 614.025L499.783 606.892L443.42 604.5Z" fill="#248C9A"/>
<path d="M443.425 604.509C443.258 608.459 455.739 612.187 471.298 612.836C486.857 613.503 499.616 610.833 499.782 606.902C499.949 602.952 487.469 599.224 471.91 598.575C456.351 597.907 443.592 600.578 443.425 604.509Z" fill="#248C9A"/>
<path opacity="0.4" d="M443.425 604.509C443.258 608.459 455.739 612.187 471.298 612.836C486.857 613.503 499.616 610.833 499.782 606.902C499.949 602.952 487.469 599.224 471.91 598.575C456.351 597.907 443.592 600.578 443.425 604.509Z" fill="white"/>
<path d="M447.024 604.398C447.024 604.621 447.098 604.917 447.265 605.103C449.157 602.785 459.449 601.431 471.763 601.95C484.113 602.469 494.257 604.713 495.908 607.18C496.075 606.994 496.205 606.735 496.205 606.494C496.316 603.823 485.412 601.153 471.818 600.578C458.262 600.003 447.135 601.746 447.024 604.417V604.398Z" fill="#248C9A"/>
<path opacity="0.4" d="M447.024 604.398C447.024 604.621 447.098 604.917 447.265 605.103C449.157 602.785 459.449 601.431 471.763 601.95C484.113 602.469 494.257 604.713 495.908 607.18C496.075 606.994 496.205 606.735 496.205 606.494C496.316 603.823 485.412 601.153 471.818 600.578C458.262 600.003 447.135 601.746 447.024 604.417V604.398Z" fill="#248C9A"/>
<path opacity="0.4" d="M447.265 605.102C448.952 607.569 459.096 609.794 471.41 610.332C483.761 610.851 494.053 609.498 495.907 607.179C494.257 604.713 484.113 602.488 471.762 601.95C459.449 601.431 449.156 602.784 447.265 605.102Z" fill="#248C9A"/>
<path d="M505.382 603.99C505.549 607.94 493.068 611.667 477.509 612.316C461.95 612.984 449.192 610.313 449.025 606.382C448.858 602.432 461.338 598.705 476.897 598.055C492.456 597.388 505.215 600.058 505.382 603.99Z" fill="#248C9A"/>
<path d="M505.063 596.869L448.701 599.261L449.003 606.394L505.366 604.002L505.063 596.869Z" fill="#248C9A"/>
<path d="M505.067 596.85C505.234 600.8 492.753 604.528 477.194 605.195C461.635 605.863 448.877 603.193 448.71 599.261C448.543 595.311 461.023 591.584 476.582 590.935C492.141 590.267 504.9 592.937 505.067 596.869V596.85Z" fill="#248C9A"/>
<path opacity="0.4" d="M505.067 596.85C505.234 600.8 492.753 604.528 477.194 605.195C461.635 605.863 448.877 603.193 448.71 599.261C448.543 595.311 461.023 591.584 476.582 590.935C492.141 590.267 504.9 592.937 505.067 596.869V596.85Z" fill="white"/>
<path d="M501.47 596.72C501.47 596.942 501.395 597.239 501.228 597.424C499.337 595.106 489.045 593.752 476.731 594.272C464.38 594.791 454.236 597.035 452.586 599.501C452.419 599.316 452.289 599.056 452.289 598.815C452.178 596.145 463.082 593.474 476.675 592.899C490.231 592.324 501.358 594.068 501.47 596.738V596.72Z" fill="#248C9A"/>
<path opacity="0.4" d="M501.47 596.72C501.47 596.942 501.395 597.239 501.228 597.424C499.337 595.106 489.045 593.752 476.731 594.272C464.38 594.791 454.236 597.035 452.586 599.501C452.419 599.316 452.289 599.056 452.289 598.815C452.178 596.145 463.082 593.474 476.675 592.899C490.231 592.324 501.358 594.068 501.47 596.738V596.72Z" fill="#248C9A"/>
<path opacity="0.4" d="M501.228 597.426C499.54 599.892 489.396 602.118 477.083 602.656C464.732 603.175 454.439 601.821 452.585 599.503C454.235 597.037 464.379 594.811 476.73 594.273C489.044 593.754 499.336 595.108 501.228 597.426Z" fill="#248C9A"/>
<path d="M511.299 593.178C511.465 597.128 498.985 600.856 483.426 601.505C467.867 602.172 455.108 599.502 454.941 595.57C454.774 591.62 467.255 587.893 482.814 587.244C498.373 586.576 511.132 589.247 511.299 593.178Z" fill="#248C9A"/>
<path d="M510.998 586.021L454.635 588.413L454.938 595.546L511.3 593.154L510.998 586.021Z" fill="#248C9A"/>
<path d="M510.983 586.039C511.15 589.989 498.669 593.717 483.11 594.366C467.551 595.033 454.793 592.363 454.626 588.431C454.459 584.481 466.939 580.754 482.498 580.105C498.057 579.437 510.816 582.108 510.983 586.039Z" fill="#248C9A"/>
<path opacity="0.4" d="M510.983 586.039C511.15 589.989 498.669 593.717 483.11 594.366C467.551 595.033 454.793 592.363 454.626 588.431C454.459 584.481 466.939 580.754 482.498 580.105C498.057 579.437 510.816 582.108 510.983 586.039Z" fill="white"/>
<path d="M507.404 585.91C507.404 586.133 507.33 586.43 507.163 586.615C505.271 584.297 494.979 582.943 482.665 583.463C470.315 583.982 460.171 586.226 458.52 588.692C458.353 588.507 458.224 588.247 458.224 588.006C458.112 585.336 469.017 582.665 482.61 582.09C496.166 581.515 507.293 583.259 507.404 585.929V585.91Z" fill="#248C9A"/>
<path opacity="0.4" d="M507.404 585.91C507.404 586.133 507.33 586.43 507.163 586.615C505.271 584.297 494.979 582.943 482.665 583.463C470.315 583.982 460.171 586.226 458.52 588.692C458.353 588.507 458.224 588.247 458.224 588.006C458.112 585.336 469.017 582.665 482.61 582.09C496.166 581.515 507.293 583.259 507.404 585.929V585.91Z" fill="#248C9A"/>
<path opacity="0.4" d="M507.162 586.614C505.475 589.081 495.331 591.306 483.017 591.844C470.666 592.363 460.374 591.01 458.52 588.691C460.17 586.225 470.314 584 482.665 583.462C494.978 582.943 505.271 584.296 507.162 586.614Z" fill="#248C9A"/>
<path d="M510.517 580.159C510.684 584.109 498.203 587.836 482.644 588.486C467.085 589.153 454.326 586.483 454.159 582.551C453.992 578.601 466.473 574.874 482.032 574.225C497.591 573.557 510.35 576.227 510.517 580.159Z" fill="#248C9A"/>
<path d="M510.221 573.027L453.859 575.419L454.162 582.552L510.524 580.16L510.221 573.027Z" fill="#248C9A"/>
<path d="M510.222 573.021C510.389 576.971 497.909 580.698 482.35 581.347C466.791 582.015 454.032 579.344 453.865 575.413C453.698 571.463 466.179 567.735 481.738 567.086C497.297 566.419 510.055 569.089 510.222 573.021Z" fill="#248C9A"/>
<path opacity="0.4" d="M510.222 573.021C510.389 576.971 497.909 580.698 482.35 581.347C466.791 582.015 454.032 579.344 453.865 575.413C453.698 571.463 466.179 567.735 481.738 567.086C497.297 566.419 510.055 569.089 510.222 573.021Z" fill="white"/>
<path d="M506.623 572.892C506.623 573.114 506.549 573.411 506.382 573.596C504.49 571.278 494.198 569.924 481.884 570.444C469.533 570.963 459.389 573.207 457.739 575.673C457.572 575.488 457.442 575.228 457.442 574.987C457.331 572.317 468.235 569.646 481.829 569.071C495.385 568.497 506.512 570.24 506.623 572.91V572.892Z" fill="#248C9A"/>
<path opacity="0.4" d="M506.623 572.892C506.623 573.114 506.549 573.411 506.382 573.596C504.49 571.278 494.198 569.924 481.884 570.444C469.533 570.963 459.389 573.207 457.739 575.673C457.572 575.488 457.442 575.228 457.442 574.987C457.331 572.317 468.235 569.646 481.829 569.071C495.385 568.497 506.512 570.24 506.623 572.91V572.892Z" fill="#248C9A"/>
<path opacity="0.4" d="M506.383 573.595C504.696 576.062 494.552 578.287 482.238 578.825C469.887 579.344 459.595 577.99 457.74 575.672C459.391 573.206 469.535 570.98 481.885 570.443C494.199 569.923 504.492 571.277 506.383 573.595Z" fill="#248C9A"/>
<path d="M516.453 569.347C516.62 573.297 504.14 577.025 488.581 577.674C473.022 578.342 460.263 575.671 460.096 571.74C459.929 567.79 472.41 564.062 487.969 563.413C503.528 562.746 516.286 565.416 516.453 569.347Z" fill="#248C9A"/>
<path d="M516.12 562.234L459.757 564.625L460.06 571.759L516.422 569.367L516.12 562.234Z" fill="#248C9A"/>
<path d="M516.138 562.208C516.305 566.158 503.825 569.886 488.266 570.535C472.707 571.202 459.948 568.532 459.781 564.601C459.614 560.65 472.095 556.923 487.654 556.274C503.213 555.606 515.971 558.277 516.138 562.208Z" fill="#248C9A"/>
<path opacity="0.4" d="M516.138 562.208C516.305 566.158 503.825 569.886 488.266 570.535C472.707 571.202 459.948 568.532 459.781 564.601C459.614 560.65 472.095 556.923 487.654 556.274C503.213 555.606 515.971 558.277 516.138 562.208Z" fill="white"/>
<path d="M512.559 562.08C512.559 562.302 512.485 562.599 512.318 562.784C510.426 560.466 500.134 559.112 487.82 559.632C475.469 560.151 465.325 562.395 463.675 564.861C463.508 564.676 463.378 564.416 463.378 564.175C463.267 561.505 474.171 558.834 487.765 558.259C501.321 557.685 512.448 559.428 512.559 562.098V562.08Z" fill="#248C9A"/>
<path opacity="0.4" d="M512.559 562.08C512.559 562.302 512.485 562.599 512.318 562.784C510.426 560.466 500.134 559.112 487.82 559.632C475.469 560.151 465.325 562.395 463.675 564.861C463.508 564.676 463.378 564.416 463.378 564.175C463.267 561.505 474.171 558.834 487.765 558.259C501.321 557.685 512.448 559.428 512.559 562.098V562.08Z" fill="#248C9A"/>
<path opacity="0.4" d="M512.318 562.783C510.63 565.25 500.486 567.475 488.172 568.013C475.822 568.532 465.529 567.178 463.675 564.86C465.325 562.394 475.469 560.169 487.82 559.631C500.134 559.112 510.426 560.465 512.318 562.783Z" fill="#248C9A"/>
<path d="M507.402 560.187C507.569 564.137 495.088 567.864 479.529 568.513C463.97 569.181 451.212 566.511 451.045 562.579C450.878 558.629 463.358 554.902 478.917 554.252C494.476 553.585 507.235 556.255 507.402 560.187Z" fill="#248C9A"/>
<path d="M507.116 553.037L450.753 555.429L451.056 562.562L507.418 560.17L507.116 553.037Z" fill="#248C9A"/>
<path d="M507.107 553.047C507.274 556.997 494.793 560.725 479.234 561.392C463.675 562.06 450.916 559.389 450.749 555.458C450.582 551.508 463.063 547.78 478.622 547.131C494.181 546.464 506.94 549.134 507.107 553.066V553.047Z" fill="#248C9A"/>
<path opacity="0.4" d="M507.107 553.047C507.274 556.997 494.793 560.725 479.234 561.392C463.675 562.06 450.916 559.389 450.749 555.458C450.582 551.508 463.063 547.78 478.622 547.131C494.181 546.464 506.94 549.134 507.107 553.066V553.047Z" fill="white"/>
<path d="M503.51 552.916C503.51 553.139 503.435 553.436 503.268 553.621C501.377 551.303 491.085 549.949 478.771 550.468C466.42 550.988 456.276 553.232 454.626 555.698C454.459 555.513 454.329 555.253 454.329 555.012C454.218 552.341 465.122 549.671 478.715 549.096C492.271 548.521 503.398 550.264 503.51 552.935V552.916Z" fill="#248C9A"/>
<path opacity="0.4" d="M503.51 552.916C503.51 553.139 503.435 553.436 503.268 553.621C501.377 551.303 491.085 549.949 478.771 550.468C466.42 550.988 456.276 553.232 454.626 555.698C454.459 555.513 454.329 555.253 454.329 555.012C454.218 552.341 465.122 549.671 478.715 549.096C492.271 548.521 503.398 550.264 503.51 552.935V552.916Z" fill="#248C9A"/>
<path opacity="0.4" d="M503.268 553.623C501.581 556.089 491.437 558.315 479.123 558.852C466.772 559.372 456.48 558.018 454.625 555.7C456.276 553.233 466.42 551.008 478.771 550.47C491.084 549.951 501.377 551.305 503.268 553.623Z" fill="#248C9A"/>
<path d="M513.338 549.376C513.505 553.326 501.024 557.053 485.465 557.702C469.906 558.37 457.148 555.699 456.981 551.768C456.814 547.818 469.294 544.09 484.853 543.441C500.412 542.774 513.171 545.444 513.338 549.376Z" fill="#248C9A"/>
<path d="M513.014 542.246L456.651 544.637L456.954 551.771L513.316 549.379L513.014 542.246Z" fill="#248C9A"/>
<path d="M513.023 542.236C513.19 546.186 500.709 549.914 485.15 550.563C469.591 551.231 456.833 548.56 456.666 544.629C456.499 540.679 468.979 536.951 484.538 536.302C500.097 535.634 512.856 538.305 513.023 542.236Z" fill="#248C9A"/>
<path opacity="0.4" d="M513.023 542.236C513.19 546.186 500.709 549.914 485.15 550.563C469.591 551.231 456.833 548.56 456.666 544.629C456.499 540.679 468.979 536.951 484.538 536.302C500.097 535.634 512.856 538.305 513.023 542.236Z" fill="white"/>
<path d="M509.425 542.107C509.425 542.33 509.351 542.627 509.184 542.812C507.292 540.494 497 539.14 484.686 539.66C472.336 540.179 462.192 542.423 460.541 544.889C460.374 544.704 460.244 544.444 460.244 544.203C460.133 541.533 471.038 538.862 484.631 538.287C498.187 537.712 509.314 539.456 509.425 542.126V542.107Z" fill="#248C9A"/>
<path opacity="0.4" d="M509.425 542.107C509.425 542.33 509.351 542.627 509.184 542.812C507.292 540.494 497 539.14 484.686 539.66C472.336 540.179 462.192 542.423 460.541 544.889C460.374 544.704 460.244 544.444 460.244 544.203C460.133 541.533 471.038 538.862 484.631 538.287C498.187 537.712 509.314 539.456 509.425 542.126V542.107Z" fill="#248C9A"/>
<path opacity="0.4" d="M509.184 542.811C507.496 545.278 497.352 547.503 485.039 548.041C472.688 548.56 462.395 547.206 460.541 544.888C462.191 542.422 472.335 540.196 484.686 539.659C497 539.139 507.292 540.493 509.184 542.811Z" fill="#248C9A"/>
<path d="M489.852 541.29C502.991 536.364 509.649 521.72 504.723 508.582C499.798 495.443 485.154 488.785 472.016 493.71C458.877 498.636 452.219 513.28 457.144 526.418C462.07 539.557 476.713 546.215 489.852 541.29Z" fill="#248C9A"/>
<path d="M506.865 529.237C501.061 542.014 485.984 547.67 473.207 541.847C460.429 536.042 454.773 520.966 460.596 508.188C466.401 495.411 481.478 489.755 494.255 495.578C507.032 501.382 512.689 516.459 506.865 529.237Z" fill="#248C9A"/>
<path opacity="0.4" d="M506.865 529.237C501.061 542.014 485.984 547.67 473.207 541.847C460.429 536.042 454.773 520.966 460.596 508.188C466.401 495.411 481.478 489.755 494.255 495.578C507.032 501.382 512.689 516.459 506.865 529.237Z" fill="white"/>
<path d="M501.042 526.584C496.702 536.153 485.427 540.381 475.858 536.023C466.289 531.684 462.061 520.409 466.419 510.84C470.758 501.271 482.034 497.042 491.603 501.4C501.172 505.74 505.4 517.015 501.042 526.584Z" fill="#248C9A"/>
<path opacity="0.3" d="M494.125 519.853C494.254 518.184 494.069 516.57 493.587 515.068L494.811 514.771L496.888 514.252L495.367 507.428L492.511 508.095L493.995 512.768L493.624 512.843L492.697 513.028C491.788 511.396 490.471 510.042 488.821 509.134L487.764 511.86C488.524 512.323 489.173 512.917 489.73 513.603L486.874 514.159L480.253 531.258C481.273 531.499 482.312 531.573 483.332 531.48C486.391 531.22 489.359 529.551 491.399 526.881C492.233 525.805 492.456 524.359 491.955 523.079C493.179 522.43 493.995 521.206 494.106 519.834L494.125 519.853ZM490.397 523.673C489.118 526.139 486.911 527.845 484.519 528.439L484.574 528.29L486.15 524.044L488.246 518.388L489.043 516.236L490.954 515.754C491.64 517.516 491.769 519.556 491.232 521.577C491.139 521.948 488.747 520.112 488.172 521.614C487.467 523.413 490.583 523.376 490.416 523.691L490.397 523.673ZM475.005 516.496C473.781 517.127 472.965 518.332 472.872 519.686C472.594 523.042 473.651 526.269 475.747 528.531C476.452 529.292 477.267 529.941 478.176 530.442L484.797 513.343L483.072 511.007C483.944 510.858 484.815 510.877 485.687 511.044L486.744 508.318C484.908 507.873 483.035 508.002 481.255 508.596L480.698 507.836L480.476 507.539L484.722 505.072L483.053 502.643L477.342 506.667L478.529 508.466L479.215 509.505C477.861 510.284 476.618 511.359 475.598 512.676C474.745 513.77 474.541 515.216 475.024 516.515L475.005 516.496ZM478.825 517.98C479.4 516.477 476.414 516.218 476.581 515.884C477.546 514.03 479.029 512.602 480.698 511.767L481.792 513.418L480.921 515.55L478.658 521.132L476.952 525.342L476.897 525.49C475.524 523.45 475.042 520.706 475.765 518.017C475.858 517.646 478.121 519.797 478.825 517.98Z" fill="white"/>
<path d="M723.19 662.576C722.615 664.894 714.752 664.912 705.628 662.631C696.504 660.35 689.568 656.623 690.161 654.305C690.736 651.986 698.599 651.968 707.723 654.249C716.847 656.53 723.783 660.257 723.19 662.576Z" fill="#248C9A"/>
<path d="M691.206 650.124L690.157 654.316L723.203 662.592L724.253 658.401L691.206 650.124Z" fill="#248C9A"/>
<path d="M724.247 658.383C723.672 660.701 715.809 660.72 706.685 658.439C697.561 656.158 690.626 652.43 691.219 650.112C691.794 647.794 699.657 647.775 708.781 650.056C717.905 652.337 724.841 656.065 724.247 658.383Z" fill="#248C9A"/>
<path opacity="0.4" d="M724.247 658.383C723.672 660.701 715.809 660.72 706.685 658.439C697.561 656.158 690.626 652.43 691.219 650.112C691.794 647.794 699.657 647.775 708.781 650.056C717.905 652.337 724.841 656.065 724.247 658.383Z" fill="white"/>
<path d="M722.169 657.697C722.132 657.827 722.039 657.975 721.909 658.068C721.223 656.399 715.474 653.84 708.261 652.041C701.028 650.224 694.76 649.778 693.388 650.928C693.313 650.798 693.295 650.613 693.332 650.483C693.721 648.925 700.49 649.241 708.465 651.225C716.42 653.209 722.558 656.139 722.169 657.697Z" fill="#248C9A"/>
<path opacity="0.4" d="M722.169 657.697C722.132 657.827 722.039 657.975 721.909 658.068C721.223 656.399 715.474 653.84 708.261 652.041C701.028 650.224 694.76 649.778 693.388 650.928C693.313 650.798 693.295 650.613 693.332 650.483C693.721 648.925 700.49 649.241 708.465 651.225C716.42 653.209 722.558 656.139 722.169 657.697Z" fill="#248C9A"/>
<path opacity="0.4" d="M721.909 658.067C720.519 659.217 714.25 658.754 707.037 656.955C699.804 655.137 694.074 652.597 693.388 650.928C694.76 649.778 701.028 650.223 708.261 652.04C715.474 653.839 721.205 656.398 721.909 658.067Z" fill="#248C9A"/>
<path d="M739.065 670.156C739.158 672.53 731.629 674.792 722.226 675.182C712.824 675.59 705.128 673.976 705.036 671.603C704.943 669.229 712.472 666.966 721.874 666.577C731.276 666.169 738.972 667.782 739.065 670.156Z" fill="#248C9A"/>
<path d="M738.844 665.897L704.808 667.342L704.991 671.659L739.027 670.214L738.844 665.897Z" fill="#248C9A"/>
<path d="M738.878 665.855C738.971 668.229 731.442 670.491 722.039 670.881C712.637 671.289 704.941 669.675 704.849 667.302C704.756 664.928 712.285 662.665 721.687 662.276C731.089 661.868 738.785 663.481 738.878 665.855Z" fill="#248C9A"/>
<path opacity="0.4" d="M738.878 665.855C738.971 668.229 731.442 670.491 722.039 670.881C712.637 671.289 704.941 669.675 704.849 667.302C704.756 664.928 712.285 662.665 721.687 662.276C731.089 661.868 738.785 663.481 738.878 665.855Z" fill="white"/>
<path d="M736.709 665.781C736.709 665.93 736.653 666.097 736.56 666.208C735.429 664.817 729.198 663.983 721.762 664.298C714.307 664.613 708.168 665.967 707.186 667.45C707.074 667.339 707.019 667.172 707 667.042C706.926 665.429 713.509 663.816 721.725 663.463C729.903 663.111 736.635 664.168 736.709 665.781Z" fill="#248C9A"/>
<path opacity="0.4" d="M736.709 665.781C736.709 665.93 736.653 666.097 736.56 666.208C735.429 664.817 729.198 663.983 721.762 664.298C714.307 664.613 708.168 665.967 707.186 667.45C707.074 667.339 707.019 667.172 707 667.042C706.926 665.429 713.509 663.816 721.725 663.463C729.903 663.111 736.635 664.168 736.709 665.781Z" fill="#248C9A"/>
<path opacity="0.4" d="M736.561 666.208C735.541 667.692 729.421 669.045 721.985 669.361C714.53 669.676 708.299 668.86 707.186 667.45C708.187 665.967 714.307 664.613 721.762 664.298C729.199 663.983 735.411 664.799 736.561 666.208Z" fill="#248C9A"/>
<path d="M718.907 664.742C709.561 675.22 693.501 676.147 683.023 666.801C672.545 657.454 671.618 641.395 680.965 630.917C690.311 620.439 706.371 619.512 716.849 628.858C727.326 638.205 728.254 654.265 718.907 664.742Z" fill="#248C9A"/>
<path d="M721.189 666.763C711.842 677.241 695.783 678.168 685.305 668.822C674.827 659.475 673.9 643.416 683.246 632.938C692.593 622.46 708.653 621.533 719.13 630.879C729.608 640.226 730.535 656.286 721.189 666.763Z" fill="#248C9A"/>
<path opacity="0.4" d="M721.189 666.763C711.842 677.241 695.783 678.168 685.305 668.822C674.827 659.475 673.9 643.416 683.246 632.938C692.593 622.46 708.653 621.533 719.13 630.879C729.608 640.226 730.535 656.286 721.189 666.763Z" fill="white"/>
<path d="M716.404 662.518C709.413 670.362 697.396 671.048 689.551 664.057C681.707 657.066 681.021 645.049 688.012 637.204C695.003 629.36 707.02 628.674 714.865 635.665C722.709 642.656 723.395 654.673 716.404 662.518Z" fill="#248C9A"/>
<g opacity="0.3">
<path d="M710.284 656.34C711.174 654.931 711.749 653.392 711.99 651.853L713.214 652.131L715.31 652.613L717.09 645.863L714.234 645.139L713.399 649.98L713.047 649.868L712.138 649.609C712.083 647.736 711.526 645.937 710.488 644.379L708.3 646.326C708.763 647.087 709.078 647.921 709.245 648.774L706.464 647.958L692.722 660.105C693.519 660.791 694.41 661.329 695.355 661.718C698.193 662.887 701.586 662.775 704.646 661.347C705.889 660.773 706.742 659.586 706.89 658.232C708.263 658.232 709.561 657.509 710.284 656.34ZM705.203 658.028C702.922 659.641 700.196 660.142 697.803 659.567L697.933 659.456L701.271 656.396L705.722 652.335L707.428 650.777L709.357 651.222C709.153 653.095 708.337 654.968 706.927 656.526C706.668 656.804 705.407 654.078 704.183 655.153C702.736 656.433 705.518 657.824 705.221 658.028H705.203ZM694.818 644.583H694.799C693.427 644.583 692.147 645.269 691.442 646.437C689.662 649.293 689.124 652.65 689.94 655.599C690.218 656.6 690.645 657.546 691.22 658.417L704.943 646.271L704.479 643.396C705.314 643.674 706.093 644.082 706.797 644.62L708.986 642.673C707.558 641.449 705.833 640.689 703.997 640.392L703.849 639.446L703.793 639.075L708.689 638.834L708.318 635.923L701.401 636.868L701.624 639.001L701.753 640.244C700.196 640.299 698.601 640.689 697.08 641.393C695.838 641.968 694.966 643.174 694.818 644.546V644.583ZM697.525 647.643C698.731 646.567 696.19 644.972 696.487 644.75C698.193 643.545 700.158 642.97 702.05 642.988L702.273 644.954L700.529 646.456L695.949 650.388L692.5 653.336L692.37 653.447C692.092 650.999 692.926 648.348 694.799 646.289C695.059 646.011 696.079 648.941 697.525 647.661V647.643Z" fill="white"/>
</g>
<path d="M387.679 672.53C387.586 674.904 395.115 677.166 404.517 677.556C413.919 677.964 421.615 676.35 421.708 673.976C421.801 671.603 414.272 669.34 404.87 668.951C395.467 668.543 387.771 670.156 387.679 672.53Z" fill="#248C9A"/>
<path d="M387.874 668.229L387.69 672.546L421.726 673.991L421.91 669.673L387.874 668.229Z" fill="#248C9A"/>
<path d="M387.864 668.209C387.771 670.583 395.3 672.846 404.702 673.235C414.104 673.643 421.8 672.03 421.893 669.656C421.986 667.282 414.457 665.02 405.055 664.63C395.652 664.222 387.956 665.836 387.864 668.209Z" fill="#248C9A"/>
<path opacity="0.4" d="M387.864 668.209C387.771 670.583 395.3 672.846 404.702 673.235C414.104 673.643 421.8 672.03 421.893 669.656C421.986 667.282 414.457 665.02 405.055 664.63C395.652 664.222 387.956 665.836 387.864 668.209Z" fill="white"/>
<path d="M390.035 668.135C390.035 668.284 390.09 668.451 390.183 668.562C391.314 667.171 397.545 666.337 404.982 666.652C412.437 666.967 418.575 668.321 419.558 669.805C419.669 669.693 419.725 669.526 419.743 669.397C419.818 667.783 413.234 666.17 405.019 665.817C396.841 665.465 390.109 666.522 390.035 668.135Z" fill="#248C9A"/>
<path opacity="0.4" d="M390.035 668.135C390.035 668.284 390.09 668.451 390.183 668.562C391.314 667.171 397.545 666.337 404.982 666.652C412.437 666.967 418.575 668.321 419.558 669.805C419.669 669.693 419.725 669.526 419.743 669.397C419.818 667.783 413.234 666.17 405.019 665.817C396.841 665.465 390.109 666.522 390.035 668.135Z" fill="#248C9A"/>
<path opacity="0.4" d="M390.183 668.562C391.203 670.046 397.322 671.4 404.759 671.715C412.214 672.03 418.445 671.214 419.557 669.805C418.556 668.321 412.436 666.967 404.981 666.652C397.545 666.337 391.332 667.153 390.183 668.562Z" fill="#248C9A"/>
<path d="M384.101 666.004C384.008 668.377 391.537 670.64 400.94 671.029C410.342 671.437 418.038 669.824 418.131 667.45C418.223 665.076 410.694 662.814 401.292 662.424C391.89 662.016 384.194 663.63 384.101 666.004Z" fill="#248C9A"/>
<path d="M384.261 661.645L384.078 665.962L418.114 667.406L418.297 663.089L384.261 661.645Z" fill="#248C9A"/>
<path d="M384.286 661.683C384.193 664.057 391.722 666.319 401.125 666.709C410.527 667.117 418.223 665.504 418.316 663.13C418.408 660.756 410.879 658.494 401.477 658.104C392.075 657.696 384.379 659.31 384.286 661.683Z" fill="#248C9A"/>
<path opacity="0.4" d="M384.286 661.683C384.193 664.057 391.722 666.319 401.125 666.709C410.527 667.117 418.223 665.504 418.316 663.13C418.408 660.756 410.879 658.494 401.477 658.104C392.075 657.696 384.379 659.31 384.286 661.683Z" fill="white"/>
<path d="M386.456 661.61C386.456 661.758 386.512 661.925 386.604 662.036C387.736 660.645 393.967 659.811 401.403 660.126C408.858 660.441 414.996 661.795 415.979 663.279C416.091 663.167 416.146 663 416.165 662.871C416.239 661.257 409.656 659.644 401.44 659.291C393.262 658.939 386.53 659.996 386.456 661.61Z" fill="#248C9A"/>
<path opacity="0.4" d="M386.456 661.61C386.456 661.758 386.512 661.925 386.604 662.036C387.736 660.645 393.967 659.811 401.403 660.126C408.858 660.441 414.996 661.795 415.979 663.279C416.091 663.167 416.146 663 416.165 662.871C416.239 661.257 409.656 659.644 401.44 659.291C393.262 658.939 386.53 659.996 386.456 661.61Z" fill="#248C9A"/>
<path opacity="0.4" d="M386.604 662.036C387.624 663.519 393.744 664.873 401.181 665.188C408.636 665.503 414.867 664.688 415.979 663.278C414.978 661.795 408.858 660.441 401.403 660.126C393.967 659.81 387.754 660.626 386.604 662.036Z" fill="#248C9A"/>
<path d="M384.564 658.138C384.472 660.512 392.001 662.774 401.403 663.164C410.805 663.572 418.501 661.959 418.594 659.585C418.687 657.211 411.157 654.949 401.755 654.559C392.353 654.151 384.657 655.765 384.564 658.138Z" fill="#248C9A"/>
<path d="M384.734 653.781L384.551 658.098L418.587 659.543L418.77 655.226L384.734 653.781Z" fill="#248C9A"/>
<path d="M384.751 653.82C384.658 656.193 392.187 658.456 401.589 658.845C410.992 659.253 418.688 657.64 418.78 655.266C418.873 652.892 411.344 650.63 401.942 650.241C392.54 649.833 384.844 651.446 384.751 653.82Z" fill="#248C9A"/>
<path opacity="0.4" d="M384.751 653.82C384.658 656.193 392.187 658.456 401.589 658.845C410.992 659.253 418.688 657.64 418.78 655.266C418.873 652.892 411.344 650.63 401.942 650.241C392.54 649.833 384.844 651.446 384.751 653.82Z" fill="white"/>
<path d="M386.919 653.746C386.919 653.894 386.975 654.061 387.068 654.173C388.199 652.782 394.43 651.947 401.866 652.262C409.321 652.578 415.46 653.931 416.443 655.415C416.554 655.304 416.61 655.137 416.628 655.007C416.702 653.394 410.119 651.78 401.904 651.428C393.725 651.076 386.994 652.133 386.919 653.746Z" fill="#248C9A"/>
<path opacity="0.4" d="M386.919 653.746C386.919 653.894 386.975 654.061 387.068 654.173C388.199 652.782 394.43 651.947 401.866 652.262C409.321 652.578 415.46 653.931 416.443 655.415C416.554 655.304 416.61 655.137 416.628 655.007C416.702 653.394 410.119 651.78 401.904 651.428C393.725 651.076 386.994 652.133 386.919 653.746Z" fill="#248C9A"/>
<path opacity="0.4" d="M387.068 654.172C388.088 655.656 394.208 657.01 401.644 657.325C409.099 657.64 415.33 656.824 416.443 655.415C415.441 653.931 409.322 652.578 401.867 652.262C394.43 651.947 388.218 652.763 387.068 654.172Z" fill="#248C9A"/>
<path d="M380.985 651.613C380.892 653.987 388.421 656.249 397.823 656.639C407.226 657.047 414.922 655.433 415.014 653.059C415.107 650.686 407.578 648.423 398.176 648.034C388.774 647.626 381.077 649.239 380.985 651.613Z" fill="#248C9A"/>
<path d="M381.176 647.272L380.993 651.589L415.029 653.033L415.212 648.716L381.176 647.272Z" fill="#248C9A"/>
<path d="M381.17 647.292C381.077 649.666 388.606 651.928 398.008 652.318C407.411 652.725 415.107 651.112 415.199 648.738C415.292 646.365 407.763 644.102 398.361 643.713C388.959 643.305 381.263 644.918 381.17 647.292Z" fill="#248C9A"/>
<path opacity="0.4" d="M381.17 647.292C381.077 649.666 388.606 651.928 398.008 652.318C407.411 652.725 415.107 651.112 415.199 648.738C415.292 646.365 407.763 644.102 398.361 643.713C388.959 643.305 381.263 644.918 381.17 647.292Z" fill="white"/>
<path d="M383.341 647.218C383.341 647.367 383.396 647.533 383.489 647.645C384.62 646.254 390.851 645.419 398.288 645.735C405.743 646.05 411.881 647.404 412.864 648.887C412.975 648.776 413.031 648.609 413.049 648.479C413.124 646.866 406.54 645.252 398.325 644.9C390.147 644.548 383.415 645.605 383.341 647.218Z" fill="#248C9A"/>
<path opacity="0.4" d="M383.341 647.218C383.341 647.367 383.396 647.533 383.489 647.645C384.62 646.254 390.851 645.419 398.288 645.735C405.743 646.05 411.881 647.404 412.864 648.887C412.975 648.776 413.031 648.609 413.049 648.479C413.124 646.866 406.54 645.252 398.325 644.9C390.147 644.548 383.415 645.605 383.341 647.218Z" fill="#248C9A"/>
<path opacity="0.4" d="M383.489 647.645C384.509 649.129 390.628 650.482 398.065 650.798C405.52 651.113 411.751 650.297 412.864 648.888C411.862 647.404 405.742 646.05 398.287 645.735C390.851 645.42 384.639 646.236 383.489 647.645Z" fill="#248C9A"/>
<path d="M386.455 646.068C386.363 648.442 393.892 650.704 403.294 651.094C412.696 651.502 420.392 649.888 420.485 647.515C420.578 645.141 413.049 642.879 403.646 642.489C394.244 642.081 386.548 643.695 386.455 646.068Z" fill="#248C9A"/>
<path d="M386.623 641.72L386.44 646.037L420.476 647.481L420.659 643.164L386.623 641.72Z" fill="#248C9A"/>
<path d="M386.642 641.766C386.549 644.14 394.078 646.402 403.481 646.792C412.883 647.2 420.579 645.587 420.672 643.194C420.764 640.821 413.235 638.558 403.833 638.169C394.431 637.761 386.735 639.374 386.642 641.748V641.766Z" fill="#248C9A"/>
<path opacity="0.4" d="M386.642 641.766C386.549 644.14 394.078 646.402 403.481 646.792C412.883 647.2 420.579 645.587 420.672 643.194C420.764 640.821 413.235 638.558 403.833 638.169C394.431 637.761 386.735 639.374 386.642 641.748V641.766Z" fill="white"/>
<path d="M388.811 641.692C388.811 641.84 388.866 642.007 388.959 642.118C390.09 640.727 396.321 639.893 403.758 640.208C411.213 640.523 417.351 641.877 418.334 643.361C418.445 643.249 418.501 643.082 418.519 642.953C418.593 641.339 412.01 639.726 403.795 639.373C395.616 639.021 388.885 640.078 388.811 641.692Z" fill="#248C9A"/>
<path opacity="0.4" d="M388.811 641.692C388.811 641.84 388.866 642.007 388.959 642.118C390.09 640.727 396.321 639.893 403.758 640.208C411.213 640.523 417.351 641.877 418.334 643.361C418.445 643.249 418.501 643.082 418.519 642.953C418.593 641.339 412.01 639.726 403.795 639.373C395.616 639.021 388.885 640.078 388.811 641.692Z" fill="#248C9A"/>
<path opacity="0.4" d="M388.959 642.118C389.979 643.602 396.099 644.956 403.535 645.271C410.99 645.586 417.221 644.77 418.334 643.361C417.332 641.877 411.213 640.524 403.758 640.208C396.321 639.893 390.109 640.709 388.959 642.118Z" fill="#248C9A"/>
<path d="M362.457 672.143C363.032 674.461 370.895 674.48 380.019 672.199C389.143 669.918 396.078 666.19 395.485 663.872C394.91 661.554 387.047 661.536 377.923 663.817C368.799 666.098 361.863 669.825 362.457 672.143Z" fill="#248C9A"/>
<path d="M394.464 659.671L361.418 667.948L362.468 672.139L395.514 663.863L394.464 659.671Z" fill="#248C9A"/>
<path d="M361.419 667.953C361.994 670.271 369.857 670.289 378.981 668.008C388.105 665.727 395.04 662 394.447 659.682C393.872 657.363 386.009 657.345 376.885 659.626C367.761 661.907 360.825 665.634 361.419 667.953Z" fill="#248C9A"/>
<path opacity="0.4" d="M361.419 667.953C361.994 670.271 369.857 670.289 378.981 668.008C388.105 665.727 395.04 662 394.447 659.682C393.872 657.363 386.009 657.345 376.885 659.626C367.761 661.907 360.825 665.634 361.419 667.953Z" fill="white"/>
<path d="M363.478 667.267C363.515 667.396 363.608 667.545 363.738 667.638C364.424 665.969 370.173 663.409 377.387 661.611C384.619 659.793 390.887 659.348 392.259 660.498C392.334 660.368 392.352 660.183 392.315 660.053C391.926 658.495 385.157 658.81 377.183 660.795C369.227 662.779 363.089 665.709 363.478 667.267Z" fill="#248C9A"/>
<path opacity="0.4" d="M363.478 667.267C363.515 667.396 363.608 667.545 363.738 667.638C364.424 665.969 370.173 663.409 377.387 661.611C384.619 659.793 390.887 659.348 392.259 660.498C392.334 660.368 392.352 660.183 392.315 660.053C391.926 658.495 385.157 658.81 377.183 660.795C369.227 662.779 363.089 665.709 363.478 667.267Z" fill="#248C9A"/>
<path opacity="0.4" d="M363.738 667.637C365.129 668.787 371.397 668.323 378.611 666.524C385.843 664.707 391.573 662.166 392.26 660.497C390.887 659.347 384.619 659.792 377.387 661.61C370.173 663.409 364.442 665.968 363.738 667.637Z" fill="#248C9A"/>
<path d="M346.678 672.531C346.585 674.904 354.115 677.167 363.517 677.556C372.919 677.964 380.615 676.351 380.708 673.977C380.8 671.603 373.271 669.341 363.869 668.952C354.467 668.544 346.771 670.157 346.678 672.531Z" fill="#248C9A"/>
<path d="M346.84 668.174L346.657 672.491L380.693 673.935L380.876 669.618L346.84 668.174Z" fill="#248C9A"/>
<path d="M346.862 668.209C346.769 670.583 354.299 672.846 363.701 673.235C373.103 673.643 380.799 672.03 380.892 669.656C380.984 667.282 373.455 665.02 364.053 664.63C354.651 664.222 346.955 665.836 346.862 668.209Z" fill="#248C9A"/>
<path opacity="0.4" d="M346.862 668.209C346.769 670.583 354.299 672.846 363.701 673.235C373.103 673.643 380.799 672.03 380.892 669.656C380.984 667.282 373.455 665.02 364.053 664.63C354.651 664.222 346.955 665.836 346.862 668.209Z" fill="white"/>
<path d="M349.033 668.136C349.033 668.284 349.089 668.451 349.182 668.562C350.313 667.172 356.544 666.337 363.98 666.652C371.435 666.968 377.574 668.321 378.556 669.805C378.668 669.694 378.723 669.527 378.742 669.397C378.816 667.784 372.233 666.17 364.017 665.818C355.839 665.465 349.107 666.523 349.033 668.136Z" fill="#248C9A"/>
<path opacity="0.4" d="M349.033 668.136C349.033 668.284 349.089 668.451 349.182 668.562C350.313 667.172 356.544 666.337 363.98 666.652C371.435 666.968 377.574 668.321 378.556 669.805C378.668 669.694 378.723 669.527 378.742 669.397C378.816 667.784 372.233 666.17 364.017 665.818C355.839 665.465 349.107 666.523 349.033 668.136Z" fill="#248C9A"/>
<path opacity="0.4" d="M349.18 668.562C350.2 670.046 356.319 671.4 363.756 671.715C371.211 672.03 377.442 671.214 378.555 669.805C377.553 668.321 371.433 666.967 363.978 666.652C356.542 666.337 350.329 667.153 349.18 668.562Z" fill="#248C9A"/>
<path d="M522.462 672.329C522.351 674.777 530.103 677.095 539.764 677.503C549.426 677.911 557.345 676.261 557.456 673.813C557.567 671.365 549.816 669.047 540.154 668.639C530.492 668.231 522.573 669.882 522.462 672.329Z" fill="#248C9A"/>
<path d="M522.609 667.858L522.421 672.286L557.42 673.771L557.608 669.343L522.609 667.858Z" fill="#248C9A"/>
<path d="M522.647 667.896C522.535 670.344 530.287 672.662 539.949 673.07C549.611 673.478 557.529 671.827 557.641 669.379C557.752 666.931 550 664.613 540.338 664.205C530.677 663.797 522.758 665.448 522.647 667.896Z" fill="#248C9A"/>
<path opacity="0.4" d="M522.647 667.896C522.535 670.344 530.287 672.662 539.949 673.07C549.611 673.478 557.529 671.827 557.641 669.379C557.752 666.931 550 664.613 540.338 664.205C530.677 663.797 522.758 665.448 522.647 667.896Z" fill="white"/>
<path d="M524.872 667.821C524.872 667.97 524.927 668.136 525.02 668.266C526.188 666.82 532.586 665.985 540.227 666.301C547.886 666.634 554.191 668.007 555.211 669.546C555.322 669.435 555.396 669.268 555.396 669.119C555.47 667.469 548.702 665.8 540.264 665.448C531.844 665.095 524.927 666.171 524.872 667.821Z" fill="#248C9A"/>
<path opacity="0.4" d="M524.872 667.821C524.872 667.97 524.927 668.136 525.02 668.266C526.188 666.82 532.586 665.985 540.227 666.301C547.886 666.634 554.191 668.007 555.211 669.546C555.322 669.435 555.396 669.268 555.396 669.119C555.47 667.469 548.702 665.8 540.264 665.448C531.844 665.095 524.927 666.171 524.872 667.821Z" fill="#248C9A"/>
<path opacity="0.4" d="M525.02 668.247C526.059 669.787 532.364 671.159 540.004 671.493C547.663 671.826 554.061 670.973 555.211 669.527C554.191 667.988 547.886 666.615 540.227 666.282C532.586 665.948 526.188 666.801 525.02 668.247Z" fill="#248C9A"/>
<path d="M518.771 665.614C518.659 668.062 526.411 670.38 536.073 670.788C545.735 671.196 553.653 669.545 553.765 667.097C553.876 664.65 546.124 662.331 536.462 661.924C526.801 661.516 518.882 663.166 518.771 665.614Z" fill="#248C9A"/>
<path d="M518.954 661.171L518.766 665.6L553.766 667.085L553.953 662.657L518.954 661.171Z" fill="#248C9A"/>
<path d="M518.976 661.183C518.865 663.631 526.616 665.949 536.278 666.357C545.94 666.765 553.858 665.114 553.97 662.667C554.081 660.219 546.329 657.901 536.667 657.493C527.006 657.085 519.087 658.735 518.976 661.183Z" fill="#248C9A"/>
<path opacity="0.4" d="M518.976 661.183C518.865 663.631 526.616 665.949 536.278 666.357C545.94 666.765 553.858 665.114 553.97 662.667C554.081 660.219 546.329 657.901 536.667 657.493C527.006 657.085 519.087 658.735 518.976 661.183Z" fill="white"/>
<path d="M521.201 661.108C521.201 661.256 521.256 661.423 521.349 661.553C522.517 660.106 528.915 659.272 536.556 659.587C544.215 659.921 550.52 661.293 551.54 662.833C551.651 662.721 551.725 662.554 551.725 662.406C551.799 660.755 545.031 659.086 536.593 658.734C528.173 658.382 521.256 659.457 521.201 661.108Z" fill="#248C9A"/>
<path opacity="0.4" d="M521.201 661.108C521.201 661.256 521.256 661.423 521.349 661.553C522.517 660.106 528.915 659.272 536.556 659.587C544.215 659.921 550.52 661.293 551.54 662.833C551.651 662.721 551.725 662.554 551.725 662.406C551.799 660.755 545.031 659.086 536.593 658.734C528.173 658.382 521.256 659.457 521.201 661.108Z" fill="#248C9A"/>
<path opacity="0.4" d="M521.349 661.553C522.387 663.092 528.692 664.464 536.333 664.798C543.992 665.132 550.39 664.279 551.54 662.832C550.52 661.293 544.214 659.921 536.555 659.587C528.915 659.253 522.517 660.106 521.349 661.553Z" fill="#248C9A"/>
<path d="M526.671 660.644C526.56 663.092 534.312 665.41 543.973 665.818C553.635 666.226 561.554 664.576 561.665 662.128C561.776 659.68 554.025 657.362 544.363 656.954C534.701 656.546 526.782 658.197 526.671 660.644Z" fill="#248C9A"/>
<path d="M526.803 656.184L526.615 660.612L561.615 662.097L561.803 657.669L526.803 656.184Z" fill="#248C9A"/>
<path d="M526.857 656.212C526.746 658.66 534.498 660.978 544.159 661.386C553.821 661.794 561.74 660.144 561.851 657.696C561.962 655.248 554.211 652.93 544.549 652.522C534.887 652.114 526.968 653.764 526.857 656.212Z" fill="#248C9A"/>
<path opacity="0.4" d="M526.857 656.212C526.746 658.66 534.498 660.978 544.159 661.386C553.821 661.794 561.74 660.144 561.851 657.696C561.962 655.248 554.211 652.93 544.549 652.522C534.887 652.114 526.968 653.764 526.857 656.212Z" fill="white"/>
<path d="M529.082 656.138C529.082 656.286 529.137 656.453 529.23 656.583C530.398 655.137 536.796 654.302 544.437 654.618C552.096 654.951 558.401 656.324 559.421 657.863C559.532 657.752 559.606 657.585 559.606 657.436C559.68 655.786 552.911 654.117 544.474 653.764C536.054 653.412 529.137 654.488 529.082 656.138Z" fill="#248C9A"/>
<path opacity="0.4" d="M529.082 656.138C529.082 656.286 529.137 656.453 529.23 656.583C530.398 655.137 536.796 654.302 544.437 654.618C552.096 654.951 558.401 656.324 559.421 657.863C559.532 657.752 559.606 657.585 559.606 657.436C559.68 655.786 552.911 654.117 544.474 653.764C536.054 653.412 529.137 654.488 529.082 656.138Z" fill="#248C9A"/>
<path opacity="0.4" d="M529.23 656.583C530.269 658.122 536.574 659.494 544.215 659.828C551.874 660.162 558.272 659.309 559.421 657.862C558.401 656.323 552.096 654.951 544.437 654.617C536.797 654.283 530.399 655.136 529.23 656.583Z" fill="#248C9A"/>
<path d="M526.765 653.931C526.654 656.379 534.405 658.697 544.067 659.105C553.729 659.513 561.647 657.863 561.759 655.415C561.87 652.967 554.118 650.649 544.457 650.241C534.795 649.833 526.876 651.483 526.765 653.931Z" fill="#248C9A"/>
<path d="M526.944 649.483L526.756 653.911L561.756 655.396L561.944 650.968L526.944 649.483Z" fill="#248C9A"/>
<path d="M526.95 649.5C526.839 651.948 534.59 654.266 544.252 654.674C553.914 655.082 561.833 653.431 561.944 650.983C562.055 648.535 554.303 646.217 544.642 645.809C534.98 645.401 527.061 647.052 526.95 649.5Z" fill="#248C9A"/>
<path opacity="0.4" d="M526.95 649.5C526.839 651.948 534.59 654.266 544.252 654.674C553.914 655.082 561.833 653.431 561.944 650.983C562.055 648.535 554.303 646.217 544.642 645.809C534.98 645.401 527.061 647.052 526.95 649.5Z" fill="white"/>
<path d="M529.176 649.425C529.176 649.573 529.232 649.74 529.325 649.87C530.493 648.423 536.891 647.589 544.531 647.904C552.19 648.238 558.496 649.61 559.515 651.149C559.627 651.038 559.701 650.871 559.701 650.723C559.775 649.072 553.006 647.403 544.568 647.051C536.149 646.698 529.232 647.774 529.176 649.425Z" fill="#248C9A"/>
<path opacity="0.4" d="M529.176 649.425C529.176 649.573 529.232 649.74 529.325 649.87C530.493 648.423 536.891 647.589 544.531 647.904C552.19 648.238 558.496 649.61 559.515 651.149C559.627 651.038 559.701 650.871 559.701 650.723C559.775 649.072 553.006 647.403 544.568 647.051C536.149 646.698 529.232 647.774 529.176 649.425Z" fill="#248C9A"/>
<path opacity="0.4" d="M529.325 649.869C530.363 651.409 536.668 652.781 544.309 653.115C551.968 653.449 558.366 652.596 559.516 651.149C558.496 649.61 552.19 648.238 544.531 647.904C536.891 647.57 530.493 648.423 529.325 649.869Z" fill="#248C9A"/>
<path d="M519.571 648.46C519.459 650.908 527.211 653.226 536.873 653.634C546.535 654.042 554.453 652.392 554.564 649.944C554.676 647.496 546.924 645.178 537.262 644.77C527.6 644.362 519.682 646.012 519.571 648.46Z" fill="#248C9A"/>
<path d="M519.8 644.054L519.611 648.482L554.61 649.973L554.799 645.545L519.8 644.054Z" fill="#248C9A"/>
<path d="M519.755 644.028C519.643 646.476 527.395 648.794 537.057 649.202C546.719 649.61 554.637 647.959 554.748 645.512C554.86 643.064 547.108 640.746 537.446 640.338C527.784 639.93 519.866 641.58 519.755 644.028Z" fill="#248C9A"/>
<path opacity="0.4" d="M519.755 644.028C519.643 646.476 527.395 648.794 537.057 649.202C546.719 649.61 554.637 647.959 554.748 645.512C554.86 643.064 547.108 640.746 537.446 640.338C527.784 639.93 519.866 641.58 519.755 644.028Z" fill="white"/>
<path d="M521.981 643.935C521.981 644.083 522.037 644.25 522.129 644.38C523.298 642.934 529.696 642.099 537.336 642.414C544.995 642.748 551.3 644.12 552.32 645.66C552.431 645.548 552.506 645.382 552.506 645.233C552.58 643.583 545.811 641.914 537.373 641.561C528.954 641.209 522.037 642.285 521.981 643.935Z" fill="#248C9A"/>
<path opacity="0.4" d="M521.981 643.935C521.981 644.083 522.037 644.25 522.129 644.38C523.298 642.934 529.696 642.099 537.336 642.414C544.995 642.748 551.3 644.12 552.32 645.66C552.431 645.548 552.506 645.382 552.506 645.233C552.58 643.583 545.811 641.914 537.373 641.561C528.954 641.209 522.037 642.285 521.981 643.935Z" fill="#248C9A"/>
<path opacity="0.4" d="M522.13 644.38C523.168 645.919 529.474 647.292 537.114 647.626C544.773 647.959 551.171 647.106 552.321 645.66C551.301 644.121 544.996 642.748 537.337 642.414C529.696 642.081 523.298 642.934 522.13 644.38Z" fill="#248C9A"/>
<path d="M564.744 661.813C563.724 663.965 569.807 668.953 578.319 672.959C586.831 676.965 594.564 678.448 595.566 676.297C596.586 674.146 590.503 669.157 581.991 665.152C573.479 661.146 565.746 659.662 564.744 661.813Z" fill="#248C9A"/>
<path d="M566.601 657.953L564.763 661.864L595.595 676.352L597.433 672.442L566.601 657.953Z" fill="#248C9A"/>
<path d="M566.58 657.899C565.56 660.051 571.643 665.039 580.155 669.045C588.667 673.05 596.4 674.534 597.402 672.383C598.422 670.232 592.339 665.243 583.827 661.237C575.315 657.232 567.582 655.748 566.58 657.899Z" fill="#248C9A"/>
<path opacity="0.4" d="M566.58 657.899C565.56 660.051 571.643 665.039 580.155 669.045C588.667 673.05 596.4 674.534 597.402 672.383C598.422 670.232 592.339 665.243 583.827 661.237C575.315 657.232 567.582 655.748 566.58 657.899Z" fill="white"/>
<path d="M568.601 658.679C568.545 658.809 568.527 658.976 568.564 659.124C570.159 658.271 576.204 659.922 582.954 663.093C589.705 666.264 594.842 669.88 595.175 671.642C595.324 671.586 595.435 671.456 595.509 671.327C596.195 669.862 590.743 665.837 583.307 662.332C575.889 658.846 569.287 657.214 568.601 658.679Z" fill="#248C9A"/>
<path opacity="0.4" d="M568.601 658.679C568.545 658.809 568.527 658.976 568.564 659.124C570.159 658.271 576.204 659.922 582.954 663.093C589.705 666.264 594.842 669.88 595.175 671.642C595.324 671.586 595.435 671.456 595.509 671.327C596.195 669.862 590.743 665.837 583.307 662.332C575.889 658.846 569.287 657.214 568.601 658.679Z" fill="#248C9A"/>
<path opacity="0.4" d="M568.583 659.124C568.935 660.886 574.072 664.502 580.804 667.673C587.554 670.844 593.618 672.495 595.194 671.642C594.86 669.88 589.724 666.264 582.973 663.093C576.242 659.922 570.177 658.29 568.583 659.124Z" fill="#248C9A"/>
<path d="M546.849 669.453C546.756 671.827 554.285 674.089 563.688 674.479C573.09 674.887 580.786 673.274 580.879 670.9C580.971 668.526 573.442 666.264 564.04 665.874C554.638 665.466 546.942 667.08 546.849 669.453Z" fill="#248C9A"/>
<path d="M547.009 665.091L546.826 669.408L580.862 670.852L581.045 666.535L547.009 665.091Z" fill="#248C9A"/>
<path d="M547.036 665.151C546.943 667.525 554.472 669.787 563.874 670.177C573.276 670.585 580.972 668.971 581.065 666.579C581.158 664.205 573.629 661.943 564.227 661.554C554.824 661.146 547.128 662.759 547.036 665.133V665.151Z" fill="#248C9A"/>
<path opacity="0.4" d="M547.036 665.151C546.943 667.525 554.472 669.787 563.874 670.177C573.276 670.585 580.972 668.971 581.065 666.579C581.158 664.205 573.629 661.943 564.227 661.554C554.824 661.146 547.128 662.759 547.036 665.133V665.151Z" fill="white"/>
<path d="M549.204 665.077C549.204 665.225 549.26 665.392 549.352 665.503C550.484 664.112 556.715 663.278 564.151 663.593C571.606 663.908 577.744 665.262 578.727 666.746C578.839 666.634 578.894 666.467 578.913 666.338C578.987 664.724 572.404 663.111 564.188 662.759C556.01 662.406 549.278 663.463 549.204 665.077Z" fill="#248C9A"/>
<path opacity="0.4" d="M549.204 665.077C549.204 665.225 549.26 665.392 549.352 665.503C550.484 664.112 556.715 663.278 564.151 663.593C571.606 663.908 577.744 665.262 578.727 666.746C578.839 666.634 578.894 666.467 578.913 666.338C578.987 664.724 572.404 663.111 564.188 662.759C556.01 662.406 549.278 663.463 549.204 665.077Z" fill="#248C9A"/>
<path opacity="0.4" d="M549.353 665.503C550.372 666.987 556.492 668.341 563.929 668.656C571.384 668.971 577.615 668.155 578.727 666.746C577.726 665.262 571.606 663.909 564.151 663.593C556.715 663.278 550.502 664.094 549.353 665.503Z" fill="#248C9A"/>
<path d="M247.017 193.04V204.241C254.231 204.816 260.814 207.672 266.025 212.104L273.944 204.185C266.693 197.75 257.328 193.652 247.017 193.04Z" fill="#248C9A"/>
<path d="M241.621 271.038C225.264 269.74 212.227 256.703 210.929 240.347H199.728C201.082 262.879 219.107 280.904 241.621 282.239V271.038Z" fill="#248C9A"/>
<path d="M210.929 234.933C212.227 218.576 225.283 205.539 241.621 204.241V193.04C219.089 194.394 201.063 212.42 199.728 234.933H210.929Z" fill="#248C9A"/>
<path opacity="0.2" d="M210.929 234.933C212.227 218.576 225.283 205.539 241.621 204.241V193.04C219.089 194.394 201.063 212.42 199.728 234.933H210.929Z" fill="black"/>
<path d="M277.764 208.006L269.846 215.924C274.816 221.766 277.838 229.351 277.838 237.64C277.838 255.239 264.264 269.667 247.017 271.039V282.24C270.439 280.85 289.002 261.415 289.002 237.64C289.002 226.272 284.756 215.906 277.764 208.024V208.006Z" fill="#248C9A"/>
<g opacity="0.8">
<path d="M247.017 193.04V204.241C254.231 204.816 260.814 207.672 266.025 212.104L273.944 204.186C266.693 197.751 257.328 193.652 247.017 193.04Z" fill="white"/>
</g>
<g opacity="0.6">
<path d="M241.621 271.038C225.264 269.74 212.227 256.703 210.929 240.347H199.728C201.082 262.879 219.107 280.904 241.621 282.239V271.038Z" fill="white"/>
</g>
<g opacity="0.7">
<path d="M210.929 234.933C212.227 218.576 225.283 205.539 241.621 204.241V193.04C219.089 194.394 201.063 212.42 199.728 234.933H210.929Z" fill="white"/>
</g>
<path opacity="0.5" d="M277.764 208.006L269.846 215.925C274.816 221.766 277.838 229.351 277.838 237.641C277.838 255.24 264.264 269.667 247.017 271.04V282.241C270.439 280.85 289.002 261.415 289.002 237.641C289.002 226.273 284.756 215.906 277.764 208.025V208.006Z" fill="white"/>
<path d="M325.592 181.225L339.779 200.456L421.524 166.538L501.211 194.874L573.405 148.178L578.579 155.059L595.733 126.982L557.29 123.978L563.187 131.748L490.937 177.998L412.437 150.144L325.592 181.225Z" fill="#248C9A"/>
<g opacity="0.5">
<path d="M325.592 181.225L339.779 200.456L421.524 166.538L501.211 194.874L573.405 148.178L578.579 155.059L595.733 126.982L557.29 123.978L563.187 131.748L490.937 177.998L412.437 150.144L325.592 181.225Z" fill="white"/>
</g>
<path d="M660.677 193.205C650.737 193.205 642.689 201.253 642.689 211.193C642.689 221.133 650.737 229.182 660.677 229.182C670.617 229.182 678.666 221.115 678.666 211.193C678.666 201.272 670.599 193.205 660.677 193.205ZM660.677 220.188C655.707 220.188 651.683 216.163 651.683 211.193C651.683 206.223 655.707 202.199 660.677 202.199C665.647 202.199 669.672 206.223 669.672 211.193C669.672 216.163 665.647 220.188 660.677 220.188Z" fill="#248C9A"/>
<path d="M706.038 245.983C696.098 245.983 688.049 254.032 688.049 263.972C688.049 273.912 696.098 281.96 706.038 281.96C715.978 281.96 724.026 273.893 724.026 263.972C724.026 254.05 715.959 245.983 706.038 245.983ZM706.038 272.966C701.068 272.966 697.044 268.942 697.044 263.972C697.044 259.002 701.068 254.978 706.038 254.978C711.008 254.978 715.032 259.002 715.032 263.972C715.032 268.942 711.008 272.966 706.038 272.966Z" fill="#248C9A"/>
<path d="M667.427 281.96H655.465L697.024 193.205H708.985L667.427 281.96Z" fill="#248C9A"/>
<g opacity="0.5">
<path d="M660.677 193.205C650.737 193.205 642.689 201.253 642.689 211.193C642.689 221.133 650.737 229.182 660.677 229.182C670.617 229.182 678.666 221.115 678.666 211.193C678.666 201.272 670.599 193.205 660.677 193.205ZM660.677 220.188C655.707 220.188 651.683 216.163 651.683 211.193C651.683 206.223 655.707 202.199 660.677 202.199C665.647 202.199 669.672 206.223 669.672 211.193C669.672 216.163 665.647 220.188 660.677 220.188Z" fill="white"/>
<path d="M706.038 245.983C696.098 245.983 688.049 254.032 688.049 263.972C688.049 273.912 696.098 281.96 706.038 281.96C715.978 281.96 724.026 273.893 724.026 263.972C724.026 254.05 715.959 245.983 706.038 245.983ZM706.038 272.966C701.068 272.966 697.044 268.942 697.044 263.972C697.044 259.002 701.068 254.978 706.038 254.978C711.008 254.978 715.032 259.002 715.032 263.972C715.032 268.942 711.008 272.966 706.038 272.966Z" fill="white"/>
<path d="M667.427 281.96H655.465L697.024 193.205H708.985L667.427 281.96Z" fill="white"/>
</g>
<path d="M454.774 201.161C455.275 203.461 455.72 205.445 456.276 207.541C456.832 209.636 457.37 211.62 458.001 213.735C459.206 217.796 460.597 221.82 462.136 225.937C465.196 234.449 469.406 242.516 474.635 249.897C476.175 251.955 477.899 253.884 479.754 255.664C481.849 257.667 484.223 259.355 486.819 260.653C491.789 263.064 497.297 264.139 502.823 263.75C507.645 263.416 512.392 262.303 516.862 260.449C518.976 259.596 521.034 258.613 523.019 257.5C524.02 256.962 524.984 256.369 525.912 255.701C526.839 255.034 527.729 254.459 528.804 253.68L523.927 244.927L521.331 245.613L518.605 246.262C516.769 246.67 514.97 247.004 513.116 247.263C509.759 247.764 506.365 247.838 502.99 247.467C500.301 247.189 497.705 246.262 495.461 244.76C494.497 244.074 493.607 243.276 492.809 242.386C491.826 241.236 490.918 240.031 490.102 238.751C488.21 235.784 486.578 232.65 485.187 229.405C483.667 226.048 482.331 222.506 481.07 218.871C479.809 215.237 478.678 211.546 477.547 207.837L475.952 202.218C475.451 200.364 474.895 198.398 474.506 196.785L454.792 201.161H454.774Z" fill="#FF8B7B"/>
<path d="M453.16 189.905C461.561 179.223 474.338 183.136 481.515 200.16C485.502 209.228 488.655 218.63 490.936 228.255L461.005 238.77C453.142 222.673 444.5 200.883 453.16 189.905Z" fill="#248C9A"/>
<path opacity="0.1" d="M453.16 189.905C461.561 179.223 474.338 183.136 481.515 200.16C485.502 209.228 488.655 218.63 490.936 228.255L461.005 238.77C453.142 222.673 444.5 200.883 453.16 189.905Z" fill="black"/>
<path opacity="0.3" d="M465.661 207.78C463.194 206.093 458.15 215.829 455.813 227.308C457.445 231.313 459.226 235.134 460.987 238.713L478.994 232.389C476.046 221.633 471.206 211.563 465.661 207.78Z" fill="black"/>
<path d="M525.448 240.494L531.995 236.915L534.276 251.547C534.276 251.547 523.334 258.13 520.2 252.678L520.534 248.895C520.905 245.52 522.685 242.46 525.43 240.475L525.448 240.494Z" fill="#FF8B7B"/>
<path d="M540.21 236.21L545.94 247.968L534.238 251.565L531.939 236.934L540.21 236.21Z" fill="#FF8B7B"/>
<path d="M453.05 180.335L437.732 178.184L418.65 179.853C412.697 180.372 407.82 184.804 406.744 190.683C404.63 202.218 403.48 218.593 402.887 232.705C402.219 248.542 403.962 271.964 406.522 293.087L470.278 285.372C470.89 229.756 475.1 212.51 475.619 197.767C475.842 191.833 472.077 186.473 466.421 184.674L453.069 180.316L453.05 180.335Z" fill="#248C9A"/>
<path d="M472.319 456.226L485.875 459.972L498.04 428.112L484.503 424.347L472.319 456.226Z" fill="#FF8B7B"/>
<path d="M491.956 455.743C490.899 454.352 489.323 453.444 487.617 453.11L473.653 450.402C473.004 450.254 472.355 450.625 472.151 451.255L467.83 463.996C467.31 465.442 468.089 467.037 469.536 467.556C469.573 467.556 469.591 467.575 469.629 467.593C474.784 469.615 478.252 468.928 486.3 470.486C491.252 471.45 501.674 474.751 508.647 474.473C515.601 474.195 515.582 467.296 512.652 466.796C502.267 465.164 494.312 458.914 491.956 455.78V455.743Z" fill="#263238"/>
<g opacity="0.2">
<path d="M498.04 428.13L491.568 445.079L478.216 440.777L484.484 424.365L498.04 428.13Z" fill="black"/>
</g>
<path d="M531.884 334.127C531.439 339.059 530.456 344.048 529.14 349.055C525.561 362.889 519.441 376.983 514.378 390.725C504.364 417.875 495.667 441.538 495.667 441.538L477.066 435.121C477.066 435.121 493.775 356.566 505.013 348.536H436.008C434.673 348.536 433.375 348.443 432.151 348.276C444.038 346.737 462.564 339.412 479.755 332.087C494.943 325.596 509.149 319.161 516.863 316.657H516.882C526.265 320.607 532.589 326.189 531.866 334.108L531.884 334.127Z" fill="#263238"/>
<path opacity="0.1" d="M531.884 334.127C531.439 339.059 530.456 344.048 529.14 349.055C525.561 362.889 519.441 376.983 514.378 390.725C504.364 417.875 495.667 441.538 495.667 441.538L477.066 435.121C477.066 435.121 493.775 356.566 505.013 348.536H436.008C434.673 348.536 433.375 348.443 432.151 348.276C444.038 346.737 462.564 339.412 479.755 332.087C494.943 325.596 509.149 319.161 516.863 316.657H516.882C526.265 320.607 532.589 326.189 531.866 334.108L531.884 334.127Z" fill="black"/>
<path opacity="0.6" d="M516.881 316.676C509.167 319.179 494.961 325.615 479.773 332.105C462.582 339.43 444.056 346.755 432.169 348.295C412.141 345.624 404.611 321.757 425.548 294.255C439.142 294.274 452.809 307.274 452.809 307.274C452.809 307.274 493.923 307.033 516.881 316.694V316.676Z" fill="black"/>
<path d="M566.84 428.817H582.01L566.117 390.726L555.806 408.214L566.84 428.817Z" fill="#FF8B7B"/>
<path d="M580.767 425.219L562.946 421.176C562.315 421.009 561.666 421.362 561.444 421.974L556.474 435.678C556.047 437.125 556.882 438.646 558.347 439.072C558.384 439.072 558.402 439.072 558.44 439.091C564.689 440.389 567.767 440.704 575.593 442.484C580.415 443.578 592.617 446.787 599.275 448.289C605.933 449.791 607.676 443.282 605.024 442.039C593.137 436.531 586.702 431.283 583.252 426.795C582.64 425.998 581.75 425.442 580.767 425.219Z" fill="#263238"/>
<g opacity="0.2">
<path d="M566.118 390.745L572.886 406.972L559.219 414.593L555.807 408.233L566.118 390.745Z" fill="black"/>
</g>
<path d="M573.09 401.185L556.381 411.292C556.381 411.292 542.472 382.047 529.139 349.056C530.455 344.03 531.42 339.06 531.883 334.127C532.607 326.19 526.283 320.608 516.899 316.695L516.881 316.676C509.166 319.18 494.961 325.615 479.773 332.106C462.582 339.431 444.056 346.756 432.168 348.295C430.685 348.481 429.331 348.592 428.051 348.592C405.204 348.592 397.323 328.564 406.54 294.59C420.133 294.534 443.406 293.125 443.406 293.125C443.406 293.125 488.322 282.332 520.645 286.022C536.39 287.802 549.148 299.634 552.245 315.23C560.461 356.492 573.09 401.222 573.09 401.222V401.185Z" fill="#263238"/>
<path d="M573.09 401.185L556.381 411.292C556.381 411.292 542.472 382.047 529.139 349.056C530.455 344.03 531.42 339.06 531.883 334.127C532.607 326.19 526.283 320.608 516.899 316.695L516.881 316.676C509.166 319.18 494.961 325.615 479.773 332.106C462.582 339.431 444.056 346.756 432.168 348.295C430.685 348.481 429.331 348.592 428.051 348.592C405.204 348.592 397.323 328.564 406.54 294.59C420.133 294.534 443.406 293.125 443.406 293.125C443.406 293.125 488.322 282.332 520.645 286.022C536.39 287.802 549.148 299.634 552.245 315.23C560.461 356.492 573.09 401.222 573.09 401.222V401.185Z" fill="#263238"/>
<path d="M453.05 180.335L437.732 178.184L418.65 179.853C412.697 180.372 407.82 184.804 406.744 190.683C404.63 202.218 403.48 218.593 402.887 232.705C402.219 248.543 403.962 271.965 406.522 293.087L470.278 285.372C470.89 229.757 475.1 212.51 475.619 197.767C475.842 191.833 472.077 186.473 466.421 184.674L453.069 180.316L453.05 180.335Z" fill="#248C9A"/>
<path d="M406.484 289.582L404.834 294.756C404.611 295.145 405.075 295.572 405.742 295.609L469.388 291.789C469.926 291.789 470.352 291.566 470.408 291.214L471.076 286.003C471.076 285.632 470.686 285.335 470.111 285.298L407.486 289.155C407.096 289.1 406.707 289.248 406.466 289.563L406.484 289.582Z" fill="#263238"/>
<path opacity="0.1" d="M406.484 289.582L404.834 294.756C404.611 295.145 405.075 295.572 405.742 295.609L469.388 291.789C469.926 291.789 470.352 291.566 470.408 291.214L471.076 286.003C471.076 285.632 470.686 285.335 470.111 285.298L407.486 289.155C407.096 289.1 406.707 289.248 406.466 289.563L406.484 289.582Z" fill="black"/>
<path opacity="0.3" d="M412.937 185.453C425.659 183.228 425.028 212.083 419.242 233.224C417.573 239.344 410.322 236.785 407.874 235.19C399.863 229.979 397.156 194.688 412.937 185.472V185.453Z" fill="black"/>
<path d="M411.398 228.2C411.88 240.699 407.912 247.227 414.551 251.399C421.208 255.572 460.542 268.609 461.506 266.977C459.596 270 457.76 272.559 455.85 275.582C444.519 273.875 433.188 272.318 421.82 270.871C413.531 269.814 400.16 267.682 393.669 262.433C387.791 257.686 388.829 236.823 389.83 221.802C395.134 218.705 411.398 228.2 411.398 228.2Z" fill="#FF8B7B"/>
<path d="M408.005 180.353C421.079 176.514 426.03 186.992 422.043 204.943C420.04 214.642 417.166 224.137 413.457 233.298L386.122 224.675C389.071 207.039 394.616 184.285 407.986 180.353H408.005Z" fill="#248C9A"/>
<path d="M426.939 180.279C426.475 183.005 427.402 185.805 428.905 188.123C430.407 190.423 432.465 192.314 434.505 194.187C437.083 196.561 440.161 199.083 443.648 198.62C446.856 198.193 448.692 196.58 449.99 192.759C452.735 197.192 452.864 199.398 455.887 199.677C457.853 199.862 459.559 198.434 460.931 197.025C463.361 194.54 464.436 193.112 464.881 189.662C465.605 184.062 461.043 178.851 455.757 176.885C450.064 174.771 445.02 174.27 439.104 173.584C434.171 173.009 427.922 174.548 426.939 180.297V180.279Z" fill="#248C9A"/>
<path opacity="0.3" d="M426.939 180.279C426.475 183.005 427.402 185.805 428.905 188.123C430.407 190.423 432.465 192.314 434.505 194.187C437.083 196.561 440.161 199.083 443.648 198.62C446.856 198.193 448.692 196.58 449.99 192.759C452.735 197.192 452.864 199.398 455.887 199.677C457.853 199.862 459.559 198.434 460.931 197.025C463.361 194.54 464.436 193.112 464.881 189.662C465.605 184.062 461.043 178.851 455.757 176.885C450.064 174.771 445.02 174.27 439.104 173.584C434.171 173.009 427.922 174.548 426.939 180.297V180.279Z" fill="black"/>
<path d="M440.31 153.371L447.227 161.364L456.629 172.231C455.758 173.381 455.109 174.698 454.701 176.089C453.699 179.149 453.996 182.19 457.316 184.415C458.762 185.083 459.485 192.593 450.046 191.796C443.611 191.444 439.531 189.2 435.785 185.305C434.042 182.987 433.597 181.726 435.377 179.204C440.496 172.046 440.885 161.642 440.329 153.371H440.31Z" fill="#FF8B7B"/>
<path opacity="0.2" d="M454.368 169.541L448.433 161.678C448.359 161.919 448.285 162.16 448.192 162.42C447.914 163.533 447.71 164.645 447.599 165.795C447.246 169.69 450.9 174.678 454.46 176.811L454.739 176.032C455.147 174.734 455.74 173.491 456.5 172.341C456.556 172.286 456.612 172.23 456.667 172.175L454.349 169.523L454.368 169.541Z" fill="black"/>
<path d="M440.96 138.665C439.884 151.554 438.697 159.046 444.242 166.371C452.606 177.442 469.241 173.529 474.099 161.141C478.476 149.996 479.384 130.783 467.553 124.144C458.874 119.341 447.951 122.494 443.167 131.173C441.887 133.472 441.145 136.032 440.96 138.665Z" fill="#FF8B7B"/>
<path d="M439.067 150.071L444.575 152.463C446.485 151.684 446.856 149.44 446.726 147.4C446.244 139.537 460.338 129.857 460.338 129.857C460.338 129.857 445.335 124.942 440.829 124.182C439.253 123.923 433.578 128.039 432.688 129.838C430.203 134.882 431.223 140.872 434.635 145.342C435.952 147.066 437.435 148.643 439.067 150.089V150.071Z" fill="#263238"/>
<path d="M434.319 147.752C434.579 150.627 435.896 153.316 438.01 155.281C440.773 157.785 444.13 155.04 444.519 151.387C444.853 148.123 443.202 143.339 439.883 142.244C436.545 141.15 434.004 144.154 434.319 147.734V147.752Z" fill="#FF8B7B"/>
<path d="M460.301 146.398C460.097 147.455 460.486 148.438 461.191 148.586C461.896 148.734 462.526 148.011 462.786 146.954C463.045 145.897 462.6 144.914 461.896 144.747C461.191 144.58 460.486 145.322 460.301 146.379V146.398Z" fill="#263238"/>
<path d="M472.188 149.013C471.984 150.089 472.374 151.072 473.079 151.22C473.765 151.369 474.469 150.645 474.673 149.588C474.877 148.55 474.469 147.548 473.783 147.4C473.097 147.252 472.374 147.956 472.188 149.013Z" fill="#263238"/>
<path d="M468.22 148.958C468.591 151.981 469.351 154.948 470.482 157.785C468.554 158.805 466.069 157.359 466.069 157.359L468.22 148.958Z" fill="#FF5652"/>
<path d="M463.083 163.366C460.691 162.532 458.762 160.751 457.724 158.452C457.649 158.285 457.724 158.1 457.89 158.044C458.057 157.988 458.243 158.062 458.298 158.229C459.467 160.714 461.729 162.532 464.418 163.125C464.585 163.144 464.715 163.311 464.696 163.496C464.678 163.663 464.511 163.793 464.325 163.774H464.288C463.88 163.682 463.472 163.552 463.083 163.403V163.366Z" fill="#263238"/>
<path d="M458.669 142.132C458.558 142.095 458.484 142.039 458.41 141.965C458.187 141.687 458.224 141.298 458.484 141.075C459.986 139.721 462.082 139.221 464.029 139.74C464.363 139.833 464.548 140.185 464.455 140.519C464.455 140.519 464.455 140.519 464.455 140.537C464.344 140.871 463.992 141.057 463.658 140.945C462.119 140.574 460.505 140.982 459.318 142.039C459.152 142.188 458.892 142.243 458.688 142.151L458.669 142.132Z" fill="#263238"/>
<path d="M473.542 142.151C473.505 142.058 473.487 141.947 473.505 141.835C473.598 141.502 473.95 141.298 474.284 141.39C476.25 141.854 477.845 143.282 478.549 145.192C478.661 145.526 478.475 145.878 478.142 145.971C477.808 146.082 477.455 145.897 477.363 145.563C476.788 144.079 475.527 142.985 473.988 142.614C473.784 142.54 473.617 142.373 473.58 142.151H473.542Z" fill="#263238"/>
<path d="M477.474 288.266L558.849 288.507C561.723 288.507 564.282 286.17 564.542 283.296L568.269 242.108C568.529 239.233 566.396 236.878 563.522 236.878L482.148 236.637C479.273 236.637 476.714 238.974 476.454 241.848L472.727 283.036C472.467 285.911 474.6 288.266 477.474 288.266Z" fill="#248C9A"/>
<path opacity="0.3" d="M477.474 288.266L558.849 288.507C561.723 288.507 564.282 286.17 564.542 283.296L568.269 242.108C568.529 239.233 566.396 236.878 563.522 236.878L482.148 236.637C479.273 236.637 476.714 238.974 476.454 241.848L472.727 283.036C472.467 285.911 474.6 288.266 477.474 288.266Z" fill="black"/>
<path d="M479.867 288.099L561.241 288.341C564.115 288.341 566.674 286.004 566.934 283.129L570.662 241.942C570.921 239.067 568.789 236.712 565.914 236.712L484.54 236.471C481.665 236.471 479.106 238.808 478.847 241.682L475.119 282.87C474.859 285.744 476.992 288.099 479.867 288.099Z" fill="#248C9A"/>
<path opacity="0.2" d="M479.867 288.099L561.241 288.341C564.115 288.341 566.674 286.004 566.934 283.129L570.662 241.942C570.921 239.067 568.789 236.712 565.914 236.712L484.54 236.471C481.665 236.471 479.106 238.808 478.847 241.682L475.119 282.87C474.859 285.744 476.992 288.099 479.867 288.099Z" fill="white"/>
<path d="M528.602 262.99C528.287 266.143 525.468 268.442 522.297 268.127C519.145 267.812 516.845 264.993 517.16 261.822C517.476 258.651 520.294 256.37 523.465 256.685C526.618 257 528.918 259.819 528.602 262.99Z" fill="white"/>
<path d="M562.353 288.284C562.353 290.75 560.888 292.753 559.108 292.753H559.071L475.082 292.419C474.173 292.419 473.431 291.399 473.431 290.194C473.45 288.951 474.173 287.931 475.082 287.931L562.353 288.284Z" fill="#248C9A"/>
<path opacity="0.3" d="M562.353 288.284C562.353 290.75 560.888 292.753 559.108 292.753H559.071L475.082 292.419C474.173 292.419 473.431 291.399 473.431 290.194C473.45 288.951 474.173 287.931 475.082 287.931L562.353 288.284Z" fill="black"/>
<path d="M474.655 292.438H434.227C432.076 292.438 430.333 290.695 430.333 288.543C430.333 288.21 430.611 287.931 430.945 287.931H474.655C475.897 287.931 476.899 288.933 476.899 290.175C476.899 291.418 475.897 292.419 474.655 292.419V292.438Z" fill="#248C9A"/>
<path d="M452.81 261.209L459.523 259.243L458.911 275.767C458.911 275.767 444.335 279.197 443.426 272.484L445.299 268.868C446.894 265.734 449.119 262.118 452.81 261.19V261.209Z" fill="#FF8B7B"/>
<path d="M469.573 263.842L467.589 273.114L458.91 275.766L459.522 259.261L469.573 263.842Z" fill="#FF8B7B"/>
<path d="M472.225 433.712L500.042 443.151L505.735 431.653L474.97 421.88L472.225 433.712Z" fill="#248C9A"/>
<path d="M552.135 413.591L577.783 399.274L573.072 387.35L545.2 403.632L552.135 413.591Z" fill="#248C9A"/>
<path opacity="0.2" d="M525.895 261.876C525.783 261.394 525.579 260.968 525.301 260.597L525.617 260.393L526.154 260.059L525.116 258.297L524.374 258.742L525.209 259.911L525.116 259.966L524.875 260.096C524.467 259.725 523.985 259.466 523.447 259.373L523.41 260.226C523.669 260.282 523.892 260.393 524.114 260.541L523.373 260.949L523.076 266.309C523.373 266.29 523.669 266.216 523.948 266.086C524.782 265.734 525.45 265.01 525.783 264.065C525.913 263.694 525.839 263.267 525.598 262.952C525.876 262.655 526.006 262.247 525.913 261.858L525.895 261.876ZM525.209 263.286C525.079 264.083 524.615 264.769 524.003 265.14V265.085L524.059 263.768L524.133 262.006V261.339L524.652 261.023C525.005 261.45 525.227 262.006 525.264 262.618C525.264 262.729 524.448 262.433 524.411 262.915C524.374 263.49 525.246 263.193 525.227 263.286H525.209ZM520.276 262.692C519.997 262.989 519.868 263.397 519.96 263.787C520.183 264.751 520.776 265.548 521.555 265.993C521.815 266.142 522.112 266.253 522.408 266.309L522.705 260.949L522.019 260.467C522.241 260.356 522.501 260.263 522.761 260.244L522.798 259.391C522.241 259.429 521.741 259.632 521.296 259.966L521.073 259.799L520.98 259.744L521.945 258.668L521.258 258.149L520.035 259.781L520.535 260.17L520.832 260.393C520.517 260.727 520.276 261.135 520.109 261.598C519.979 261.969 520.053 262.396 520.294 262.711L520.276 262.692ZM521.463 262.748C521.481 262.284 520.628 262.47 520.647 262.377C520.739 261.765 521.036 261.246 521.425 260.856L521.871 261.209L521.815 261.876L521.685 263.638L521.592 264.955V265.01C521.017 264.565 520.647 263.842 520.591 263.026C520.591 262.915 521.407 263.304 521.444 262.748H521.463Z" fill="#0036CC"/>
<path d="M478.384 138.516C482.185 138.312 483.743 133.805 483.298 131.858C482.705 129.336 481.203 127.055 479.014 125.701C480.238 121.899 478.588 117.449 473.989 114.333C471.003 112.293 466.089 112.126 462.862 113.517C462.862 110.161 456.686 107.954 452.736 112.126C449.269 110.198 444.929 110.309 442.092 113.091C441.164 113.999 440.497 115.427 440.923 116.67C437.789 116.707 434.729 118.561 433.264 121.343C431.799 124.106 431.966 127.685 433.71 130.3C434.971 132.192 437.901 132.155 440.126 131.673C441.183 133.935 443.483 135.103 445.856 135.882C453.2 138.312 463.251 136.031 465.032 135.827C466.812 135.623 468.703 135.827 470.187 136.865C469.686 137.848 468.37 138.293 467.275 138.46C466.181 138.645 465.069 138.46 463.975 138.293C466.682 139.814 469.352 140.685 472.375 140.018C473.284 139.814 475.324 138.404 476.233 138.33C477.16 138.256 478.476 138.664 478.384 138.534V138.516Z" fill="#263238"/>
</g>
<defs>
<clipPath id="clip0_15_4316">
<rect width="927.236" height="689.975" fill="white" transform="translate(0.503418 0.0249023)"/>
</clipPath>
</defs>
</svg>


}