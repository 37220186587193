export default function BgSvg(props) {
    return <svg {...props} 
    width="1440" height="1064" viewBox="0 0 1440 1064" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.15">
<path d="M1643.22 1245.47L1643.09 1244.9C1662.21 1240.61 1688.56 1231.72 1696.8 1204.19C1703.42 1182.1 1697.71 1157.17 1690.56 1133.51C1682.32 1106.25 1671.02 1079.6 1660.1 1053.82C1648.71 1026.94 1636.92 999.141 1628.59 970.608C1618.51 936.133 1619.02 899.879 1619.5 864.823C1619.61 857.096 1619.72 849.107 1619.72 841.27C1619.7 812.841 1619.7 784.406 1619.71 755.977V722.423C1619.71 709.209 1619.66 695.768 1619.6 682.776C1619.34 620.251 1619.07 555.603 1624.81 492.158C1626.64 471.875 1628.74 451.307 1630.76 431.414C1636.71 372.962 1642.87 312.515 1643.05 252.492C1643.2 201.284 1643.41 149.215 1643.62 98.8619C1643.83 45.5535 1644.05 -9.56447 1644.2 -63.7806H1644.78C1644.63 -9.56447 1644.41 45.5593 1644.2 98.8619C1644 149.215 1643.79 201.284 1643.63 252.492C1643.45 312.544 1637.3 373.003 1631.34 431.466C1629.32 451.354 1627.22 471.922 1625.39 492.199C1619.66 555.614 1619.93 620.251 1620.18 682.764C1620.24 695.757 1620.3 709.197 1620.3 722.411V755.966C1620.3 784.395 1620.3 812.829 1620.31 841.258C1620.31 849.096 1620.2 857.09 1620.09 864.817C1619.6 899.832 1619.1 936.04 1629.15 970.427C1637.48 998.932 1649.25 1026.71 1660.64 1053.58C1671.57 1079.37 1682.87 1106.04 1691.12 1133.33C1698.3 1157.06 1704.02 1182.1 1697.37 1204.34C1689.04 1232.15 1662.48 1241.12 1643.23 1245.45L1643.22 1245.47Z" fill="url(#paint0_linear_15_4142)"/>
<path d="M1626.17 1245.46L1626.04 1244.89C1655.73 1238.4 1672.47 1225.97 1678.71 1205.78C1685.86 1182.69 1678.99 1156.65 1671.98 1134.06C1664.31 1109.29 1654.07 1084.97 1644.18 1061.46C1632.71 1034.19 1620.84 1006 1612.89 976.938C1603.52 942.673 1603.86 905.703 1604.2 869.943C1604.27 861.628 1604.35 853.029 1604.3 844.697C1604.13 816.571 1604.21 787.973 1604.28 760.324C1604.31 747.814 1604.34 735.305 1604.35 722.795C1604.39 687.338 1604.27 645.491 1603.17 603.925C1602.21 567.676 1605.65 530.822 1608.97 495.184L1609.15 493.241C1610.63 477.42 1612.18 461.362 1613.69 445.832C1619.52 385.635 1625.55 323.389 1628.16 261.731C1630.47 207.003 1633.61 151.402 1636.66 97.6342C1639.91 40.1831 1643.28 -19.2289 1645.63 -77.7099L1646.21 -77.6866C1643.87 -19.1998 1640.5 40.2063 1637.24 97.6633C1634.2 151.431 1631.05 207.032 1628.74 261.755C1626.14 323.43 1620.1 385.687 1614.27 445.89C1612.77 461.42 1611.21 477.479 1609.73 493.293L1609.55 495.236C1606.23 530.857 1602.79 567.693 1603.75 603.907C1604.85 645.48 1604.97 687.338 1604.93 722.795C1604.92 735.305 1604.89 747.814 1604.86 760.324C1604.78 787.979 1604.71 816.571 1604.88 844.691C1604.93 853.023 1604.85 861.628 1604.78 869.943C1604.45 905.656 1604.1 942.592 1613.45 976.775C1621.39 1005.8 1633.25 1033.98 1644.71 1061.22C1654.61 1084.75 1664.85 1109.08 1672.53 1133.88C1679.56 1156.56 1686.46 1182.7 1679.26 1205.95C1670.97 1232.76 1644.98 1241.34 1626.16 1245.45L1626.17 1245.46Z" fill="url(#paint1_linear_15_4142)"/>
<path d="M1609.8 1245.43L1609.68 1244.86C1638.73 1238.52 1655.11 1226.37 1661.22 1206.64C1668.19 1184.12 1661.5 1158.68 1654.68 1136.6C1647.4 1113.01 1637.72 1089.82 1628.35 1067.39C1617.08 1040.38 1605.42 1012.45 1597.85 983.618C1588.77 948.969 1588.95 911.091 1589.13 874.464C1589.17 866.464 1589.21 858.184 1589.15 850.213C1588.92 821.266 1589.16 791.842 1589.41 763.384C1589.51 750.753 1589.62 737.696 1589.69 724.855C1589.88 688.396 1589.81 645.328 1587.82 602.377C1586.46 573.081 1589.36 543.081 1592.17 514.065C1592.78 507.763 1593.41 501.247 1593.98 494.864C1600.55 421.819 1607.33 346.296 1612.85 269.935C1617.05 211.798 1622.88 152.822 1628.52 95.7897C1634.54 34.8068 1640.78 -28.2474 1645.04 -90.429L1645.62 -90.3882C1641.36 -28.1951 1635.13 34.865 1629.1 95.8479C1623.46 152.874 1617.63 211.844 1613.44 269.976C1607.92 346.337 1601.13 421.872 1594.57 494.916C1594 501.293 1593.37 507.816 1592.76 514.117C1589.95 543.11 1587.05 573.099 1588.4 602.348C1590.39 645.317 1590.47 688.391 1590.28 724.855C1590.21 737.702 1590.1 750.758 1589.99 763.39C1589.75 791.848 1589.5 821.272 1589.74 850.213C1589.8 858.19 1589.76 866.464 1589.72 874.47C1589.54 911.062 1589.35 948.893 1598.42 983.472C1605.97 1012.27 1617.62 1040.18 1628.89 1067.16C1638.26 1089.61 1647.95 1112.81 1655.25 1136.42C1662.09 1158.58 1668.81 1184.13 1661.79 1206.81C1653.67 1233.03 1628.24 1241.41 1609.81 1245.42L1609.8 1245.43Z" fill="url(#paint2_linear_15_4142)"/>
<path d="M1594.07 1245.35L1593.94 1244.78C1622.2 1238.49 1638.15 1226.57 1644.13 1207.27C1651.2 1184.44 1644.09 1158.29 1636.87 1135.58C1630.08 1114.21 1621.31 1093.09 1612.83 1072.67C1601.34 1045.01 1589.47 1016.4 1582.34 986.783C1578.67 971.55 1577.95 956.777 1577.2 941.143C1577.01 937.146 1576.8 933.009 1576.55 928.907C1575.18 906.605 1574.41 882.691 1574.2 855.793C1573.96 825.49 1574.48 794.687 1574.99 764.897C1575.2 752.713 1575.41 740.116 1575.57 727.723C1576.05 690.485 1576.19 646.481 1573.58 602.458C1571.96 574.943 1574.61 546.729 1577.19 519.447C1577.93 511.545 1578.7 503.382 1579.35 495.393C1581.36 470.543 1583.28 445.25 1585.13 420.79C1588.78 372.782 1592.54 323.139 1597.23 274.445C1603.06 213.898 1611.25 152.613 1619.17 93.346C1627.71 29.4364 1636.55 -36.6493 1642.44 -101.979L1643.02 -101.926C1637.12 -36.5852 1628.29 29.5063 1619.75 93.4217C1611.83 152.682 1603.64 213.968 1597.81 274.503C1593.12 323.191 1589.35 372.834 1585.71 420.836C1583.85 445.297 1581.93 470.589 1579.92 495.446C1579.27 503.44 1578.5 511.609 1577.75 519.511C1575.18 546.77 1572.53 574.96 1574.15 602.435C1576.75 646.475 1576.62 690.491 1576.13 727.741C1575.98 740.134 1575.76 752.731 1575.55 764.915C1575.05 794.699 1574.52 825.502 1574.76 855.793C1574.97 882.685 1575.74 906.587 1577.11 928.878C1577.36 932.986 1577.56 937.117 1577.76 941.12C1578.51 956.731 1579.23 971.475 1582.88 986.655C1590 1016.24 1601.87 1044.82 1613.35 1072.45C1621.83 1092.89 1630.61 1114.02 1637.4 1135.41C1644.65 1158.21 1651.78 1184.46 1644.66 1207.45C1636.73 1233.09 1611.97 1241.37 1594.04 1245.36L1594.07 1245.35Z" fill="url(#paint3_linear_15_4142)"/>
<path d="M1578.91 1245.22L1578.77 1244.66C1606.31 1238.24 1621.8 1226.35 1627.53 1207.23C1634.29 1184.66 1627.27 1158.86 1620.15 1136.44C1613.66 1115.98 1605.31 1095.72 1597.24 1076.12C1585.87 1048.5 1574.11 1019.94 1567.32 990.344C1564.18 976.665 1563.55 962.776 1562.94 949.347C1562.7 944.023 1562.45 938.513 1562.05 933.05C1560.36 910.137 1559.49 885.606 1559.37 858.073C1559.24 827.073 1560.13 795.572 1560.98 765.107C1561.33 752.655 1561.69 739.779 1561.98 727.112C1562.85 689.036 1563.3 644.019 1560.12 598.804C1558.35 573.651 1560.68 547.788 1562.94 522.775C1563.79 513.372 1564.66 503.656 1565.3 494.154C1566.63 474.342 1567.82 454.164 1568.98 434.655C1572.02 383.331 1575.16 330.255 1581.13 278.384C1588.36 215.428 1598.73 151.856 1608.75 90.3728C1619.56 24.0777 1630.73 -44.475 1638.02 -112.423L1638.59 -112.359C1631.3 -44.3936 1620.13 24.1649 1609.32 90.4717C1599.3 151.943 1588.94 215.51 1581.7 278.453C1575.74 330.307 1572.6 383.371 1569.56 434.69C1568.4 454.205 1567.21 474.383 1565.88 494.195C1565.24 503.696 1564.36 513.425 1563.51 522.827C1561.26 547.817 1558.93 573.663 1560.7 598.764C1563.89 644.002 1563.43 689.036 1562.56 727.124C1562.28 739.791 1561.91 752.667 1561.56 765.118C1560.71 795.578 1559.82 827.079 1559.95 858.068C1560.06 885.589 1560.94 910.102 1562.62 933.003C1563.02 938.472 1563.27 943.988 1563.52 949.318C1564.13 962.724 1564.76 976.583 1567.88 990.21C1574.67 1019.77 1586.41 1048.3 1597.77 1075.9C1605.85 1095.51 1614.19 1115.79 1620.7 1136.27C1627.84 1158.76 1634.88 1184.67 1628.07 1207.39C1622.28 1226.74 1606.66 1238.76 1578.9 1245.23L1578.91 1245.22Z" fill="url(#paint4_linear_15_4142)"/>
<path d="M1564.29 1245.02L1564.15 1244.45C1590.89 1237.81 1605.88 1225.82 1611.31 1206.74C1617.7 1184.29 1610.71 1158.71 1603.66 1136.49C1597.42 1116.83 1589.46 1097.32 1581.75 1078.45C1570.45 1050.76 1558.77 1022.12 1552.27 992.427C1549.49 979.713 1548.98 966.802 1548.48 954.31C1548.23 948.166 1547.98 941.818 1547.46 935.528C1545.52 912.016 1544.61 886.933 1544.67 858.853C1544.74 827.224 1546.08 795.095 1547.36 764.03C1547.89 751.334 1548.43 738.202 1548.89 725.291C1550.24 686.47 1551.14 640.54 1547.5 594.225C1545.67 570.969 1547.62 547.014 1549.51 523.845C1550.38 513.279 1551.27 502.346 1551.78 491.67C1552.64 473.679 1553.32 455.363 1553.98 437.646C1555.9 385.972 1557.89 332.542 1564.66 280.42C1573.09 215.51 1585.4 150.14 1597.3 86.9167C1610.14 18.7538 1623.41 -51.7364 1631.85 -121.819L1632.43 -121.75C1623.98 -51.6491 1610.71 18.8469 1597.87 87.0214C1585.97 150.233 1573.66 215.597 1565.24 280.49C1558.47 332.588 1556.48 386.001 1554.56 437.663C1553.9 455.38 1553.22 473.702 1552.35 491.699C1551.84 502.387 1550.95 513.32 1550.09 523.892C1548.2 547.038 1546.26 570.969 1548.08 594.179C1551.72 640.522 1550.83 686.476 1549.47 725.308C1549.01 738.225 1548.47 751.358 1547.94 764.053C1546.65 795.112 1545.32 827.236 1545.25 858.853C1545.2 886.915 1546.1 911.981 1548.04 935.476C1548.56 941.777 1548.82 948.131 1549.06 954.281C1549.56 966.744 1550.07 979.632 1552.84 992.293C1559.32 1021.94 1571 1050.55 1582.3 1078.22C1590 1097.1 1597.97 1116.63 1604.22 1136.31C1611.29 1158.61 1618.31 1184.29 1611.88 1206.89C1606.38 1226.2 1591.26 1238.31 1564.29 1245.02H1564.29Z" fill="url(#paint5_linear_15_4142)"/>
<path d="M1550.14 1244.72L1549.99 1244.16C1575.99 1237.14 1590.45 1224.88 1595.48 1205.59C1601.16 1183.82 1594.66 1159.47 1588.14 1138.35C1581.95 1118.33 1573.95 1098.48 1566.21 1079.3C1555.26 1052.13 1543.93 1024.04 1537.53 994.929C1534.65 981.802 1534.16 968.443 1533.69 955.52C1533.44 948.759 1533.18 941.766 1532.59 934.848C1530.52 910.788 1529.68 885.129 1530.04 856.398C1530.43 824.862 1532.26 792.861 1534.03 761.906C1534.81 748.181 1535.62 733.99 1536.29 720.031C1538.21 680.402 1539.59 633.471 1535.34 585.87C1533.49 565.174 1535.1 543.715 1536.66 522.961C1537.52 511.371 1538.42 499.385 1538.7 487.731C1539.1 470.723 1539.27 453.408 1539.42 436.662C1539.91 385.961 1540.4 333.531 1547.59 282.375C1557.01 215.37 1571.18 148.086 1584.87 83.0183C1599.51 13.459 1614.65 -58.4625 1624.03 -130.204L1624.61 -130.128C1615.23 -58.3636 1600.08 13.5754 1585.44 83.1405C1571.75 148.196 1557.59 215.469 1548.17 282.456C1540.98 333.571 1540.49 385.984 1540.01 436.668C1539.85 453.414 1539.68 470.729 1539.28 487.742C1539 499.414 1538.1 511.406 1537.24 523.002C1535.68 543.733 1534.08 565.168 1535.92 585.818C1540.18 633.453 1538.79 680.408 1536.88 720.06C1536.2 734.025 1535.39 748.216 1534.61 761.941C1532.84 792.884 1531.01 824.879 1530.62 856.403C1530.27 885.117 1531.1 910.765 1533.16 934.801C1533.76 941.731 1534.02 948.73 1534.26 955.503C1534.74 968.397 1535.22 981.727 1538.1 994.806C1544.48 1023.87 1555.8 1051.94 1566.75 1079.08C1574.49 1098.28 1582.5 1118.13 1588.7 1138.18C1595.25 1159.37 1601.76 1183.82 1596.04 1205.74C1593.31 1216.22 1587.53 1224.91 1578.86 1231.56C1571.55 1237.18 1562.16 1241.48 1550.14 1244.73V1244.72Z" fill="url(#paint6_linear_15_4142)"/>
<path d="M1536.42 1244.32L1536.25 1243.76C1561.03 1236.39 1575.29 1223.46 1579.86 1204.25C1585.08 1182.35 1578.51 1157.98 1571.96 1136.87C1565.94 1117.51 1558.25 1098.28 1550.81 1079.68C1539.81 1052.19 1528.44 1023.78 1522.24 994.312C1519.57 981.622 1519.16 968.705 1518.78 956.213C1518.55 949.039 1518.32 941.626 1517.66 934.301C1515.47 909.788 1514.74 883.686 1515.42 854.501C1516.16 822.476 1518.59 790.015 1520.94 758.619C1521.98 744.696 1523.06 730.301 1523.97 716.139C1526.57 675.933 1528.61 628.298 1524.1 579.796C1522.31 560.531 1523.47 540.597 1524.6 521.32C1525.33 508.787 1526.09 495.824 1526.02 483.192C1525.94 466.767 1525.55 450.068 1525.19 433.916C1524.04 383.726 1522.84 331.82 1530.45 281.043C1540.65 212.909 1556.36 144.705 1571.55 78.7476C1587.79 8.2399 1604.59 -64.6708 1614.69 -137.651L1615.27 -137.57C1605.16 -64.5661 1588.36 8.35627 1572.12 78.8756C1556.93 144.822 1541.23 213.019 1531.03 281.13C1523.43 331.861 1524.62 383.738 1525.77 433.904C1526.15 450.056 1526.53 466.761 1526.61 483.192C1526.68 495.841 1525.92 508.811 1525.19 521.355C1524.06 540.608 1522.89 560.525 1524.69 579.743C1529.21 628.286 1527.16 675.951 1524.56 716.179C1523.65 730.347 1522.57 744.742 1521.53 758.666C1519.18 790.056 1516.76 822.511 1516.01 854.518C1515.33 883.68 1516.06 909.764 1518.26 934.254C1518.91 941.597 1519.15 949.021 1519.37 956.201C1519.76 968.67 1520.16 981.558 1522.82 994.201C1529.01 1023.61 1540.37 1052.01 1551.36 1079.47C1558.8 1098.08 1566.5 1117.32 1572.53 1136.71C1579.11 1157.9 1585.69 1182.35 1580.44 1204.4C1575.82 1223.83 1561.42 1236.89 1536.44 1244.32L1536.42 1244.32Z" fill="url(#paint7_linear_15_4142)"/>
<path d="M1523.08 1243.8L1522.9 1243.25C1546.84 1235.31 1560.46 1221.91 1564.54 1202.28C1569.02 1180.71 1562.84 1157.44 1556.69 1137.33C1550.68 1117.65 1542.91 1098.1 1535.4 1079.21C1524.52 1051.86 1513.28 1023.57 1507.13 994.277C1504.3 980.825 1503.9 967.116 1503.51 953.862C1503.29 946.292 1503.07 938.467 1502.39 930.74C1500.36 907.542 1499.78 883.133 1500.62 856.118C1501.67 821.976 1504.93 787.42 1508.09 753.999C1509.29 741.298 1510.52 728.165 1511.62 715.248C1515.12 674.293 1518.09 625.779 1513.76 576.496C1512.16 558.291 1512.78 539.625 1513.37 521.576C1513.82 507.81 1514.3 493.578 1513.78 479.596C1513.17 462.77 1512.12 445.669 1511.11 429.133C1508.12 380.212 1505.03 329.627 1512.74 279.949C1523.53 210.465 1540.75 141.15 1557.4 74.1103C1575.04 3.06734 1593.29 -70.3961 1603.92 -144.191L1604.49 -144.11C1593.86 -70.2856 1575.61 3.18952 1557.96 74.25C1541.32 141.272 1524.11 210.576 1513.32 280.036C1505.61 329.65 1508.7 380.206 1511.69 429.093C1512.7 445.634 1513.75 462.735 1514.36 479.573C1514.87 493.578 1514.4 507.822 1513.95 521.594C1513.35 539.625 1512.74 558.273 1514.33 576.444C1518.67 625.773 1515.69 674.316 1512.2 715.301C1511.1 728.224 1509.86 741.362 1508.66 754.063C1505.51 787.473 1502.25 822.022 1501.2 856.148C1500.37 883.139 1500.94 907.53 1502.97 930.699C1503.65 938.438 1503.88 946.275 1504.1 953.856C1504.49 967.087 1504.89 980.767 1507.7 994.166C1513.85 1023.41 1525.08 1051.67 1535.95 1079C1543.46 1097.91 1551.24 1117.46 1557.25 1137.17C1563.42 1157.36 1569.62 1180.71 1565.11 1202.41C1560.98 1222.27 1547.24 1235.81 1523.09 1243.82L1523.08 1243.8Z" fill="url(#paint8_linear_15_4142)"/>
<path d="M1510.06 1243.14L1509.86 1242.6C1532.44 1234.18 1545.12 1219.37 1548.65 1197.33C1551.33 1180.64 1548.72 1160.47 1540.46 1133.87C1534.6 1114.99 1527.15 1096.18 1519.95 1077.98C1508.77 1049.71 1497.2 1020.48 1491.22 990.192C1488.57 976.717 1488.23 962.951 1487.91 949.638C1487.72 941.847 1487.52 933.789 1486.87 925.858C1484.91 902.311 1484.6 877.53 1485.91 850.102C1487.52 816.338 1491.48 782.213 1495.3 749.216C1496.89 735.497 1498.53 721.317 1499.99 707.353C1504.3 665.856 1508.08 616.655 1503.33 566.355C1501.77 549.877 1502.04 532.992 1502.31 516.666C1502.55 501.695 1502.8 486.218 1501.65 471.014C1500.46 455.374 1498.86 439.531 1497.32 424.211C1492.34 374.946 1487.18 324.006 1495.4 273.496C1506.64 204.373 1524.85 135.623 1542.46 69.1414C1561.33 -2.05871 1580.82 -75.6793 1591.81 -149.922L1592.38 -149.835C1581.4 -75.5629 1561.89 -1.92487 1543.03 69.2927C1525.42 135.762 1507.21 204.501 1495.97 273.595C1487.77 324.035 1492.92 374.935 1497.9 424.158C1499.45 439.484 1501.05 455.334 1502.23 470.979C1503.39 486.212 1503.14 501.701 1502.89 516.683C1502.63 532.998 1502.36 549.86 1503.91 566.308C1508.67 616.655 1504.89 665.897 1500.56 707.423C1499.11 721.387 1497.46 735.578 1495.87 749.292C1492.05 782.283 1488.09 816.39 1486.48 850.137C1485.18 877.542 1485.49 902.299 1487.44 925.823C1488.1 933.771 1488.3 941.835 1488.49 949.632C1488.81 962.921 1489.15 976.659 1491.8 990.088C1497.76 1020.33 1509.32 1049.53 1520.49 1077.78C1527.69 1095.98 1535.14 1114.8 1541.01 1133.71C1549.3 1160.4 1551.91 1180.65 1549.22 1197.43C1545.71 1219.39 1532.53 1234.76 1510.06 1243.14H1510.06Z" fill="url(#paint9_linear_15_4142)"/>
<path d="M1497.33 1242.33L1497.1 1241.8C1519.07 1232.53 1531.08 1216.87 1533.8 1193.94C1536.43 1171.87 1529.6 1146.77 1524.23 1129.81C1518.49 1111.67 1511.34 1093.53 1504.42 1076C1492.88 1046.73 1480.94 1016.47 1475.12 985.09C1472.97 973.511 1472.8 961.816 1472.65 950.505C1472.54 942.83 1472.43 934.9 1471.7 927.039C1469.3 901.071 1469.11 873.62 1471.15 843.108C1473.39 809.652 1478.09 775.911 1482.64 743.282C1484.53 729.771 1486.48 715.795 1488.22 702.041C1493.42 661.044 1498.18 612.408 1493.94 562.509C1492.69 547.753 1492.54 532.748 1492.4 518.237C1492.23 501.177 1492.06 483.541 1490.1 466.132C1488.28 450.05 1485.94 433.811 1483.68 418.107C1476.53 368.534 1469.15 317.28 1477.85 265.793C1489.4 197.391 1508.42 129.572 1526.81 63.9862C1546.73 -7.03929 1567.32 -80.4795 1578.49 -154.845L1579.07 -154.757C1567.89 -80.3573 1547.3 -6.89963 1527.37 64.1433C1508.99 129.711 1489.97 197.519 1478.42 265.886C1469.74 317.286 1477.12 368.499 1484.26 418.026C1486.52 433.73 1488.86 449.975 1490.68 466.068C1492.64 483.506 1492.82 501.159 1492.99 518.231C1493.13 532.73 1493.28 547.724 1494.53 562.462C1498.77 612.419 1494.02 661.091 1488.81 702.116C1487.06 715.877 1485.12 729.853 1483.23 743.363C1478.68 775.987 1473.97 809.716 1471.74 843.149C1469.7 873.632 1469.88 901.054 1472.29 926.993C1473.01 934.871 1473.13 942.819 1473.24 950.505C1473.4 961.787 1473.57 973.459 1475.71 984.991C1481.51 1016.32 1493.44 1046.55 1504.98 1075.79C1511.9 1093.34 1519.06 1111.48 1524.81 1129.64C1530.19 1146.65 1537.04 1171.83 1534.4 1194.02C1533.12 1204.83 1529.66 1214.23 1524.13 1221.95C1517.93 1230.59 1508.92 1237.46 1497.35 1242.34L1497.33 1242.33Z" fill="url(#paint10_linear_15_4142)"/>
<path d="M1484.81 1241.36L1484.56 1240.83C1505.88 1230.6 1517.15 1214.03 1519.02 1190.15C1520.74 1168.22 1514.01 1143.89 1507.96 1125.15C1502.34 1107.71 1495.44 1090.22 1488.76 1073.31C1476.79 1042.96 1464.41 1011.57 1458.76 979.021C1456.72 967.291 1456.58 955.34 1456.44 943.779C1456.34 935.994 1456.24 927.947 1455.57 920.016C1453.43 894.794 1453.57 868.18 1456.02 838.663C1458.85 804.457 1464.52 770.052 1470.02 736.777C1472.21 723.499 1474.47 709.768 1476.53 696.246C1482.96 653.847 1488.92 603.535 1484.32 551.879C1483.19 539.107 1482.82 526.202 1482.46 513.716C1481.94 495.725 1481.41 477.124 1478.57 458.679C1476.08 442.539 1472.93 426.224 1469.89 410.439C1460.52 361.89 1450.83 311.688 1459.43 260.963C1471.1 192.166 1491.14 124.236 1510.54 58.5402C1531.35 -11.9791 1552.87 -84.8956 1564.06 -159.051L1564.64 -158.964C1553.44 -84.7735 1531.91 -11.8337 1511.09 58.7031C1491.71 124.382 1471.67 192.288 1460.01 261.056C1451.43 311.677 1461.1 361.826 1470.46 410.322C1473.51 426.108 1476.66 442.434 1479.15 458.58C1482 477.06 1482.53 495.679 1483.04 513.687C1483.4 526.161 1483.77 539.055 1484.91 551.815C1489.51 603.535 1483.54 653.887 1477.1 696.321C1475.05 709.849 1472.79 723.58 1470.59 736.858C1465.11 770.122 1459.43 804.515 1456.6 838.698C1454.16 868.186 1454.02 894.764 1456.15 919.958C1456.83 927.906 1456.93 935.965 1457.02 943.761C1457.16 955.299 1457.3 967.227 1459.33 978.91C1464.98 1011.4 1477.35 1042.76 1489.31 1073.08C1495.98 1090.01 1502.89 1107.5 1508.52 1124.95C1514.59 1143.75 1521.33 1168.15 1519.61 1190.18C1517.72 1214.28 1506.35 1231.01 1484.82 1241.34L1484.81 1241.36Z" fill="url(#paint11_linear_15_4142)"/>
<path d="M1472.47 1240.19L1472.19 1239.68C1492.85 1228.36 1503.32 1210.66 1504.19 1185.6C1504.96 1163.61 1498.14 1139.71 1491.36 1119.23C1485.85 1102.6 1479.29 1085.89 1472.95 1069.74C1461.2 1039.83 1449.05 1008.89 1442.96 976.891C1440.26 962.694 1440.02 948.166 1439.79 934.12C1439.66 926.283 1439.53 918.178 1438.97 910.212C1437.25 885.67 1437.85 859.912 1440.8 831.466C1444.34 797.346 1450.93 763.122 1457.31 730.027C1459.78 717.18 1462.34 703.902 1464.69 690.817C1472.38 647.836 1479.66 596.855 1475.29 544.635C1474.42 534.307 1473.93 523.933 1473.45 513.902C1472.5 493.921 1471.52 473.26 1467.53 452.709C1464.29 435.981 1460.09 418.928 1456.04 402.438C1444.39 355.134 1432.35 306.213 1440.53 256.937C1452.08 187.366 1473.23 119.017 1493.67 52.9138C1515.24 -16.8142 1537.53 -88.9162 1548.61 -162.56L1549.19 -162.473C1538.1 -88.7823 1515.8 -16.6571 1494.23 53.0883C1473.79 119.168 1452.65 187.5 1441.11 257.03C1432.95 306.19 1444.97 355.047 1456.61 402.299C1460.67 418.794 1464.86 435.853 1468.11 452.593C1472.11 473.19 1473.09 493.869 1474.04 513.867C1474.52 523.898 1475.01 534.266 1475.88 544.582C1480.26 596.873 1472.97 647.9 1465.27 690.916C1462.92 704.007 1460.36 717.291 1457.89 730.138C1451.52 763.221 1444.93 797.434 1441.39 831.53C1438.44 859.947 1437.85 885.67 1439.56 910.177C1440.12 918.154 1440.26 926.271 1440.38 934.114C1440.62 948.137 1440.85 962.642 1443.54 976.787C1449.62 1008.74 1461.76 1039.64 1473.5 1069.53C1479.85 1085.7 1486.41 1102.41 1491.92 1119.05C1498.72 1139.58 1505.56 1163.54 1504.79 1185.63C1503.9 1210.91 1493.34 1228.77 1472.48 1240.2L1472.47 1240.19Z" fill="url(#paint12_linear_15_4142)"/>
<path d="M1460.23 1238.84L1459.95 1238.33C1502.27 1214.66 1491.22 1161.92 1474.83 1113.46C1469.42 1097.46 1463.08 1081.36 1456.95 1065.77C1444.72 1034.66 1432.06 1002.48 1425.92 969.165C1423.43 955.66 1423.28 941.894 1423.13 928.581C1423.04 920.883 1422.96 912.924 1422.42 905.08C1420.72 880.282 1421.6 854.315 1425.11 825.694C1429.37 790.987 1437.08 756.321 1444.53 722.801C1447.57 709.151 1450.71 695.035 1453.57 681.118C1461.49 642.588 1470.14 591.822 1466.7 540.859C1466.32 535.215 1465.98 529.53 1465.65 524.032C1463.97 496.185 1462.39 469.885 1455.8 442.609C1451.96 426.695 1447.2 410.485 1442.6 394.804C1428.49 346.733 1413.9 297.02 1422.47 246.254C1433.96 178.184 1455.48 111.569 1476.29 47.1419C1498.48 -21.5388 1521.43 -92.5643 1532.26 -165.469L1532.83 -165.382C1521.99 -92.4247 1499.04 -21.3817 1476.85 47.3223C1456.04 111.726 1434.52 178.324 1423.04 246.353C1414.49 296.985 1429.06 346.628 1443.15 394.642C1447.76 410.328 1452.52 426.55 1456.37 442.475C1462.96 469.798 1464.55 496.127 1466.22 524.003C1466.55 529.501 1466.9 535.18 1467.28 540.824C1470.72 591.857 1462.06 642.669 1454.14 681.24C1451.28 695.163 1448.14 709.285 1445.1 722.935C1437.65 756.443 1429.95 791.097 1425.69 825.775C1422.18 854.355 1421.3 880.288 1423 905.051C1423.55 912.906 1423.63 920.877 1423.71 928.581C1423.86 941.87 1424.01 955.613 1426.49 969.066C1432.62 1002.32 1445.27 1034.48 1457.5 1065.56C1463.63 1081.15 1469.96 1097.27 1475.38 1113.28C1482.47 1134.24 1489.76 1158.71 1490.04 1181.2C1490.2 1193.78 1488.12 1204.79 1483.86 1213.92C1478.96 1224.43 1471.01 1232.82 1460.23 1238.85V1238.84Z" fill="url(#paint13_linear_15_4142)"/>
<path d="M1448.08 1237.26L1447.77 1236.77C1466.76 1224.51 1475.5 1205.21 1474.5 1177.76C1473.69 1155.69 1466.62 1132.19 1459.1 1110.08C1453.56 1093.78 1447.03 1077.32 1440.71 1061.41C1427.69 1028.6 1414.22 994.667 1408.3 959.5C1406.1 946.438 1405.98 933.026 1405.87 920.057C1405.81 912.627 1405.74 904.947 1405.29 897.418C1403.77 872.381 1405.09 846.192 1409.35 817.362C1414.43 782.899 1423.16 748.606 1431.6 715.441C1434.94 702.297 1438.4 688.711 1441.58 675.299C1450.8 636.438 1461 585.265 1457.98 533.999C1456.08 501.689 1454.11 468.274 1444.44 434.213C1439.94 418.369 1434.38 402.159 1429.01 386.473C1412.44 338.087 1395.31 288.054 1403.97 236.625C1415.25 169.655 1437.22 104.407 1458.47 41.306C1481.17 -26.1237 1504.64 -95.8517 1515.12 -167.808L1515.7 -167.727C1505.21 -95.7179 1481.73 -25.9608 1459.02 41.4922C1437.78 104.57 1415.82 169.794 1404.55 236.724C1395.91 288.007 1413.02 337.97 1429.57 386.286C1434.94 401.973 1440.49 418.195 1445 434.056C1454.69 468.181 1456.66 501.625 1458.56 533.97C1461.59 585.306 1451.37 636.537 1442.14 675.433C1438.96 688.845 1435.5 702.436 1432.16 715.58C1423.72 748.728 1415 783.004 1409.92 817.443C1405.67 846.233 1404.34 872.381 1405.86 897.377C1406.32 904.923 1406.39 912.609 1406.46 920.045C1406.57 932.991 1406.69 946.38 1408.88 959.395C1414.78 994.504 1428.24 1028.4 1441.25 1061.19C1447.57 1077.11 1454.1 1093.57 1459.65 1109.88C1467.19 1132.03 1474.27 1155.59 1475.08 1177.73C1475.54 1190.44 1473.85 1201.65 1470.02 1211.04C1465.61 1221.88 1458.23 1230.7 1448.09 1237.25L1448.08 1237.26Z" fill="url(#paint14_linear_15_4142)"/>
<path d="M1435.94 1235.46L1435.59 1234.99C1453.64 1221.5 1461.42 1201.08 1459.37 1172.55C1457.57 1147.49 1448.31 1120.96 1442.19 1103.41C1436.71 1087.7 1430.4 1071.85 1424.3 1056.53C1411.11 1023.37 1397.47 989.093 1391.04 953.56C1388.33 938.566 1388.19 923.077 1388.04 908.1C1387.98 901.235 1387.91 894.136 1387.59 887.195C1386.45 862.175 1388.39 835.998 1393.51 807.168C1399.49 773.503 1409.13 740.146 1418.45 707.882C1422.46 693.994 1426.61 679.628 1430.42 665.437C1454.73 574.687 1455.85 497.191 1433.87 428.528C1428.61 412.074 1421.9 395.02 1415.42 378.53C1396.36 330.016 1376.65 279.85 1385.01 228.624C1395.86 162.126 1418.42 97.704 1440.24 35.4061C1463.35 -30.5806 1487.26 -98.8133 1497.26 -169.624L1497.84 -169.542C1487.82 -98.6737 1463.91 -30.4177 1440.79 35.5981C1418.98 97.8727 1396.43 162.271 1385.58 228.718C1377.25 279.786 1396.93 329.877 1415.96 378.321C1422.44 394.822 1429.14 411.882 1434.42 428.359C1446.06 464.713 1451.22 504.621 1449.77 546.962C1448.51 583.799 1442.19 623.713 1430.97 665.594C1427.16 679.791 1423.02 694.157 1419 708.051C1409.68 740.297 1400.04 773.642 1394.07 807.279C1388.97 836.062 1387.03 862.199 1388.17 887.171C1388.48 894.119 1388.55 901.229 1388.62 908.094C1388.76 923.048 1388.91 938.513 1391.61 953.455C1398.03 988.93 1411.66 1023.18 1424.84 1056.31C1430.95 1071.65 1437.25 1087.51 1442.73 1103.22C1448.87 1120.8 1458.14 1147.37 1459.95 1172.5C1462.01 1201.24 1454.16 1221.84 1435.94 1235.45L1435.94 1235.46Z" fill="url(#paint15_linear_15_4142)"/>
<path d="M1423.78 1233.4L1423.39 1232.96C1461.63 1199.77 1439.39 1136.91 1426.11 1099.36C1420.45 1083.38 1413.97 1067.25 1407.71 1051.65C1394.03 1017.59 1379.89 982.378 1373.09 945.879C1370.41 931.508 1370.28 916.88 1370.14 902.736C1370.08 896.021 1370.02 889.074 1369.68 882.22C1368.45 856.927 1370.64 830.57 1376.39 801.652C1383.25 767.149 1394.33 733.187 1405.04 700.342C1409.38 687.052 1413.86 673.303 1418.01 659.723C1445.96 567.984 1447.44 489.721 1422.5 420.452C1416.6 404.056 1409.27 387.142 1402.19 370.78C1380.39 320.457 1357.86 268.417 1367.01 214.09C1377.73 150.407 1400.1 88.9473 1421.72 29.5121C1445.15 -34.863 1469.38 -101.437 1478.85 -170.968L1479.43 -170.892C1469.95 -101.298 1445.71 -34.7001 1422.27 29.7041C1400.65 89.116 1378.3 150.547 1367.58 214.177C1358.46 268.335 1380.96 320.294 1402.72 370.542C1409.8 386.909 1417.13 403.835 1423.04 420.248C1448.02 489.639 1446.56 568.025 1418.56 659.886C1414.42 673.478 1409.93 687.221 1405.6 700.516C1394.88 733.344 1383.81 767.289 1376.96 801.757C1371.23 830.628 1369.04 856.939 1370.27 882.185C1370.6 889.051 1370.66 896.004 1370.73 902.724C1370.85 916.845 1370.99 931.45 1373.66 945.769C1380.45 982.21 1394.58 1017.4 1408.25 1051.43C1414.51 1067.03 1421 1083.17 1426.66 1099.16C1440 1136.86 1462.32 1199.95 1423.78 1233.4L1423.78 1233.4Z" fill="url(#paint16_linear_15_4142)"/>
<path d="M1411.53 1231.08L1411.12 1230.67C1446.25 1195.1 1423.93 1133.85 1409.16 1093.32L1408.66 1091.94C1403.07 1076.6 1396.76 1061.08 1390.65 1046.06C1376.21 1010.57 1361.29 973.872 1354.29 935.784C1351.43 920.191 1351.33 904.126 1351.23 888.585C1351.19 882.831 1351.15 876.879 1350.97 871.043C1350.2 845.901 1353.1 819.707 1359.82 790.958C1367.65 757.42 1379.69 724.587 1391.33 692.83C1396.45 678.883 1401.74 664.465 1406.59 650.187C1436.99 560.845 1438.78 484.059 1412.09 415.442C1405.43 398.33 1397.25 381.207 1388.59 363.082C1364.31 312.235 1339.19 259.654 1347.95 205.281C1358.08 142.326 1380.89 81.971 1402.95 23.6005C1426.62 -39.0464 1451.1 -103.829 1459.95 -171.933L1460.52 -171.858C1451.67 -103.683 1427.18 -38.8719 1403.49 23.81C1381.45 82.1514 1358.65 142.483 1348.52 205.374C1339.79 259.567 1364.87 312.067 1389.12 362.832C1397.79 380.968 1405.97 398.098 1412.64 415.233C1426.87 451.819 1433.1 491.385 1431.16 532.823C1429.43 569.509 1421.58 607.963 1407.15 650.373C1402.29 664.657 1397 679.081 1391.89 693.028C1380.25 724.767 1368.22 757.583 1360.39 791.086C1353.68 819.782 1350.79 845.93 1351.56 871.019C1351.74 876.867 1351.77 882.819 1351.82 888.579C1351.91 904.091 1352.02 920.127 1354.87 935.674C1361.86 973.703 1376.77 1010.37 1391.19 1045.83C1397.3 1060.85 1403.61 1076.38 1409.2 1091.73L1409.7 1093.11C1424.52 1133.78 1446.92 1195.24 1411.53 1231.08V1231.08Z" fill="url(#paint17_linear_15_4142)"/>
<path d="M1399.15 1228.49L1398.7 1228.11C1430.78 1190 1407.47 1128.25 1392.05 1087.39L1390.54 1083.39C1385.1 1068.94 1379.06 1054.3 1373.23 1040.14C1358.27 1003.85 1342.8 966.319 1335.37 927.353C1332.11 910.224 1332.02 892.664 1331.94 875.686C1331.92 871.048 1331.89 866.254 1331.81 861.541C1331.36 837.354 1334.55 812.364 1341.58 785.139C1350.42 750.915 1364.05 717.71 1377.24 685.604C1382.68 672.338 1388.31 658.618 1393.53 645.02C1407.81 607.782 1424.02 558.535 1422.65 507.787C1421.72 473.382 1414.69 441.736 1400.53 408.187C1392.4 388.945 1383.11 369.849 1374.12 351.387C1366.12 334.95 1357.85 317.949 1350.43 300.901C1336.45 268.801 1323.26 234.402 1328.66 197.909C1337.94 135.169 1361.35 75.4893 1383.98 17.7821C1407.84 -43.0495 1432.51 -105.947 1440.66 -172.521L1441.24 -172.451C1433.08 -105.807 1408.39 -42.8691 1384.52 17.9916C1361.9 75.6755 1338.5 135.32 1329.23 197.996C1323.86 234.338 1337.02 268.644 1350.96 300.668C1358.38 317.71 1366.65 334.7 1374.65 351.131C1383.64 369.605 1392.93 388.707 1401.06 407.96C1415.25 441.579 1422.3 473.295 1423.23 507.769C1424.6 558.622 1408.36 607.939 1394.07 645.229C1388.86 658.833 1383.22 672.553 1377.77 685.825C1364.59 717.919 1350.97 751.102 1342.15 785.285C1335.13 812.457 1331.94 837.395 1332.39 861.529C1332.48 866.248 1332.5 871.043 1332.52 875.686C1332.6 892.641 1332.69 910.171 1335.94 927.249C1343.36 966.156 1358.81 1003.66 1373.76 1039.92C1379.6 1054.08 1385.64 1068.72 1391.08 1083.18L1392.59 1087.19C1408.06 1128.17 1431.45 1190.11 1399.14 1228.49H1399.15Z" fill="url(#paint18_linear_15_4142)"/>
<path d="M1386.57 1225.6L1386.1 1225.26C1398.87 1207.31 1402.59 1184.04 1397.46 1154.11C1393.07 1128.52 1382.87 1102.39 1374.67 1081.4C1373.86 1079.32 1373.06 1077.27 1372.28 1075.28C1366.95 1061.53 1361.11 1047.58 1355.47 1034.09C1340.11 997.367 1324.22 959.401 1316.11 919.999C1312.31 901.502 1312.19 882.627 1312.08 864.369C1312.05 860.802 1312.03 857.224 1311.98 853.64C1311.65 828.644 1315.6 802.729 1324.08 774.41C1333.97 741.391 1348.56 709.57 1362.66 678.796C1368.54 665.978 1374.61 652.724 1380.26 639.574C1397.05 600.468 1414.12 553.77 1413.46 503.935C1413.01 470.019 1405.44 438.553 1388.93 401.909C1380.08 382.272 1370.05 362.803 1360.35 343.981C1351.91 327.602 1343.19 310.664 1335.27 293.68C1319.5 259.846 1304.58 223.586 1310.25 185.027C1319.14 124.544 1342.41 67.3609 1364.92 12.0568C1388.91 -46.878 1413.71 -107.814 1421.13 -172.83L1421.71 -172.766C1414.28 -107.669 1389.46 -46.6919 1365.47 12.2721C1342.97 67.5413 1319.71 124.696 1310.83 185.103C1305.19 223.504 1320.07 259.672 1335.8 293.43C1343.72 310.408 1352.44 327.34 1360.87 343.713C1370.58 362.547 1380.61 382.016 1389.47 401.67C1406.01 438.39 1413.6 469.932 1414.05 503.929C1414.72 553.874 1397.61 600.643 1380.8 639.807C1375.15 652.962 1369.07 666.222 1363.2 679.04C1349.1 709.797 1334.52 741.6 1324.65 774.579C1316.19 802.839 1312.24 828.702 1312.58 853.634C1312.62 857.218 1312.65 860.796 1312.67 864.369C1312.79 882.598 1312.9 901.444 1316.7 919.883C1324.79 959.233 1340.67 997.174 1356.02 1033.87C1361.66 1047.36 1367.5 1061.31 1372.84 1075.07C1373.61 1077.07 1374.41 1079.11 1375.22 1081.19C1383.43 1102.21 1393.64 1128.36 1398.04 1154.01C1403.2 1184.1 1399.45 1207.52 1386.59 1225.6H1386.57Z" fill="url(#paint19_linear_15_4142)"/>
<path d="M1373.76 1222.4L1373.26 1222.1C1398.94 1178.92 1374.45 1118.49 1356.58 1074.36C1355.43 1071.52 1354.31 1068.75 1353.23 1066.06C1348.07 1053.18 1342.48 1040.09 1337.07 1027.42C1320.62 988.924 1303.62 949.114 1295.31 907.681C1286.54 863.909 1289.9 813.242 1304.52 768.679C1315.55 735.078 1331.9 703.094 1347.7 672.163C1354.37 659.112 1361.26 645.619 1367.66 632.202C1385.53 594.708 1403.8 549.819 1403.89 501.165C1403.95 468.326 1396 436.68 1378.12 398.557C1368.31 377.64 1357.15 356.979 1346.35 336.993C1336.9 319.497 1327.13 301.407 1318.3 283.213C1301.76 249.123 1286.07 212.6 1291.55 173.89C1299.87 115.095 1323.19 59.9017 1345.74 6.52928C1369.83 -50.4855 1394.74 -109.444 1401.38 -172.882L1401.96 -172.824C1395.3 -109.304 1370.38 -50.3051 1346.27 6.75621C1323.74 60.0937 1300.44 115.252 1292.12 173.972C1286.66 212.525 1302.31 248.954 1318.82 282.963C1327.65 301.145 1337.41 319.229 1346.86 336.719C1357.66 356.711 1368.82 377.384 1378.64 398.313C1396.56 436.517 1404.52 468.239 1404.46 501.171C1404.37 549.941 1386.07 594.906 1368.17 632.458C1361.77 645.887 1354.88 659.38 1348.21 672.436C1332.41 703.344 1316.08 735.31 1305.06 768.871C1290.47 813.341 1287.12 863.898 1295.87 907.577C1304.16 948.946 1321.15 988.732 1337.59 1027.2C1343 1039.87 1348.59 1052.96 1353.76 1065.86C1354.84 1068.54 1355.96 1071.31 1357.11 1074.15C1375.03 1118.4 1399.58 1178.98 1373.75 1222.41L1373.76 1222.4Z" fill="url(#paint20_linear_15_4142)"/>
<path d="M1360.67 1218.86L1360.14 1218.63C1380.75 1171.01 1357.38 1114.25 1338.6 1068.64C1337.63 1066.29 1336.68 1063.96 1335.74 1061.68C1330.25 1048.26 1324.29 1034.63 1318.53 1021.44C1301 981.319 1282.88 939.834 1274.13 896.562C1265.06 851.644 1269.25 802.554 1285.93 758.351C1298.08 726.141 1315.37 695.739 1332.1 666.339C1338.64 654.842 1345.4 642.949 1351.75 631.137C1372.1 593.265 1393.03 548.004 1393.89 499.431C1394.64 457.498 1379.57 421.179 1364.73 391.185C1354.62 370.745 1343.29 350.509 1332.34 330.947C1322.52 313.405 1312.37 295.269 1303.12 277.034C1284.26 239.866 1267.76 203.332 1272.7 164.959C1280.17 107.089 1303.77 53.2513 1326.6 1.18797C1350.77 -53.9183 1375.76 -110.904 1381.62 -172.742L1382.2 -172.69C1376.33 -110.759 1351.33 -53.7321 1327.14 1.42072C1304.33 53.4491 1280.73 107.246 1273.28 165.035C1268.35 203.244 1284.83 239.691 1303.63 276.772C1312.88 294.995 1323.03 313.125 1332.85 330.662C1343.8 350.235 1355.14 370.472 1365.26 390.929C1380.13 420.987 1395.23 457.387 1394.48 499.443C1393.61 548.137 1372.65 593.48 1352.26 631.411C1345.91 643.234 1339.14 655.127 1332.6 666.624C1315.89 696.007 1298.61 726.391 1286.47 758.555C1269.83 802.664 1265.65 851.632 1274.7 896.446C1283.44 939.653 1301.54 981.116 1319.06 1021.2C1324.82 1034.39 1330.78 1048.03 1336.27 1061.46C1337.21 1063.74 1338.17 1066.06 1339.13 1068.42C1357.95 1114.12 1381.38 1171.02 1360.67 1218.86Z" fill="url(#paint21_linear_15_4142)"/>
<path d="M1347.23 1215L1346.68 1214.81C1363.82 1164.93 1339.36 1107.6 1319.7 1061.53C1318.45 1058.62 1317.23 1055.75 1316.03 1052.91C1310.68 1040.29 1304.95 1027.45 1299.4 1015.03C1281.33 974.553 1262.63 932.695 1253.05 889.004C1242.85 842.515 1247.74 792.599 1266.81 748.448C1280.11 717.64 1298.36 688.833 1315.99 660.974C1323.5 649.116 1331.26 636.857 1338.53 624.621C1359.81 588.779 1381.79 545.822 1383.46 498.954C1384.91 458.179 1369.24 421.139 1353.71 390.278C1342.55 368.086 1329.96 346.192 1317.78 325.018C1307.79 307.644 1297.46 289.677 1287.97 271.64C1270.7 238.818 1249.21 197.967 1253.99 156.272C1260.53 99.2983 1284.43 46.8161 1307.54 -3.93806C1331.78 -57.1708 1356.84 -112.213 1361.89 -172.498L1362.47 -172.451C1357.42 -112.068 1332.33 -56.9788 1308.07 -3.6995C1284.98 47.0139 1261.1 99.4554 1254.57 156.336C1249.81 197.857 1271.25 238.615 1288.49 271.367C1297.97 289.398 1308.31 307.354 1318.29 324.727C1330.47 345.906 1343.06 367.807 1354.24 390.016C1369.79 420.941 1385.5 458.068 1384.05 498.972C1382.38 545.973 1360.36 589.012 1339.03 624.917C1331.76 637.159 1324 649.425 1316.49 661.283C1298.86 689.124 1280.63 717.913 1267.34 748.681C1248.32 792.721 1243.45 842.515 1253.62 888.882C1263.19 932.514 1281.87 974.343 1299.94 1014.8C1305.48 1027.22 1311.22 1040.06 1316.57 1052.69C1317.77 1055.52 1319 1058.4 1320.24 1061.31C1339.94 1107.47 1364.46 1164.91 1347.24 1215H1347.23Z" fill="url(#paint22_linear_15_4142)"/>
<path d="M1333.4 1210.78L1332.84 1210.64C1346.29 1159.17 1322.22 1104.34 1300.98 1055.96C1299.73 1053.13 1298.51 1050.33 1297.3 1047.56C1291.75 1034.83 1285.82 1021.9 1280.08 1009.39C1261.05 967.89 1241.38 924.985 1230.93 880.119C1225.56 857.102 1224.03 833.717 1226.37 810.624C1228.73 787.327 1234.99 764.775 1244.98 743.59C1259.61 712.584 1279.7 684.108 1299.13 656.57C1306.91 645.544 1314.96 634.14 1322.52 622.753C1345.78 587.749 1369.9 545.781 1372.44 499.932C1374.69 459.273 1358.51 421.732 1340.7 387.229C1329.07 364.688 1316.04 342.427 1303.44 320.899C1292.41 302.059 1281.01 282.573 1270.57 262.971C1249.5 223.359 1231 184.358 1236.03 142.704C1242.56 88.604 1265.98 39.095 1288.63 -8.79062C1312.93 -60.1673 1338.05 -113.301 1342.31 -172.149L1342.9 -172.108C1338.63 -113.15 1313.48 -59.9695 1289.16 -8.54044C1266.53 39.2987 1243.13 88.7728 1236.61 142.774C1231.6 184.259 1250.06 223.173 1271.09 262.697C1281.52 282.294 1292.92 301.768 1303.94 320.608C1316.54 342.142 1329.58 364.403 1341.22 386.961C1359.07 421.54 1375.28 459.162 1373.02 499.967C1370.48 545.955 1346.31 588.011 1323.01 623.079C1315.44 634.471 1307.39 645.881 1299.61 656.907C1280.19 684.428 1260.11 712.886 1245.51 743.84C1225.49 786.309 1220.64 833.392 1231.5 879.986C1241.95 924.787 1261.61 967.669 1280.62 1009.14C1286.36 1021.65 1292.29 1034.59 1297.84 1047.31C1299.05 1050.08 1300.28 1052.89 1301.53 1055.72C1311.72 1078.94 1323.27 1105.25 1330.33 1131.62C1338.43 1161.85 1339.44 1187.75 1333.42 1210.77L1333.4 1210.78Z" fill="url(#paint23_linear_15_4142)"/>
<path d="M1319.13 1206.2L1318.56 1206.09C1328.45 1152.68 1303.19 1097.28 1280.91 1048.4C1279.39 1045.08 1277.9 1041.81 1276.43 1038.57C1271.01 1026.58 1265.25 1014.38 1259.68 1002.59C1239.49 959.803 1218.61 915.565 1207.56 869.169C1202.03 845.954 1200.63 822.796 1203.41 800.343C1206.22 777.605 1213.33 755.553 1224.51 734.793C1240.37 705.38 1261.39 678.68 1281.7 652.863C1290.54 641.64 1299.67 630.038 1308.24 618.383C1332.31 585.649 1357.37 546.264 1360.72 502.335C1363.76 462.461 1347.33 424.31 1329.12 389.021C1316.54 364.642 1302.34 340.641 1288.61 317.425C1277.46 298.574 1265.92 279.076 1255.32 259.485C1237.36 226.297 1212.76 180.843 1217.58 135.082C1223.21 81.7266 1246.94 33.4221 1269.89 -13.2941C1294.29 -62.9543 1319.52 -114.308 1322.99 -171.776L1323.57 -171.741C1320.09 -114.151 1294.84 -62.7449 1270.42 -13.0381C1247.49 33.6315 1223.78 81.8895 1218.16 135.14C1213.35 180.721 1237.9 226.082 1255.83 259.2C1266.43 278.785 1277.96 298.271 1289.11 317.117C1302.85 340.338 1317.05 364.345 1329.64 388.742C1347.89 424.106 1364.35 462.345 1361.31 502.364C1357.95 546.444 1332.84 585.917 1308.71 618.715C1300.14 630.375 1291 641.983 1282.16 653.212C1261.86 679.011 1240.86 705.689 1225.03 735.06C1202.88 776.144 1197.03 822.465 1208.13 869.024C1219.17 915.362 1240.04 959.57 1260.22 1002.32C1265.78 1014.12 1271.54 1026.32 1276.97 1038.32C1278.43 1041.56 1279.93 1044.84 1281.44 1048.15C1303.77 1097.11 1329.07 1152.6 1319.14 1206.19L1319.13 1206.2Z" fill="url(#paint24_linear_15_4142)"/>
<path d="M1304.36 1201.22L1303.78 1201.16C1310.07 1145.98 1283.57 1090.06 1260.19 1040.72C1258.39 1036.94 1256.62 1033.19 1254.87 1029.48C1249.59 1018.23 1244.01 1006.77 1238.61 995.685C1217.2 951.721 1195.07 906.267 1183.36 858.422C1172.07 812.282 1178.21 768.993 1201.62 729.76C1218.82 700.929 1241.41 675.282 1263.26 650.478C1271.77 640.813 1280.57 630.817 1288.91 620.804C1317.19 586.818 1343.97 549.435 1348.35 506.379C1352.66 464.114 1333.66 423.699 1313.83 385.931C1301.28 362.023 1287.27 338.412 1273.72 315.581C1261.81 295.507 1249.49 274.747 1238.21 253.859C1220.07 220.287 1195.24 174.304 1199.35 128.14C1204.05 75.4136 1228.13 28.2146 1251.41 -17.4252C1275.94 -65.497 1301.3 -115.204 1304.02 -171.427L1304.6 -171.398C1301.88 -115.053 1276.48 -65.2875 1251.93 -17.1575C1228.67 28.4357 1204.62 75.5766 1199.93 128.193C1195.82 174.181 1220.62 220.071 1238.72 253.58C1249.99 274.456 1262.31 295.21 1274.22 315.278C1287.77 338.116 1301.78 361.733 1314.34 385.658C1334.21 423.507 1353.25 464.009 1348.93 506.437C1344.52 549.65 1317.7 587.121 1289.36 621.176C1281.02 631.201 1272.21 641.197 1263.7 650.862C1241.86 675.642 1219.29 701.267 1202.12 730.056C1178.79 769.15 1172.67 812.294 1183.92 858.283C1195.61 906.064 1217.74 951.494 1239.12 995.429C1244.52 1006.51 1250.1 1017.98 1255.39 1029.24C1257.13 1032.95 1258.91 1036.69 1260.7 1040.48C1284.11 1089.88 1310.65 1145.88 1304.35 1201.23L1304.36 1201.22Z" fill="url(#paint25_linear_15_4142)"/>
<path d="M1289.05 1195.84L1288.46 1195.82C1291.09 1139.07 1263.32 1082.75 1238.82 1033.04C1236.72 1028.77 1234.63 1024.54 1232.58 1020.35C1227.53 1010 1222.24 999.473 1217.12 989.285C1195 945.263 1172.12 899.733 1159.33 851.76C1146.7 804.422 1152.73 763.082 1177.74 725.373C1196.46 697.159 1220.73 672.646 1244.21 648.936C1253.85 639.202 1263.82 629.136 1273.23 618.983C1300.36 589.71 1329.76 554.899 1335.11 512.151C1340.33 470.485 1321.33 429.378 1301.42 390.842C1288.02 364.904 1272.91 339.355 1258.3 314.644C1245.74 293.401 1232.75 271.431 1220.9 249.303C1210.89 230.638 1199.57 208.859 1191.75 186.546C1183.1 161.87 1179.8 139.696 1181.66 118.773C1186.18 67.7799 1210.14 22.5416 1233.31 -21.2071C1257.99 -67.8069 1283.51 -115.995 1285.52 -171.177L1286.1 -171.154C1284.09 -115.838 1258.53 -67.5917 1233.83 -20.9337C1210.69 22.7627 1186.76 67.9486 1182.24 118.825C1178.22 164.017 1199.39 207.928 1221.41 249.03C1233.26 271.145 1246.25 293.11 1258.8 314.347C1273.42 339.064 1288.53 364.618 1301.94 390.574C1321.89 429.191 1340.93 470.392 1335.69 512.22C1330.31 555.143 1300.85 590.042 1273.66 619.378C1264.24 629.537 1254.27 639.603 1244.63 649.343C1221.17 673.03 1196.92 697.52 1178.23 725.687C1153.31 763.245 1147.32 804.433 1159.89 851.603C1172.67 899.518 1195.53 945.012 1217.64 989.011C1222.76 999.199 1228.06 1009.74 1233.11 1020.08C1235.16 1024.28 1237.24 1028.51 1239.35 1032.77C1263.88 1082.53 1291.68 1138.93 1289.05 1195.83V1195.84Z" fill="url(#paint26_linear_15_4142)"/>
<path d="M1272.56 1190.06C1271.52 1132.04 1242.36 1075.24 1216.64 1025.13C1214.24 1020.46 1211.86 1015.82 1209.52 1011.23C1204.74 1001.83 1199.77 992.264 1194.96 983.007C1172.1 939.025 1148.46 893.543 1134.47 845.535C1128.89 826.386 1123.03 801.251 1127.32 776.639C1130.56 757.991 1139.59 739.162 1154.91 719.083C1174.89 692.906 1199.9 670.522 1224.1 648.884C1234.12 639.917 1244.49 630.643 1254.32 621.252C1283.25 593.614 1314.66 560.699 1321.08 519.848C1328.11 475.105 1303.21 426.381 1286.74 394.135C1273.03 367.295 1257.55 340.856 1242.59 315.278C1230.11 293.954 1217.2 271.902 1205.38 249.728C1182.1 206.066 1159.73 159.344 1164.13 110.475C1168.58 61.013 1192.48 17.5319 1215.59 -24.5237C1240.49 -69.8317 1266.24 -116.682 1267.6 -171.037L1268.18 -171.026C1266.82 -116.53 1241.04 -69.6165 1216.1 -24.2502C1193.02 17.7472 1169.15 61.176 1164.71 110.516C1160.33 159.228 1182.66 205.857 1205.9 249.443C1217.71 271.605 1230.61 293.651 1243.09 314.97C1258.06 340.548 1273.55 366.998 1287.27 393.856C1297.05 412.993 1307.18 433.451 1314.16 454.618C1322.26 479.178 1324.71 500.537 1321.66 519.918C1315.22 560.95 1283.73 593.946 1254.72 621.653C1244.89 631.05 1234.52 640.325 1224.49 649.297C1200.32 670.924 1175.33 693.278 1155.38 719.414C1140.12 739.418 1131.13 758.159 1127.9 776.714C1123.64 801.204 1129.48 826.258 1135.04 845.348C1149.01 893.304 1172.64 938.758 1195.48 982.716C1200.29 991.973 1205.27 1001.54 1210.04 1010.94C1212.38 1015.54 1214.76 1020.17 1217.16 1024.85C1230.39 1050.62 1244.07 1077.26 1254.64 1104.87C1266.62 1136.17 1272.68 1164.02 1273.14 1190.03L1272.56 1190.04L1272.56 1190.06Z" fill="url(#paint27_linear_15_4142)"/>
<path d="M1255.9 1184.71C1252.66 1126 1224.75 1071.25 1197.74 1018.31C1195.43 1013.78 1193.14 1009.27 1190.87 1004.79C1186.33 995.819 1181.61 986.684 1177.06 977.852C1154.19 933.509 1130.55 887.66 1116.32 839.344C1110.45 819.404 1104.56 796.276 1109.01 774.154C1112.77 755.506 1122.63 736.695 1139.16 716.645C1159.12 692.434 1183.37 671.61 1206.83 651.473C1216.54 643.141 1226.57 634.524 1236.14 625.808C1266.46 598.182 1296.7 567.972 1304.41 529.286C1312.76 487.352 1289.53 439.909 1270.86 401.787L1269.4 398.813C1256.02 371.461 1240.99 344.399 1226.45 318.228C1213.89 295.629 1200.9 272.257 1189.03 248.791C1177.5 225.977 1166.48 203.594 1158.54 180.541C1149.15 153.305 1145.57 129.35 1147.58 107.31C1152.06 58.3133 1175.65 15.1697 1198.46 -26.5601C1222.86 -71.1932 1248.09 -117.345 1249.88 -170.671L1250.46 -170.653C1248.67 -117.188 1223.4 -70.9721 1198.97 -26.2808C1176.19 15.3908 1152.63 58.4878 1148.16 107.363C1143.57 157.616 1169.06 208.021 1189.55 248.529C1201.41 271.983 1214.4 295.35 1226.95 317.949C1241.5 344.126 1256.53 371.193 1269.92 398.557L1271.38 401.531C1280.56 420.289 1290.97 441.55 1298.15 463.59C1306.47 489.168 1308.71 510.696 1304.98 529.402C1297.24 568.258 1266.92 598.548 1236.53 626.238C1226.96 634.96 1216.92 643.577 1207.21 651.909C1183.77 672.029 1159.53 692.836 1139.61 717.006C1123.14 736.98 1113.32 755.71 1109.58 774.259C1105.15 796.253 1111.03 819.299 1116.88 839.169C1131.09 887.427 1154.72 933.253 1177.58 977.572C1182.13 986.41 1186.85 995.545 1191.38 1004.52C1193.65 1009 1195.95 1013.5 1198.26 1018.03C1225.29 1071.03 1253.24 1125.83 1256.49 1184.66L1255.9 1184.69V1184.71Z" fill="url(#paint28_linear_15_4142)"/>
<path d="M1237.52 1181.21C1232.93 1122.06 1205.62 1066.84 1179.2 1013.45C1177.15 1009.29 1175.1 1005.16 1173.08 1001.04C1168.85 992.438 1164.47 983.676 1160.23 975.204C1137.96 930.676 1114.94 884.629 1100.92 836.307C1095.6 817.955 1088.97 795.118 1093.55 774.573C1097.33 757.612 1106.44 740.349 1121.39 721.783C1141.28 697.083 1165.83 676.032 1189.58 655.674C1199.56 647.121 1209.88 638.271 1219.67 629.299C1247.89 603.453 1276.18 575.187 1284.98 538.886C1289.63 519.668 1283.93 498.634 1279.34 481.732C1271.46 452.692 1259.46 425.06 1249.88 404.23C1237.12 376.476 1222.83 348.897 1209.01 322.231C1196.78 298.638 1184.13 274.241 1172.59 249.798L1171.5 247.482C1151.96 206.142 1127.65 154.696 1131.83 106.932C1136.11 58.0806 1159.14 14.7624 1181.42 -27.1303C1204.91 -71.3038 1229.19 -116.978 1231.5 -169.193L1232.08 -169.17C1229.77 -116.821 1205.45 -71.0885 1181.93 -26.8568C1159.68 14.9835 1136.67 58.2493 1132.41 106.979C1128.25 154.591 1152.52 205.956 1172.03 247.232L1173.12 249.547C1184.66 273.979 1197.3 298.37 1209.53 321.964C1223.35 348.635 1237.65 376.215 1250.41 403.986C1260 424.839 1272.02 452.5 1279.91 481.581C1284.51 498.547 1290.24 519.662 1285.54 539.026C1276.71 575.484 1248.35 603.826 1220.06 629.729C1210.26 638.707 1199.94 647.557 1189.96 656.116C1166.23 676.457 1141.7 697.491 1121.84 722.149C1106.95 740.64 1097.88 757.828 1094.12 774.701C1089.57 795.101 1096.18 817.856 1101.48 836.144C1115.49 884.419 1138.5 930.437 1160.76 974.942C1164.99 983.414 1169.37 992.177 1173.61 1000.79C1175.63 1004.9 1177.68 1009.04 1179.73 1013.19C1206.17 1066.63 1233.51 1121.9 1238.11 1181.16L1237.52 1181.21H1237.52Z" fill="url(#paint29_linear_15_4142)"/>
<path d="M1217.42 1179.44C1211.95 1120.11 1185.62 1064.51 1160.17 1010.73C1158.51 1007.23 1156.86 1003.73 1155.21 1000.25C1151.28 991.903 1147.21 983.414 1143.28 975.204C1122.03 930.815 1100.06 884.92 1086.41 837.022L1085.69 834.497C1080.35 815.843 1074.29 794.699 1079.1 775.708C1083.25 759.306 1091.98 743.206 1106.55 725.047C1125.53 701.412 1148.72 681.019 1171.15 661.294C1180.69 652.904 1190.56 644.223 1199.94 635.437C1226.75 610.319 1253.71 582.908 1263.09 548.353C1267.94 530.519 1262.71 510.667 1258.1 493.148L1257.35 490.296C1249.53 460.401 1238.12 432.205 1229.06 410.974C1217.04 382.824 1203.61 354.762 1190.62 327.619C1179.12 303.595 1167.23 278.75 1156.36 253.929C1155.33 251.59 1154.29 249.227 1153.24 246.836C1135.06 205.479 1112.42 154.015 1116.65 107.741C1121.06 59.5351 1143.03 16.2752 1164.27 -25.5593C1186.65 -69.6397 1209.8 -115.221 1212.42 -166.65L1213 -166.621C1210.38 -115.07 1187.2 -69.4303 1164.79 -25.2917C1143.57 16.4962 1121.63 59.7039 1117.23 107.799C1113.02 153.922 1135.62 205.31 1153.78 246.603C1154.83 248.995 1155.87 251.363 1156.9 253.696C1167.76 278.506 1179.65 303.345 1191.15 327.369C1204.14 354.518 1217.58 382.586 1229.6 410.747C1238.67 431.99 1250.08 460.215 1257.91 490.151L1258.66 493.002C1263.29 510.585 1268.54 530.519 1263.65 548.504C1254.23 583.205 1227.21 610.686 1200.34 635.862C1190.95 644.654 1181.08 653.335 1171.54 661.731C1149.13 681.438 1125.96 701.814 1107.01 725.413C1092.49 743.503 1083.8 759.533 1079.67 775.853C1074.9 794.687 1080.93 815.756 1086.26 834.34L1086.98 836.865C1100.62 884.716 1122.58 930.588 1143.82 974.954C1147.75 983.164 1151.81 991.659 1155.75 1000C1157.39 1003.49 1159.05 1006.98 1160.7 1010.49C1186.18 1064.31 1212.53 1119.96 1218.01 1179.39L1217.43 1179.44H1217.42Z" fill="url(#paint30_linear_15_4142)"/>
<path d="M1195.77 1179.26C1189.86 1120.36 1165.13 1064.83 1141.21 1011.12L1138.47 1004.95C1134.42 995.848 1130.22 986.579 1126.17 977.619C1105.88 932.788 1084.9 886.427 1071.9 838.308C1071.57 837.11 1071.25 835.905 1070.91 834.683C1065.96 816.53 1060.35 795.95 1065.61 777.639C1070.14 761.837 1078.78 746.237 1092.8 728.544C1110.37 706.352 1131.52 686.773 1151.96 667.84C1160.03 660.369 1168.38 652.642 1176.37 644.88C1203.79 618.238 1229.21 591.52 1238.94 557.738C1244.12 539.736 1238.65 518.993 1233.82 500.694C1233.31 498.751 1232.81 496.836 1232.32 494.951C1226.46 472.201 1218.26 447.624 1206.49 417.613C1195.47 389.498 1183.19 361.36 1171.32 334.153C1161.06 310.664 1150.46 286.378 1140.72 262.203C1128.98 233.093 1120.89 210.331 1114.49 188.39C1107.94 165.943 1099.78 138.008 1101.77 112.233C1105.52 63.6953 1126.64 19.6149 1147.06 -23.0108C1168.16 -67.0505 1189.98 -112.591 1192.72 -163.153L1193.3 -163.124C1190.55 -112.446 1168.71 -66.8527 1147.58 -22.7607C1127.18 19.8186 1106.09 63.8466 1102.35 112.279C1100.37 137.944 1108.51 165.826 1115.05 188.227C1121.45 210.145 1129.53 232.889 1141.26 261.981C1151 286.151 1161.6 310.431 1171.85 333.915C1183.73 361.127 1196.02 389.271 1207.04 417.397C1218.81 447.426 1227.03 472.027 1232.89 494.8C1233.38 496.679 1233.88 498.599 1234.39 500.537C1239.24 518.912 1244.73 539.736 1239.5 557.889C1229.74 591.805 1204.26 618.581 1176.78 645.288C1168.79 653.055 1160.44 660.782 1152.37 668.259C1131.93 687.175 1110.8 706.742 1093.26 728.893C1079.29 746.523 1070.69 762.064 1066.17 777.785C1060.96 795.938 1066.55 816.431 1071.48 834.515C1071.81 835.736 1072.14 836.947 1072.46 838.14C1085.44 886.211 1106.42 932.549 1126.69 977.363C1130.75 986.323 1134.95 995.592 1138.99 1004.7L1141.74 1010.86C1165.67 1064.61 1190.42 1120.19 1196.34 1179.18L1195.75 1179.24L1195.77 1179.26Z" fill="url(#paint31_linear_15_4142)"/>
<path d="M1172.69 1180.5C1165.61 1111.29 1137.49 1046.23 1110.3 983.315C1090.71 937.972 1070.44 891.081 1058.2 842.992C1057.79 841.363 1057.36 839.71 1056.92 838.041C1052.34 820.382 1047.15 800.372 1052.31 782.754C1056.79 767.457 1064.91 752.242 1077.89 734.874C1094.05 713.235 1113.49 693.802 1132.28 675.008C1139.68 667.607 1147.33 659.956 1154.66 652.293C1179.83 625.971 1203.22 599.631 1212.83 566.914C1217.94 549.516 1212.88 529.152 1208.4 511.185C1207.75 508.537 1207.12 506.041 1206.53 503.551C1201.05 480.463 1193.53 455.636 1182.87 425.427C1172.92 397.248 1161.84 368.953 1151.13 341.595C1141.88 317.966 1132.3 293.535 1123.51 269.272C1113.03 240.383 1105.42 216.726 1099.57 194.808L1098.99 192.614C1092.7 169.079 1085.57 142.401 1087.4 117.539C1090.96 69.2461 1110.69 24.7119 1129.77 -18.3561C1149.43 -62.7391 1169.77 -108.641 1172.47 -158.755L1173.06 -158.726C1170.34 -108.501 1149.99 -62.5529 1130.31 -18.1176C1111.25 24.9039 1091.54 69.3916 1087.99 117.586C1086.16 142.349 1093.28 168.974 1099.56 192.469L1100.15 194.662C1105.99 216.557 1113.59 240.203 1124.06 269.074C1132.86 293.331 1142.42 317.757 1151.67 341.385C1162.39 368.75 1173.47 397.045 1183.42 425.241C1194.1 455.473 1201.62 480.312 1207.11 503.423C1207.69 505.907 1208.32 508.409 1208.97 511.051C1213.46 529.094 1218.54 549.54 1213.39 567.088C1203.74 599.927 1180.31 626.325 1155.08 652.706C1147.75 660.375 1140.09 668.032 1132.69 675.433C1113.91 694.215 1094.5 713.631 1078.36 735.235C1065.42 752.545 1057.32 767.702 1052.86 782.928C1047.74 800.395 1052.92 820.323 1057.48 837.907C1057.92 839.582 1058.34 841.235 1058.76 842.864C1070.98 890.907 1091.24 937.774 1110.83 983.1C1138.05 1046.06 1166.18 1111.15 1173.27 1180.45L1172.69 1180.51V1180.5Z" fill="url(#paint32_linear_15_4142)"/>
<path d="M1148.34 1183.03C1141.64 1115.29 1116.37 1050.89 1091.92 988.616C1074.24 943.54 1055.94 896.929 1044.82 849.386C1044.37 847.461 1043.9 845.506 1043.42 843.522C1039.26 826.264 1034.54 806.703 1039.46 789.544C1043.74 774.614 1051.2 759.661 1062.95 742.467C1077.48 721.212 1094.99 701.738 1111.92 682.904C1118.57 675.509 1125.44 667.857 1132.03 660.218C1153.76 635.018 1175.74 607.753 1184.91 575.856C1189.79 558.878 1185.2 538.863 1181.15 521.204C1180.42 518.045 1179.74 515.06 1179.11 512.104C1174.13 488.801 1167.42 463.858 1158.02 433.608C1149.28 405.499 1139.55 377.198 1130.15 349.834C1122.02 326.194 1113.61 301.745 1105.88 277.517C1096.65 248.617 1089.91 224.877 1084.65 202.802C1084.27 201.208 1083.88 199.602 1083.5 197.985C1077.72 173.867 1071.73 148.929 1073.37 124.754C1076.64 76.5366 1094.83 31.3681 1112.41 -12.3108C1130.5 -57.2465 1149.21 -103.712 1151.73 -153.594L1152.31 -153.565C1149.78 -103.584 1131.06 -57.0719 1112.95 -12.0955C1095.39 31.5427 1077.22 76.6646 1073.96 124.789C1072.32 148.871 1078.29 173.768 1084.07 197.845C1084.45 199.457 1084.84 201.068 1085.22 202.663C1090.48 224.72 1097.22 248.448 1106.44 277.336C1114.17 301.553 1122.58 326.002 1130.7 349.636C1140.11 377.006 1149.83 405.313 1158.58 433.427C1167.99 463.695 1174.7 488.656 1179.68 511.976C1180.31 514.926 1180.99 517.911 1181.71 521.064C1185.78 538.793 1190.38 558.884 1185.47 576.008C1176.27 608.015 1154.24 635.344 1132.47 660.59C1125.88 668.236 1119 675.887 1112.35 683.288C1095.44 702.111 1077.95 721.567 1063.44 742.793C1051.72 759.928 1044.28 774.835 1040.02 789.701C1035.14 806.708 1039.84 826.194 1043.98 843.382C1044.46 845.366 1044.93 847.327 1045.38 849.247C1056.5 896.748 1074.78 943.342 1092.46 988.395C1116.92 1050.71 1142.21 1115.14 1148.92 1182.96L1148.33 1183.02L1148.34 1183.03Z" fill="url(#paint33_linear_15_4142)"/>
<path d="M1122.86 1186.67C1116.83 1120.5 1094.66 1056.8 1073.22 995.196C1057.9 951.18 1042.06 905.668 1032.22 859.58C1031.76 857.422 1031.27 855.228 1030.78 853.011C1026.81 835.05 1022.31 814.691 1027.33 796.637C1031.41 781.992 1038.23 767.126 1048.79 749.839C1061.37 729.242 1076.48 709.907 1091.09 691.201C1097.28 683.27 1103.69 675.066 1109.81 666.886C1127.43 643.344 1146.93 615.625 1155.36 584.567C1159.69 568.583 1156 550.127 1152.75 533.842C1152.07 530.479 1151.44 527.302 1150.87 524.136C1146.6 500.607 1140.91 475.582 1132.96 445.396C1125.32 416.403 1116.76 387.147 1108.48 358.852C1101.44 334.787 1094.15 309.896 1087.47 285.284C1079.62 256.338 1073.85 232.517 1069.31 210.308C1068.82 207.882 1068.31 205.432 1067.8 202.971C1062.95 179.581 1057.94 155.394 1059.75 131.998C1063.41 84.8337 1079.41 39.8747 1094.9 -3.60642C1111.31 -49.7058 1128.28 -97.3703 1130.53 -147.723L1131.12 -147.7C1128.86 -97.2656 1111.88 -49.5545 1095.45 -3.4144C1079.98 40.0318 1063.99 84.9559 1060.34 132.039C1058.53 155.359 1063.54 179.499 1068.38 202.849C1068.89 205.31 1069.39 207.76 1069.89 210.192C1074.42 232.389 1080.18 256.198 1088.04 285.133C1094.72 309.739 1102 334.625 1109.04 358.69C1117.32 386.985 1125.87 416.245 1133.51 445.25C1141.47 475.454 1147.17 500.484 1151.44 524.032C1152.02 527.191 1152.65 530.368 1153.32 533.725C1156.59 550.069 1160.29 568.595 1155.91 584.712C1147.46 615.87 1127.92 643.647 1110.28 667.229C1104.15 675.416 1097.74 683.619 1091.54 691.556C1076.94 710.245 1061.85 729.573 1049.28 750.136C1038.75 767.37 1031.95 782.195 1027.89 796.788C1022.91 814.703 1027.39 834.986 1031.35 852.877C1031.84 855.094 1032.32 857.288 1032.79 859.447C1042.62 905.499 1058.46 950.994 1073.77 994.993C1095.22 1056.63 1117.4 1120.36 1123.44 1186.6L1122.86 1186.66V1186.67Z" fill="url(#paint34_linear_15_4142)"/>
<path d="M1096.39 1191.31C1091.27 1126.75 1072.43 1063.84 1054.21 1003C1041.14 959.355 1027.62 914.221 1019.26 868.803C1018.83 866.504 1018.39 864.183 1017.94 861.832C1014.39 843.312 1010.73 824.158 1015.17 806.255C1018.77 791.749 1024.65 776.895 1033.65 759.515C1044.28 738.976 1057.12 719.281 1069.53 700.231C1074.79 692.167 1080.23 683.823 1085.42 675.538C1100.36 651.688 1116.93 623.672 1124.38 592.91C1128.21 577.107 1125.23 559.844 1122.35 543.151C1121.71 539.445 1121.11 535.942 1120.58 532.439C1117 508.921 1112.33 484.013 1105.88 454.048C1099.71 425.398 1092.81 396.405 1086.15 368.366C1080.47 344.504 1074.6 319.834 1069.21 295.478C1062.85 266.782 1058.15 243.101 1054.39 220.944C1053.87 217.843 1053.32 214.718 1052.77 211.576C1048.8 188.803 1044.7 165.256 1046.2 142.436C1049.32 95.0333 1063.58 49.2016 1077.37 4.87685C1091.98 -42.1128 1107.1 -90.7082 1108.95 -141.23L1109.53 -141.206C1107.67 -90.6035 1092.55 -41.9731 1077.92 5.0514C1064.15 49.3413 1049.9 95.1381 1046.79 142.471C1045.29 165.221 1049.39 188.733 1053.35 211.472C1053.89 214.614 1054.44 217.738 1054.97 220.839C1058.72 242.984 1063.43 266.659 1069.78 295.344C1075.17 319.694 1081.04 344.365 1086.71 368.22C1093.38 396.259 1100.27 425.252 1106.44 453.914C1112.89 483.89 1117.57 508.811 1121.15 532.34C1121.68 535.837 1122.28 539.334 1122.92 543.041C1125.81 559.792 1128.8 577.119 1124.94 593.038C1117.47 623.882 1100.87 651.95 1085.91 675.84C1080.71 684.132 1075.28 692.475 1070.02 700.545C1057.61 719.583 1044.78 739.267 1034.16 759.777C1025.17 777.122 1019.32 791.935 1015.73 806.394C1011.32 824.175 1014.98 843.265 1018.51 861.722C1018.96 864.072 1019.41 866.4 1019.83 868.698C1028.19 914.087 1041.7 959.204 1054.77 1002.83C1073 1063.7 1091.84 1126.64 1096.97 1191.26L1096.39 1191.31Z" fill="url(#paint35_linear_15_4142)"/>
<path d="M1069.08 1196.76C1065.04 1133.84 1049.72 1071.78 1034.91 1011.77C1024.24 968.548 1013.22 923.857 1006.47 879.159C1006.11 876.803 1005.74 874.423 1005.36 872.026C1002.49 853.756 999.515 834.87 1003.26 816.972C1006.28 802.507 1011.11 787.566 1018.43 769.959C1027.01 749.321 1037.42 729.131 1047.49 709.605C1051.75 701.348 1056.15 692.813 1060.36 684.358C1075.36 654.178 1086.52 628.426 1092.17 600.835C1095.37 585.195 1093.01 568.397 1090.73 552.152C1090.18 548.289 1089.62 544.292 1089.16 540.451C1086.35 517.021 1082.78 492.327 1077.95 462.735C1073.34 434.533 1068.21 405.929 1063.26 378.269C1059.04 354.716 1054.67 330.365 1050.65 306.364C1045.89 278.04 1042.33 254.592 1039.43 232.581C1038.92 228.717 1038.38 224.825 1037.85 220.921C1034.82 198.945 1031.69 176.224 1032.84 154.131C1035.35 106.333 1047.77 59.5294 1059.78 14.262C1072.52 -33.7342 1085.69 -83.3654 1087.05 -134.242L1087.63 -134.224C1086.27 -83.2781 1073.09 -33.6179 1060.35 14.4132C1048.34 59.6515 1035.93 106.426 1033.43 154.166C1032.27 176.2 1035.4 198.898 1038.43 220.845C1038.97 224.749 1039.51 228.642 1040.01 232.505C1042.92 254.511 1046.48 277.953 1051.23 306.271C1055.26 330.266 1059.62 354.617 1063.84 378.17C1068.8 405.83 1073.92 434.434 1078.53 462.647C1083.37 492.246 1086.93 516.945 1089.75 540.387C1090.21 544.222 1090.77 548.219 1091.31 552.077C1093.6 568.368 1095.97 585.218 1092.75 600.957C1087.09 628.606 1075.92 654.399 1060.89 684.62C1056.69 693.074 1052.29 701.616 1048.03 709.872C1037.96 729.387 1027.56 749.566 1018.98 770.18C1011.68 787.752 1006.86 802.659 1003.84 817.088C1000.13 834.875 1003.09 853.715 1005.96 871.927C1006.33 874.324 1006.71 876.704 1007.06 879.066C1013.81 923.734 1024.83 968.414 1035.49 1011.62C1050.3 1071.67 1065.63 1133.75 1069.67 1196.72L1069.09 1196.75L1069.08 1196.76Z" fill="url(#paint36_linear_15_4142)"/>
<path d="M1041.06 1202.88C1038.22 1141.62 1026.6 1080.5 1015.36 1021.39C1007.14 978.172 998.643 933.474 993.633 889.033C993.36 886.584 993.063 884.122 992.772 881.65C990.741 864.532 988.647 846.832 991.335 829.866C993.633 815.372 997.392 799.726 1002.83 782.027C1009.37 760.743 1017.37 739.569 1025.09 719.094C1028.22 710.809 1031.45 702.244 1034.55 693.79C1045.96 662.58 1054.47 636.118 1058.89 608.405C1061.49 592.113 1059.64 574.821 1057.84 558.093C1057.34 553.473 1056.83 548.702 1056.43 544.088C1054.42 520.832 1051.99 496.295 1048.8 466.883C1045.95 440.724 1042.86 414.145 1039.87 388.445C1037.13 364.828 1034.29 340.408 1031.64 316.372C1030.46 305.585 1029.1 294.483 1027.78 283.742C1022.98 244.654 1018.01 204.234 1019.65 165.186C1021.65 117.44 1032.05 70.1538 1042.1 24.4209C1052.89 -24.6517 1064.04 -75.3883 1064.84 -126.782L1065.43 -126.771C1064.62 -75.3185 1053.46 -24.5469 1042.66 24.549C1032.62 70.2527 1022.23 117.516 1020.22 165.209C1018.58 204.205 1023.55 244.602 1028.35 283.673C1029.67 294.413 1031.03 305.521 1032.22 316.308C1034.86 340.344 1037.7 364.758 1040.44 388.375C1043.43 414.081 1046.52 440.66 1049.37 466.819C1052.57 496.237 1054.99 520.779 1057.01 544.041C1057.4 548.644 1057.92 553.421 1058.42 558.035C1060.21 574.798 1062.08 592.131 1059.46 608.498C1055.03 636.258 1046.52 662.755 1035.09 693.994C1031.99 702.448 1028.76 711.019 1025.63 719.304C1017.91 739.773 1009.92 760.935 1003.38 782.201C997.956 799.878 994.203 815.5 991.905 829.965C989.228 846.85 991.317 864.514 993.342 881.591C993.633 884.07 993.93 886.531 994.203 888.981C999.213 933.399 1007.71 978.078 1015.92 1021.29C1027.16 1080.42 1038.79 1141.56 1041.64 1202.87L1041.06 1202.9L1041.06 1202.88Z" fill="url(#paint37_linear_15_4142)"/>
<path d="M1012.5 1209.54C1010.92 1149.83 1003.12 1089.7 995.576 1031.55C990.043 988.889 984.318 944.774 981.094 901.141C978.325 863.706 980.379 829.54 987.553 793.617C991.893 771.897 997.171 750.008 1002.28 728.84C1004.34 720.281 1006.48 711.432 1008.52 702.71C1016.07 670.528 1021.72 643.362 1024.81 615.404C1026.55 599.636 1025.46 583.258 1024.41 567.42C1024.06 562.172 1023.7 556.737 1023.45 551.448C1022.38 528.692 1021.23 504.563 1019.82 475.518C1018.58 449.853 1017.33 424.188 1016.07 398.528C1014.93 375.103 1013.79 351.684 1012.65 328.259C1011.94 313.637 1010.98 298.684 1010.05 284.225C1007.82 249.582 1005.52 213.758 1006.43 178.801C1007.7 130.386 1016.17 82.0001 1024.35 35.2083C1033.14 -15.0105 1042.23 -66.94 1042.43 -119.003H1043.01C1042.81 -66.8876 1033.72 -14.9349 1024.93 35.3072C1016.74 82.0757 1008.28 130.444 1007.02 178.813C1006.1 213.747 1008.41 249.553 1010.63 284.185C1011.56 298.649 1012.52 313.603 1013.23 328.224C1014.36 351.649 1015.51 375.068 1016.65 398.493C1017.9 424.158 1019.16 449.824 1020.4 475.483C1021.8 504.528 1022.95 528.657 1024.02 551.413C1024.27 556.696 1024.63 562.125 1024.98 567.373C1026.04 583.234 1027.13 599.636 1025.38 615.457C1022.3 643.443 1016.64 670.627 1009.09 702.832C1007.04 711.554 1004.91 720.404 1002.84 728.963C997.741 750.124 992.458 772.001 988.123 793.716C980.961 829.586 978.907 863.706 981.67 901.083C984.894 944.698 990.619 988.802 996.152 1031.46C1003.7 1089.62 1011.5 1149.77 1013.08 1209.5L1012.5 1209.52V1209.54Z" fill="url(#paint38_linear_15_4142)"/>
<path d="M983.515 1216.56C983.236 1158.31 979.349 1099.25 975.596 1042.14C972.832 1000.11 969.975 956.655 968.573 913.843C966.595 853.454 973.245 792.785 979.669 734.106C980.495 726.589 981.316 719.06 982.124 711.53C985.697 678.22 988.42 650.222 990.078 621.869C991.678 594.493 991.195 566.378 990.73 539.189C990.415 521.047 990.096 502.294 990.398 483.954C990.811 458.988 991.41 433.602 991.987 409.06C992.522 386.554 993.069 363.286 993.47 340.396C993.936 313.771 993.691 286.716 993.447 260.544C993.243 238.428 993.034 215.562 993.255 193.068C993.744 143.867 1000.25 94.3526 1006.55 46.4728C1013.31 -4.933 1020.3 -58.0843 1019.85 -110.956H1020.43C1020.88 -58.0494 1013.89 -4.87481 1007.13 46.5484C1000.83 94.4166 994.32 143.914 993.831 193.074C993.61 215.562 993.819 238.429 994.023 260.539C994.262 286.71 994.512 313.771 994.046 340.408C993.645 363.298 993.092 386.571 992.563 409.071C991.981 433.619 991.381 458.999 990.974 483.966C990.672 502.294 990.997 521.047 991.306 539.177C991.771 566.378 992.254 594.505 990.654 621.904C988.996 650.268 986.273 678.272 982.7 711.595C981.892 719.129 981.065 726.653 980.245 734.17C973.821 792.826 967.177 853.477 969.149 913.82C970.551 956.626 973.408 1000.08 976.172 1042.1C979.931 1099.22 983.812 1158.29 984.091 1216.55H983.509L983.515 1216.56Z" fill="url(#paint39_linear_15_4142)"/>
<path d="M954.842 1223.81L954.26 1223.8C957.262 1055.29 957.664 885.833 955.453 720.113C954.475 646.713 954.068 568.642 960.637 492.065C962.528 470.008 964.657 447.636 966.711 426.009C973.49 354.716 980.495 281.002 980.053 207.748C979.75 157.68 984.294 107.025 988.687 58.0398C993.406 5.42962 998.288 -48.9727 997.171 -102.77L997.753 -102.781C998.87 -48.9552 993.988 5.46451 989.264 58.0922C984.871 107.066 980.326 157.704 980.629 207.742C981.071 281.019 974.06 354.756 967.281 426.061C965.228 447.688 963.098 470.054 961.207 492.112C954.644 568.665 955.051 646.719 956.029 720.101C958.234 885.827 957.832 1055.29 954.836 1223.8L954.842 1223.81Z" fill="url(#paint40_linear_15_4142)"/>
<path d="M925.464 1231.14L924.883 1231.12C927.158 1175.3 931.248 1118.66 935.199 1063.89C938.119 1023.38 941.139 981.5 943.408 940.259C947.057 873.876 939.056 810.787 930.585 743.992C929.927 738.796 929.264 733.588 928.612 728.375C924.801 698.043 920.955 665.693 919.477 632.912C917.435 587.54 924.19 540.393 930.684 499.769C934.425 476.373 938.794 452.628 943.024 429.663C955.435 362.274 968.271 292.592 966.775 222.655C965.687 171.668 968.271 119.913 970.767 69.857C973.455 16.0192 976.23 -39.6457 974.45 -94.4902L975.032 -94.5077C976.818 -39.6457 974.037 16.0366 971.349 69.8861C968.852 119.93 966.269 171.674 967.357 222.643C968.852 292.645 956.011 362.355 943.595 429.773C939.365 452.733 934.995 476.472 931.254 499.868C924.766 540.469 918.011 587.575 920.053 632.895C921.531 665.646 925.371 697.991 929.182 728.311C929.84 733.524 930.497 738.732 931.155 743.928C939.632 810.746 947.638 873.859 943.984 940.299C941.715 981.546 938.695 1023.43 935.775 1063.94C931.824 1118.71 927.739 1175.34 925.459 1231.15L925.464 1231.14Z" fill="url(#paint41_linear_15_4142)"/>
<path d="M896.105 1238.38L895.523 1238.35C899.014 1183.42 907.067 1128.15 914.857 1074.69C920.629 1035.08 926.599 994.126 930.666 953.612C930.94 950.906 931.225 948.195 931.51 945.472C933.238 929.14 935.024 912.249 933.5 895.771C932.022 879.828 929.45 862.647 925.406 841.689C920.554 816.524 914.567 791.167 908.777 766.643C906.427 756.67 903.994 746.36 901.667 736.206C894.441 704.618 887.121 670.97 883.985 637.404C879.912 593.766 891.403 545.636 900.643 506.966C906.118 484.053 912.629 460.704 918.93 438.123C937.066 373.113 955.825 305.893 953.364 237.567C951.496 185.591 952.148 132.725 952.782 81.6045C953.463 26.5912 954.167 -30.2955 951.723 -86.2164L952.305 -86.2397C954.743 -30.3013 954.044 26.5912 953.364 81.6161C952.73 132.731 952.078 185.586 953.946 237.55C956.407 305.963 937.642 373.23 919.489 438.286C913.188 460.861 906.677 484.21 901.207 507.112C891.979 545.74 880.494 593.812 884.561 637.357C887.691 670.883 895.005 704.514 902.231 736.084C904.553 746.237 906.985 756.548 909.342 766.515C915.131 791.045 921.112 816.413 925.971 841.584C930.014 862.559 932.592 879.759 934.07 895.719C935.6 912.255 933.814 929.175 932.08 945.536C931.789 948.253 931.504 950.965 931.23 953.67C927.163 994.195 921.194 1035.16 915.422 1074.77C907.631 1128.22 899.578 1183.49 896.093 1238.38H896.105Z" fill="url(#paint42_linear_15_4142)"/>
<path d="M866.902 1245.4L866.32 1245.35C870.952 1191.07 882.908 1137.25 894.475 1085.21C903.058 1046.59 911.925 1006.65 917.749 966.808C918.063 964.644 918.395 962.468 918.721 960.286C921.258 943.47 923.882 926.079 921.572 909.177C919.338 892.833 915.521 875.092 909.563 853.343C902.354 827.02 893.614 800.675 885.16 775.202C881.716 764.816 878.149 754.075 874.739 743.491C863.696 709.209 853.392 675.578 848.644 641.122C842.907 599.479 858.297 551.832 870.666 513.547C877.742 491.641 886.277 469.17 894.534 447.432C918.418 384.558 943.118 319.537 939.789 252.265C937.165 199.27 935.938 145.345 934.745 93.1947C933.459 37.0469 932.132 -21.0151 929.072 -78.059L929.654 -78.0881C932.72 -21.0384 934.047 37.0295 935.327 93.1831C936.519 145.328 937.753 199.253 940.371 252.236C943.705 319.63 918.983 384.71 895.081 447.642C886.824 469.368 878.294 491.833 871.219 513.727C858.873 551.954 843.506 599.537 849.22 641.046C853.962 675.456 864.255 709.058 875.292 743.317C878.702 753.906 882.263 764.641 885.713 775.027C894.167 800.506 902.912 826.858 910.127 853.198C916.091 874.97 919.908 892.734 922.148 909.107C924.475 926.091 921.84 943.523 919.297 960.379C918.965 962.561 918.639 964.731 918.325 966.895C912.501 1006.76 903.628 1046.71 895.046 1085.34C883.484 1137.36 871.533 1191.17 866.902 1245.4Z" fill="url(#paint43_linear_15_4142)"/>
<path d="M838.008 1252.03L837.432 1251.96C843.122 1198.09 858.872 1145.82 874.099 1095.27C885.41 1057.73 897.105 1018.91 904.617 979.673C904.937 978.009 905.263 976.339 905.589 974.663C908.952 957.411 912.425 939.578 909.376 922.239C906.386 905.232 901.516 887.439 893.591 864.549C884.049 836.999 872.627 809.635 861.578 783.173C857.063 772.362 852.397 761.179 847.922 750.142C832.887 713.049 819.93 679.465 813.652 644.025C806.396 603.063 826.324 554.718 840.876 519.418C849.4 498.733 859.361 478.305 869.904 456.672C899.503 395.951 930.107 333.164 926.005 266.543C922.683 212.548 919.623 157.628 916.673 104.523C913.49 47.2874 910.197 -11.8918 906.555 -70.0761L907.136 -70.111C910.773 -11.9268 914.066 47.2583 917.255 104.494C920.21 157.605 923.265 212.519 926.587 266.514C930.701 333.286 900.061 396.143 870.428 456.934C859.891 478.555 849.935 498.978 841.417 519.645C826.894 554.881 807.001 603.139 814.228 643.926C820.494 679.308 833.44 712.863 848.463 749.926C852.938 760.958 857.604 772.141 862.119 782.952C873.168 809.425 884.602 836.795 894.144 864.363C902.08 887.282 906.956 905.104 909.953 922.14C913.025 939.584 909.534 957.475 906.165 974.779C905.839 976.455 905.513 978.125 905.193 979.789C897.676 1019.06 885.975 1057.89 874.664 1095.45C859.443 1145.97 843.704 1198.22 838.019 1252.03L838.008 1252.03Z" fill="url(#paint44_linear_15_4142)"/>
<path d="M809.561 1258.12L808.985 1258.05C815.624 1204.35 835.011 1153.7 853.758 1104.73C867.687 1068.33 882.094 1030.7 891.211 992.008C891.485 990.856 891.758 989.692 892.032 988.534C896.227 970.864 900.567 952.594 896.832 934.749C892.142 912.365 884.014 891.134 877.451 875.133C865.634 846.314 851.623 817.903 838.077 790.428C832.527 779.176 826.79 767.539 821.286 756.041C805.436 722.946 787.475 685.435 779.178 646.056C770.543 605.106 796.109 554.631 811.377 524.474C821.187 505.099 832.8 485.816 845.1 465.4C880.302 406.942 916.708 346.5 911.931 280.193C907.968 225.203 903.162 169.346 898.519 115.334C893.515 57.1147 888.337 -3.08856 884.194 -62.3783L884.776 -62.4191C888.913 -3.13511 894.091 57.0682 899.095 115.287C903.738 169.3 908.544 225.157 912.507 280.152C917.295 346.64 880.849 407.169 845.595 465.702C833.306 486.107 821.699 505.384 811.895 524.742C796.656 554.829 771.154 605.193 779.742 645.939C788.027 685.249 805.977 722.731 821.809 755.797C827.313 767.294 833.05 778.931 838.601 790.178C852.152 817.664 866.169 846.087 877.992 874.924C884.561 890.948 892.707 912.214 897.402 934.644C901.167 952.617 896.809 970.957 892.602 988.685C892.328 989.849 892.049 991.007 891.782 992.159C882.658 1030.89 868.246 1068.54 854.305 1104.95C835.57 1153.89 816.2 1204.5 809.567 1258.13L809.561 1258.12Z" fill="url(#paint45_linear_15_4142)"/>
<path d="M781.703 1263.52L781.127 1263.44C788.574 1209.69 811.406 1160.74 833.481 1113.4C849.889 1078.22 866.861 1041.84 877.474 1003.63L877.986 1001.78C883.025 983.687 888.232 964.975 883.868 946.531C878.428 923.542 868.869 901.537 861.142 884.943C847.131 854.838 830.653 825.356 814.716 796.84C808.177 785.139 801.416 773.043 794.922 761.074C775.32 724.954 755.054 687.599 745.413 647.138C741.265 629.735 743.761 609.516 753.035 585.329C760.745 565.232 771.654 546.188 782.302 528.599C794.265 508.84 807.763 489.639 820.814 471.067C832.771 454.054 845.141 436.464 856.289 418.497C877.451 384.384 901.266 339.291 897.524 292.97C893.009 237.044 886.551 180.366 880.313 125.551C873.581 66.4242 866.617 5.28996 862.067 -55.0704L862.649 -55.1169C867.199 5.23177 874.158 66.366 880.889 125.487C887.133 180.302 893.585 236.991 898.106 292.924C901.859 339.413 877.998 384.616 856.789 418.806C845.63 436.79 833.26 454.385 821.297 471.404C808.246 489.965 794.759 509.16 782.802 528.902C767.675 553.892 736.197 605.897 745.989 647.004C755.613 687.39 775.861 724.709 795.446 760.801C801.939 772.769 808.7 784.86 815.24 796.561C831.183 825.083 847.666 854.576 861.683 884.698C869.415 901.322 878.993 923.362 884.444 946.397C888.843 964.993 883.612 983.775 878.556 1001.94L878.044 1003.79C867.42 1042.03 850.442 1078.44 834.022 1113.65C811.964 1160.95 789.15 1209.85 781.714 1263.52L781.703 1263.52Z" fill="url(#paint46_linear_15_4142)"/>
<path d="M754.589 1268.09L754.013 1268C762.106 1214 788.155 1166.78 813.355 1121.1C831.741 1087.77 850.756 1053.29 862.777 1016.31L863.073 1015.39C868.845 997.646 875.385 977.531 870.864 958.977C865.209 935.778 854.817 913.727 845.391 895.218C829.158 863.333 810.073 832.333 791.623 802.356C784.286 790.44 776.699 778.111 769.403 765.91L768.518 764.432C746.373 727.409 723.472 689.124 712.591 647.324C708.21 630.491 710.974 610.389 720.801 587.569C729.383 567.641 742.073 548.498 752.168 534.045C766.069 514.152 781.784 495.161 796.988 476.792C809.87 461.228 823.194 445.128 835.343 428.563C859.547 395.555 886.83 351.83 882.897 306.277C877.945 248.913 869.864 190.938 862.055 134.872C853.712 75.0064 845.089 13.1041 840.248 -48.2047L840.83 -48.2512C845.671 13.0401 854.293 74.9365 862.637 134.791C870.451 190.863 878.527 248.843 883.484 306.225C887.435 351.969 860.089 395.817 835.82 428.906C823.659 445.489 810.329 461.588 797.442 477.164C782.25 495.521 766.54 514.501 752.651 534.377C729.296 567.804 703.701 610.831 713.162 647.173C724.019 688.897 746.897 727.141 769.024 764.129L769.909 765.607C777.205 777.808 784.792 790.132 792.129 802.048C810.591 832.036 829.681 863.048 845.921 894.951C855.358 913.488 865.773 935.581 871.44 958.837C876.002 977.555 869.433 997.751 863.638 1015.57L863.341 1016.49C851.303 1053.52 832.277 1088.02 813.873 1121.37C788.702 1167 762.677 1214.19 754.601 1268.09L754.589 1268.09Z" fill="url(#paint47_linear_15_4142)"/>
<path d="M728.348 1271.67L727.772 1271.58C736.278 1217.23 765.283 1171.75 793.34 1127.76C813.803 1095.67 834.97 1062.49 848.202 1025.94C854.293 1009.11 861.881 988.173 856.923 968.81C850.831 945.012 839.294 922.227 828.815 903.079C810.649 869.891 789.371 837.808 768.792 806.784C760.593 794.426 752.116 781.643 743.959 768.976L741.87 765.735C717.677 728.189 692.658 689.362 680.881 646.411C676.238 629.473 679.322 609.609 690.057 587.383C699.739 567.327 714.168 548.428 724.182 535.959C739.56 516.811 756.823 498.803 773.516 481.389C787.655 466.639 802.271 451.389 815.677 435.522C842.081 404.259 871.853 362.506 867.717 316.756C862.457 258.624 852.949 200.05 843.75 143.408C833.923 82.8962 823.764 20.3189 818.766 -41.88L819.348 -41.9266C824.346 20.2433 834.505 82.8089 844.326 143.315C853.525 199.969 863.038 258.549 868.298 316.704C872.459 362.669 842.598 404.545 816.125 435.894C802.713 451.779 788.086 467.034 773.941 481.79C757.254 499.193 740.002 517.195 724.641 536.326C701.176 565.552 671.164 608.742 681.451 646.259C693.204 689.13 718.2 727.915 742.37 765.421L744.459 768.662C752.616 781.328 761.094 794.106 769.286 806.464C789.872 837.499 811.156 869.594 829.332 902.8C839.829 921.977 851.384 944.803 857.494 968.664C862.497 988.203 854.875 1009.24 848.754 1026.14C835.494 1062.75 814.315 1095.96 793.834 1128.08C765.813 1172.01 736.843 1217.44 728.354 1271.67H728.348Z" fill="url(#paint48_linear_15_4142)"/>
<path d="M703.131 1274.11L702.555 1274.02C711.207 1219.23 742.871 1175.49 773.493 1133.19C795.824 1102.34 818.917 1070.44 833.173 1034.31C840.515 1015.69 847.271 995.97 842.36 977.363C835.866 952.762 823.386 929.355 812.034 909.7C792.124 875.232 768.838 842.084 746.315 810.025C737.326 797.23 728.028 783.999 719.085 770.873L715.803 766.067C689.783 727.938 662.873 688.513 650.486 644.403C645.668 627.256 649.043 607.613 660.511 586.027C671.06 566.175 686.397 548.341 697.016 536.658C713.761 518.231 732.438 501.171 750.499 484.682C765.778 470.723 781.581 456.294 796.138 441.102C824.404 411.602 856.301 371.828 852.059 325.746C846.642 266.968 835.843 207.946 825.399 150.867C814.234 89.855 802.69 26.7599 797.692 -36.1896L798.274 -36.2361C803.272 26.6843 814.81 89.7618 825.975 150.762C836.425 207.853 847.224 266.886 852.641 325.693C854.544 346.314 849.517 367.01 837.275 388.963C828.128 405.371 815.188 422.064 796.563 441.509C781.994 456.718 766.185 471.154 750.894 485.118C732.845 501.602 714.18 518.65 697.452 537.059C686.857 548.719 671.548 566.512 661.034 586.312C649.636 607.765 646.279 627.262 651.056 644.258C663.42 688.28 690.301 727.665 716.292 765.752L719.574 770.558C728.516 783.679 737.809 796.91 746.798 809.705C769.327 841.77 792.618 874.929 812.546 909.421C823.915 929.105 836.419 952.559 842.93 977.223C847.887 995.999 841.097 1015.83 833.719 1034.53C819.441 1070.73 796.324 1102.66 773.97 1133.54C743.388 1175.78 711.765 1219.47 703.137 1274.11L703.131 1274.11Z" fill="url(#paint49_linear_15_4142)"/>
<path d="M679.083 1275.25L678.507 1275.16C686.973 1219.92 720.964 1177.91 753.832 1137.27C778.072 1107.3 803.144 1076.31 818.126 1040.04C825.184 1022.96 832.253 1003.04 826.679 982.885C819.633 957.411 806.681 934.138 793.735 912.778C772.498 877.745 747.839 844.127 723.99 811.613C713.685 797.562 703.026 783.033 692.797 768.598L689.213 763.547C662.128 725.378 634.124 685.912 621.661 641.354C616.756 623.806 620.387 604.274 632.466 583.292C640.961 568.543 653.645 552.955 671.246 535.634C689.137 518.033 708.943 501.986 728.098 486.474C743.772 473.778 759.983 460.646 775.052 446.734C805.57 418.561 840.085 380.357 836.018 334.613C830.7 274.735 818.638 214.835 806.978 156.912C794.637 95.6269 781.877 32.2525 777.071 -31.2381L777.653 -31.2847C782.453 32.1711 795.213 95.5279 807.548 156.796C819.214 214.736 831.276 274.648 836.6 334.561C838.432 355.187 832.742 375.65 819.197 397.126C806.163 417.805 787.987 435.58 775.442 447.159C760.361 461.082 744.145 474.22 728.458 486.922C709.316 502.428 689.516 518.463 671.647 536.047C654.081 553.327 641.426 568.874 632.96 583.578C620.963 604.413 617.35 623.8 622.214 641.192C634.648 685.65 662.623 725.076 689.678 763.204L693.263 768.254C703.491 782.69 714.145 797.218 724.449 811.264C748.305 843.783 772.969 877.414 794.224 912.47C807.193 933.864 820.168 957.185 827.232 982.727C832.853 1003.06 825.754 1023.09 818.656 1040.27C803.638 1076.61 778.543 1107.64 754.275 1137.64C721.453 1178.21 687.514 1220.17 679.071 1275.25L679.083 1275.25Z" fill="url(#paint50_linear_15_4142)"/>
<path d="M656.345 1274.95L655.769 1274.86C659.312 1249.87 668.796 1225.82 684.756 1201.35C698.988 1179.54 716.984 1159.34 734.387 1139.81C760.32 1110.72 787.137 1080.62 802.55 1044.16C809.625 1027.41 816.072 1007.49 810.219 986.655C803.208 961.688 790.297 938.88 777.391 917.951C754.798 881.312 728.336 846.378 702.747 812.597C692.82 799.493 682.557 785.942 672.671 772.49C670.705 769.814 668.721 767.126 666.725 764.42C638.82 726.6 607.196 683.736 594.466 637.072C589.555 619.07 593.418 599.596 605.951 579.196C614.929 564.586 628.213 549.546 646.57 533.213C665.375 516.485 686.054 501.45 706.046 486.916C723.984 473.877 742.533 460.39 759.663 445.727C789.488 420.202 823.194 384.878 819.069 337.935C813.85 278.482 800.979 219.204 788.528 161.875C775.18 100.404 761.379 36.8375 756.974 -27.107L757.556 -27.1478C761.961 36.756 775.756 100.299 789.098 161.753C801.549 219.1 814.42 278.395 819.645 337.883C821.472 358.655 816.165 378.53 803.44 398.645C793.671 414.087 779.876 429.186 760.041 446.164C742.894 460.844 724.333 474.331 706.389 487.381C686.403 501.91 665.742 516.933 646.954 533.644C603.583 572.232 586.593 606.008 595.024 636.909C607.726 683.474 637.953 724.447 667.184 764.065C669.18 766.771 671.164 769.459 673.131 772.135C683.016 785.582 693.28 799.133 703.206 812.236C728.802 846.029 755.275 880.969 777.88 917.631C790.809 938.595 803.743 961.444 810.777 986.486C816.677 1007.5 810.201 1027.54 803.085 1044.37C787.638 1080.92 760.786 1111.05 734.818 1140.19C700.105 1179.13 664.217 1219.4 656.339 1274.93L656.345 1274.95Z" fill="url(#paint51_linear_15_4142)"/>
<path d="M635.055 1273.05L634.479 1272.98C637.569 1247.79 647.076 1223.94 663.542 1200.07C678.228 1178.78 697.01 1159.37 715.181 1140.61C741.783 1113.13 769.286 1084.72 785.037 1049.46C791.937 1034.01 799.175 1013.5 793.817 991.944C787.463 966.389 774.267 943.005 760.954 921.541C737.081 883.052 708.844 846.588 681.533 811.322C670.379 796.916 658.841 782.021 647.774 767.207C645.505 764.17 643.207 761.109 640.885 758.02C612.014 719.583 579.291 676.026 568.434 628.414C561.638 598.612 579.117 566.047 620.393 531.613C640.763 514.618 663.245 499.775 684.983 485.415C702.991 473.522 721.616 461.222 738.937 447.77C770.31 423.402 805.779 389.038 801.538 339.169C796.54 280.414 783.082 222.003 770.066 165.512C755.892 103.994 741.236 40.3867 737.459 -23.8604L738.041 -23.8953C741.812 40.2994 756.462 103.889 770.636 165.378C783.658 221.887 797.122 280.321 802.125 339.116C806.39 389.265 770.793 423.774 739.298 448.229C721.959 461.699 703.323 474.005 685.309 485.904C663.583 500.252 641.118 515.089 620.771 532.061C579.675 566.343 562.261 598.717 569.004 628.286C579.838 675.776 612.52 719.286 641.356 757.676C643.678 760.766 645.976 763.832 648.245 766.864C659.312 781.677 670.844 796.567 681.998 810.973C709.316 846.25 737.564 882.726 761.455 921.238C774.79 942.743 788.016 966.174 794.387 991.81C799.786 1013.54 792.513 1034.16 785.572 1049.7C769.781 1085.06 742.236 1113.51 715.6 1141.02C679.38 1178.44 641.927 1217.13 635.061 1273.06L635.055 1273.05Z" fill="url(#paint52_linear_15_4142)"/>
<path d="M615.366 1269.41L614.784 1269.35C617.198 1244.01 626.502 1220.37 643.224 1197.07C658.143 1176.3 677.506 1157.65 696.236 1139.6C723.67 1113.18 752.04 1085.85 767.68 1050.93C777.217 1029.62 779.969 1010.84 776.1 993.497C770.304 967.535 756.893 943.633 743.26 921.669C718.695 882.086 689.411 844.743 661.093 808.628C649.508 793.855 637.534 778.582 626.06 763.384C623.57 760.085 621.033 756.751 618.473 753.377C588.752 714.277 555.069 669.964 545.312 621.467C539.069 590.42 556.716 558.442 597.765 526.423C618.868 509.963 642.09 495.841 664.549 482.191C683.139 470.892 702.357 459.203 720.272 446.251C751.715 423.518 787.318 390.912 783.512 340.082C779.125 281.45 765.085 223.335 751.511 167.141C736.72 105.902 721.424 42.5744 718.567 -21.6203L719.149 -21.6435C722.006 42.493 737.291 105.792 752.075 167.007C765.655 223.225 779.695 281.363 784.088 340.041C785.758 362.332 780.318 382.417 767.453 401.438C757.155 416.676 742.265 431.065 720.609 446.722C702.677 459.686 683.441 471.381 664.845 482.686C642.404 496.33 619.194 510.44 598.12 526.883C557.257 558.756 539.679 590.542 545.882 621.357C555.61 669.725 589.252 713.98 618.932 753.028C621.498 756.396 624.029 759.736 626.52 763.035C637.993 778.233 649.968 793.5 661.546 808.273C689.876 844.4 719.166 881.754 743.749 921.366C757.411 943.377 770.851 967.332 776.664 993.375C780.562 1010.84 777.799 1029.75 768.21 1051.16C752.529 1086.19 724.118 1113.56 696.643 1140.02C659.318 1175.97 620.719 1213.15 615.366 1269.41Z" fill="url(#paint53_linear_15_4142)"/>
<path d="M597.422 1263.86L596.84 1263.83C598.341 1238.38 607.197 1214.96 623.907 1192.24C638.814 1171.97 658.521 1154.03 677.582 1136.69C705.394 1111.38 734.149 1085.21 749.387 1050.85C770.619 1002.98 748.905 957.231 722.285 914.919C697.935 876.221 669.163 839.612 641.333 804.206C629.924 789.689 618.124 774.678 606.807 759.742C603.997 756.03 601.117 752.271 598.201 748.46C567.55 708.383 532.808 662.952 524.54 613.31C521.462 594.819 525.704 576.59 537.149 559.129C546.173 545.362 559.398 532.178 577.587 518.813C598.882 503.167 622.22 489.913 644.79 477.095C663.531 466.453 682.912 455.444 701.019 443.127C734.068 420.644 767.506 390.999 764.946 341.583C761.862 282.061 747.13 223.289 732.886 166.449C717.706 105.873 702.008 43.2319 700.367 -20.4566L700.949 -20.474C702.59 43.1563 718.282 105.763 733.451 166.309C747.7 223.173 762.438 281.979 765.528 341.554C766.662 363.397 760.954 382.801 748.078 400.873C737.751 415.367 722.902 428.947 701.345 443.609C683.215 455.945 663.827 466.953 645.075 477.601C622.522 490.407 599.196 503.656 577.93 519.284C536.887 549.441 519.606 580.168 525.11 613.217C533.349 662.72 568.045 708.08 598.661 748.105C601.576 751.916 604.456 755.681 607.267 759.393C618.583 774.323 630.377 789.335 641.787 803.851C669.623 839.268 698.406 875.889 722.774 914.617C734.696 933.562 747.764 956.178 754.31 980.272C761.216 1005.69 759.779 1028.86 749.917 1051.09C734.632 1085.56 705.825 1111.77 677.972 1137.13C640.007 1171.67 600.75 1207.41 597.416 1263.87L597.422 1263.86Z" fill="url(#paint54_linear_15_4142)"/>
<path d="M581.351 1256.66L580.769 1256.64C581.683 1231.33 590.16 1208.16 606.673 1185.8C621.405 1165.85 641.1 1148.29 660.144 1131.31C687.945 1106.53 716.699 1080.9 731.472 1046.88C752.279 998.984 730.565 952.309 706.575 912.964C681.917 872.526 652.19 834.515 623.436 797.754C611.264 782.19 598.673 766.096 586.657 750.054C584.458 747.116 582.218 744.149 579.96 741.146C550.211 701.651 516.487 656.884 507.917 607.992C501.656 572.301 518.832 540.521 560.428 510.841C581.741 495.632 605.049 482.843 627.59 470.473C646.843 459.907 666.754 448.98 685.326 436.598C717.013 415.477 749.085 387.369 746.728 339.064C743.866 280.385 729.197 222.469 715.012 166.46C699.669 105.867 683.802 43.2145 682.836 -20.5671H683.418C684.384 43.1388 700.239 105.763 715.576 166.321C729.767 222.358 744.442 280.304 747.31 339.041C748.346 360.29 742.929 378.978 730.745 396.183C720.819 410.194 706.494 423.193 685.652 437.087C667.056 449.48 647.134 460.413 627.869 470.985C605.346 483.344 582.049 496.127 560.765 511.313C519.373 540.847 502.273 572.441 508.487 607.887C517.034 656.639 550.711 701.348 580.426 740.791C582.689 743.794 584.924 746.767 587.129 749.705C599.138 765.741 611.723 781.835 623.895 797.399C652.656 834.171 682.4 872.201 707.07 912.668C731.135 952.134 752.919 998.972 732.002 1047.12C717.176 1081.25 688.375 1106.92 660.528 1131.75C622.598 1165.57 583.376 1200.53 581.345 1256.66H581.351Z" fill="url(#paint55_linear_15_4142)"/>
<path d="M566.363 1249.67L565.781 1249.65C566.625 1224.61 574.991 1201.6 591.347 1179.32C605.94 1159.45 625.466 1141.9 644.347 1124.92C671.217 1100.77 699.005 1075.79 713.872 1043.28C736.092 994.684 715.134 948.061 691.703 908.857C667.016 867.552 636.888 828.847 607.755 791.412C595.746 775.987 583.335 760.033 571.489 744.143C569.295 741.199 567.061 738.22 564.803 735.212C534.757 695.123 500.696 649.686 492.48 600.108C489.222 580.441 493.033 561.741 503.803 544.53C512.641 530.409 526.128 517.038 545.032 503.667C566.264 488.65 589.444 476.001 611.857 463.77C631.174 453.227 651.155 442.329 669.791 429.959C701.315 409.042 733.078 381.236 729.686 333.641C725.671 277.4 711.672 221.724 698.138 167.88C682.627 106.193 666.591 42.3999 665.509 -22.5163L666.091 -22.5279C667.173 42.3184 683.203 106.077 698.703 167.735C712.248 221.602 726.253 277.307 730.268 333.6C731.769 354.634 726.678 373.119 714.704 390.121C705.022 403.87 690.854 416.682 670.117 430.442C651.457 442.824 631.471 453.734 612.136 464.277C589.735 476.501 566.566 489.139 545.364 504.139C503.722 533.603 487.098 564.063 493.051 600.009C501.243 649.442 535.252 694.82 565.263 734.857C567.521 737.865 569.755 740.844 571.948 743.794C583.789 759.678 596.2 775.626 608.209 791.051C637.353 828.498 667.493 867.22 692.198 908.554C715.71 947.887 736.732 994.678 714.395 1043.52C699.477 1076.14 671.641 1101.16 644.731 1125.35C607.121 1159.16 568.23 1194.12 566.357 1249.67H566.363Z" fill="url(#paint56_linear_15_4142)"/>
<path d="M552.108 1243.11L551.526 1243.1C552.311 1218.23 560.568 1195.3 576.772 1173.01C591.225 1153.12 610.594 1135.48 629.318 1118.43C655.588 1094.51 682.749 1069.77 697.568 1038.01C720.249 989.413 700.658 944.058 678.495 906.099C653.628 863.496 622.72 823.785 592.831 785.378C581.078 770.279 568.929 754.662 557.338 739.121C554.767 735.671 552.137 732.174 549.466 728.625C518.308 687.175 482.991 640.191 476.573 589.099C472.203 554.288 489.228 524.695 530.16 495.975C551.177 481.231 574.055 468.728 596.176 456.637C614.888 446.408 634.241 435.836 652.365 423.955C668.668 413.272 687.043 399.983 699.692 382.435C711.311 366.323 715.064 349.677 712.644 324.978C707.46 272.053 694.316 219.42 681.603 168.526C665.835 105.396 649.531 40.1191 648.304 -26.2401L648.885 -26.2517C650.113 40.0376 666.411 105.285 682.167 168.386C694.886 219.303 708.036 271.954 713.226 324.925C715.664 349.782 711.876 366.544 700.169 382.784C687.462 400.408 669.035 413.738 652.691 424.449C634.543 436.342 615.185 446.926 596.461 457.155C574.351 469.24 551.491 481.732 530.498 496.464C489.763 525.05 472.814 554.462 477.155 589.041C483.549 639.981 518.82 686.901 549.937 728.293C552.608 731.843 555.238 735.345 557.81 738.79C569.394 754.325 581.543 769.936 593.296 785.035C623.197 823.454 654.116 863.182 679.008 905.819C693.408 930.489 701.926 950.976 705.801 970.282C710.666 994.51 708.152 1016.75 698.104 1038.28C683.237 1070.13 656.031 1094.91 629.72 1118.88C592.418 1152.85 553.853 1187.98 552.113 1243.13L552.108 1243.11Z" fill="url(#paint57_linear_15_4142)"/>
<path d="M538.527 1236.98L537.946 1236.96C538.661 1212.19 546.83 1189.25 562.912 1166.81C577.255 1146.8 596.485 1128.96 615.08 1111.7C640.408 1088.2 666.603 1063.89 681.457 1033.41C705.528 984.002 686.507 938.827 664.729 901.153C639.721 857.887 608.325 817.694 577.965 778.826C566.299 763.891 554.231 748.443 542.728 733.076C540.11 729.579 537.434 726.036 534.722 722.434C503.175 680.635 467.42 633.255 461.183 581.698C455.65 535.954 489.17 506.611 514.864 488.522C535.804 473.778 558.584 461.233 580.618 449.096C599.429 438.734 618.886 428.022 637.097 415.989C653.342 405.26 671.624 391.936 684.052 374.446C695.456 358.404 698.907 341.816 695.962 317.204C689.958 267.003 677.495 216.877 665.433 168.404C649.299 103.546 632.623 36.4825 631.221 -31.5989L631.803 -31.6105C633.199 36.4069 649.869 103.435 665.998 168.264C678.059 216.755 690.528 266.898 696.538 317.134C699.5 341.909 696.021 358.614 684.529 374.783C672.049 392.349 653.709 405.714 637.417 416.472C619.182 428.516 599.72 439.234 580.897 449.602C558.88 461.728 536.113 474.267 515.195 488.993C489.624 506.995 456.261 536.186 461.759 581.623C467.979 633.028 503.681 680.338 535.182 722.079C537.899 725.681 540.575 729.224 543.194 732.721C554.691 748.082 566.753 763.53 578.419 778.46C608.791 817.344 640.199 857.556 665.229 900.856C679.519 925.573 687.799 946.188 691.296 965.732C695.596 989.744 692.541 1011.96 681.975 1033.66C667.074 1064.24 640.839 1088.58 615.47 1112.12C578.419 1146.5 540.11 1182.04 538.522 1236.98H538.527Z" fill="url(#paint58_linear_15_4142)"/>
<path d="M525.546 1231.26L524.965 1231.24C525.616 1206.49 533.698 1183.43 549.67 1160.74C563.913 1140.5 583.027 1122.35 601.512 1104.8C625.903 1081.65 651.12 1057.71 665.968 1028.37C675.918 1008.71 680.503 990.245 679.601 973.47C678.1 945.629 664.52 918.614 651.428 895.858C626.223 852.028 594.291 811.45 563.419 772.211C551.799 757.444 539.79 742.176 528.339 726.99C525.657 723.435 522.911 719.822 520.124 716.156C488.157 674.124 451.926 626.488 445.77 574.565C443.46 555.079 447.725 537.414 458.815 520.564C467.653 507.135 480.983 494.189 499.579 480.987C520.45 466.173 543.147 453.501 565.1 441.253C584.027 430.687 603.607 419.766 621.917 407.512C638.116 396.672 656.321 383.232 668.54 365.718C682.405 345.842 681.969 326.461 679.438 308.471C672.788 261.219 661.029 213.834 649.654 168.008C633.036 101.044 615.848 31.7987 614.254 -38.389L614.836 -38.4006C616.424 31.7231 633.606 100.939 650.218 167.868C661.593 213.706 673.358 261.103 680.014 308.389C682.562 326.496 682.993 346.011 669.017 366.05C656.746 383.639 638.488 397.126 622.243 407.995C603.909 420.26 584.324 431.193 565.385 441.759C543.45 454.001 520.764 466.662 499.916 481.458C474.338 499.618 440.958 529.018 446.352 574.489C452.49 626.256 488.669 673.827 520.589 715.795C523.376 719.461 526.128 723.074 528.811 726.635C540.255 741.815 552.271 757.083 563.884 771.844C594.774 811.101 626.711 851.691 651.94 895.561C665.061 918.375 678.676 945.472 680.189 973.435C681.096 990.315 676.494 1008.88 666.492 1028.63C651.597 1058.06 626.339 1082.04 601.913 1105.23C565.077 1140.2 526.989 1176.36 525.546 1231.26Z" fill="url(#paint59_linear_15_4142)"/>
<path d="M513.107 1225.94L512.525 1225.93C513.107 1201.11 521.119 1177.84 537.003 1154.78C551.165 1134.22 570.185 1115.68 588.577 1097.75C611.717 1075.19 635.637 1051.87 650.462 1024.1C662.053 1002.38 667.184 983.391 666.149 966.04C664.491 938.385 651.393 912.4 637.481 888.44C612.061 844.668 579.902 804.206 548.797 765.077C537.073 750.328 524.953 735.084 513.398 719.909C510.983 716.738 508.522 713.526 506.026 710.274C474.443 669.079 438.654 622.386 430.828 571.184C427.739 550.977 431.544 532.655 442.459 515.176C451.239 501.119 464.773 487.585 483.84 473.801C504.775 458.668 527.583 445.716 549.641 433.189C569.4 421.971 589.828 410.369 608.837 397.237C624.745 386.246 642.567 372.636 654.087 354.972C664.171 339.512 666.958 323.372 663.402 300.994C656.298 256.291 645.127 211.297 634.322 167.781C617.059 98.2509 599.214 26.3469 597.398 -46.43L597.98 -46.4417C599.79 26.277 617.629 98.1462 634.886 167.647C645.691 211.175 656.863 256.18 663.973 300.907C667.551 323.441 664.741 339.704 654.57 355.297C642.991 373.044 625.123 386.699 609.163 397.72C590.131 410.863 569.691 422.471 549.92 433.695C527.874 446.216 505.077 459.162 484.172 474.273C458.053 493.159 424.16 523.741 431.393 571.097C439.195 622.148 474.938 668.783 506.474 709.925C508.964 713.177 511.431 716.389 513.846 719.56C525.395 734.729 537.515 749.973 549.239 764.717C580.356 803.863 612.532 844.342 637.97 888.149C651.917 912.167 665.055 938.228 666.719 966.005C667.766 983.466 662.611 1002.56 650.968 1024.38C636.108 1052.22 612.148 1075.58 588.979 1098.17C552.311 1133.92 514.398 1170.88 513.101 1225.94L513.107 1225.94Z" fill="url(#paint60_linear_15_4142)"/>
<path d="M501.138 1221L500.556 1220.99C501.08 1196.01 509.022 1172.43 524.848 1148.9C538.952 1127.93 557.891 1108.89 576.207 1090.49C598.381 1068.21 621.306 1045.18 636.027 1018.39C648.501 995.691 653.965 977.171 653.22 960.105C652.016 932.503 638.936 906.541 624.983 882.592C599.248 838.419 566.479 797.754 534.792 758.421C523.062 743.864 510.936 728.805 499.375 713.835C496.856 710.571 494.284 707.26 491.677 703.908C459.641 662.702 423.334 615.998 415.392 564.621C412.256 544.326 416.078 525.859 427.081 508.165C435.878 494.009 449.459 480.3 468.59 466.243C489.455 450.917 512.193 437.715 534.187 424.956C554.097 413.4 574.689 401.449 593.82 387.956C609.698 376.756 627.456 362.908 638.784 345.074C648.716 329.452 651.23 313.207 647.204 290.765C639.838 249.722 629.504 208.237 619.52 168.119C601.43 95.4698 582.73 20.348 580.664 -55.5824L581.246 -55.5999C583.312 20.2666 602.006 95.3592 620.084 167.973C630.074 208.103 640.408 249.594 647.78 290.655C651.835 313.259 649.298 329.626 639.279 345.383C627.892 363.298 610.076 377.192 594.157 388.428C575.009 401.932 554.406 413.889 534.483 425.45C512.507 438.204 489.78 451.395 468.939 466.703C442.692 485.985 408.642 517.108 415.974 564.522C423.892 615.748 460.153 662.388 492.148 703.536C494.755 706.887 497.327 710.198 499.846 713.462C511.402 728.433 523.533 743.485 535.257 758.043C566.956 797.393 599.737 838.075 625.495 882.284C639.483 906.297 652.597 932.34 653.808 960.065C654.558 977.241 649.071 995.86 636.544 1018.66C621.783 1045.51 598.829 1068.58 576.626 1090.89C540.086 1127.61 502.307 1165.57 501.144 1220.99L501.138 1221Z" fill="url(#paint61_linear_15_4142)"/>
<path d="M489.577 1216.42L488.995 1216.41C489.455 1191.19 497.339 1167.23 513.095 1143.15C527.135 1121.7 546.004 1102.13 564.251 1083.19C585.191 1061.47 606.842 1039.01 621.393 1013.53C630.476 997.628 640.885 976.676 640.751 955.608C640.565 926.917 626.013 898.634 611.799 874.743C585.884 831.181 553.108 791.097 521.415 752.335C510.459 738.935 499.131 725.082 488.285 711.321C470.132 688.292 449.279 661.306 431.48 632.423C416.823 608.637 402.795 582.763 399.409 554.672C397.052 535.151 401.288 517.253 412.361 499.967C421.1 486.317 434.325 472.946 452.787 459.086C473.698 443.394 496.513 429.82 518.576 416.693C538.306 404.958 558.7 392.82 577.662 379.211C594.245 367.312 611.182 353.831 622.714 335.939C635.852 315.558 634.339 296.188 630.749 278.291C623.453 241.96 614.214 205.095 605.282 169.445C586.14 93.0551 566.345 14.07 564 -65.6657L564.582 -65.6832C566.927 13.9885 586.71 92.9445 605.847 169.306C614.784 204.967 624.023 241.838 631.32 278.18C634.939 296.194 636.457 315.697 623.203 336.259C611.613 354.238 594.629 367.755 578 379.688C559.02 393.309 538.609 405.452 518.867 417.194C496.815 430.314 474.013 443.883 453.13 459.552C427.692 478.642 394.51 509.265 399.979 554.602C403.354 582.577 417.347 608.387 431.969 632.115C449.75 660.968 470.591 687.937 488.733 710.954C499.573 724.709 510.902 738.563 521.852 751.957C553.556 790.737 586.343 830.837 612.282 874.435C626.537 898.389 641.13 926.766 641.316 955.596C641.455 976.804 631 997.849 621.882 1013.81C607.295 1039.35 585.616 1061.83 564.652 1083.58C528.24 1121.36 490.584 1160.43 489.56 1216.41L489.577 1216.42Z" fill="url(#paint62_linear_15_4142)"/>
<path d="M478.365 1212.21L477.783 1212.2C478.185 1186.62 486.028 1162.15 501.761 1137.39C515.777 1115.32 534.623 1095.06 552.852 1075.47C573.252 1053.55 594.349 1030.87 608.779 1005.56C618.711 988.144 627.98 969.45 628.568 950.802C629.452 922.547 615.348 894.648 601.43 871.083C574.997 826.322 540.96 785.477 508.039 745.97C496.71 732.378 485.004 718.326 473.815 704.368C470.702 700.487 467.508 696.542 464.267 692.533C430.77 651.147 392.799 604.245 384.415 552.129C381.069 531.316 385.154 512.156 396.913 493.549C406.211 478.834 419.913 464.928 440.039 449.794C460.211 434.62 482.083 421.249 503.239 408.309C522.73 396.393 542.88 384.064 561.65 370.373C578.337 358.195 595.362 344.463 606.876 326.583C619.846 306.44 619.246 287.559 614.359 265.403C607.534 234.449 599.551 202.942 591.836 172.471C571.343 91.5714 550.153 7.91408 547.488 -76.4706L548.07 -76.488C550.734 7.83262 571.914 91.4609 592.4 172.331C600.121 202.808 608.098 234.315 614.929 265.281C619.846 287.577 620.445 306.591 607.365 326.903C595.798 344.865 578.727 358.643 561.993 370.85C543.205 384.558 523.045 396.888 503.541 408.815C482.403 421.744 460.543 435.115 440.388 450.266C410.58 472.684 377.34 504.511 384.991 552.047C393.352 604.012 431.271 650.85 464.721 692.178C467.962 696.187 471.156 700.132 474.269 704.013C485.452 717.966 497.158 732.017 508.481 745.609C541.413 785.128 575.474 825.996 601.931 870.798C615.889 894.439 630.04 922.431 629.149 950.831C628.562 969.607 619.252 988.377 609.285 1005.87C594.821 1031.24 573.706 1053.93 553.283 1075.89C516.895 1115 479.261 1155.44 478.371 1212.22L478.365 1212.21Z" fill="url(#paint63_linear_15_4142)"/>
<path d="M467.42 1208.34H466.839C467.188 1182.33 474.99 1157.29 490.694 1131.79C504.687 1109.07 523.51 1088.1 541.71 1067.83C560.853 1046.51 580.653 1024.46 594.815 1000.37C605.614 982.006 616.198 962.38 616.646 942.161C617.245 915.309 604.142 889.231 591.254 867.267C564.239 821.237 528.793 779.606 494.517 739.348C482.88 725.681 470.842 711.542 459.374 697.508C440.836 674.816 419.529 648.22 401.277 619.64C386.282 596.163 371.917 570.602 368.344 542.808C365.836 523.304 370.026 505.163 381.139 487.352C389.826 473.441 402.941 459.651 421.251 445.198C442.349 428.54 465.465 414.017 487.82 399.971C508.19 387.177 529.247 373.945 548.715 359.138C564.256 347.32 581.636 332.757 592.68 314.376C604.718 294.344 603.723 276.4 597.934 252.177C592.045 227.531 585.575 202.389 579.32 178.08C557.077 91.6529 534.07 2.28766 531.039 -87.863L531.621 -87.8805C534.652 2.20621 557.647 91.5423 579.885 177.94C586.145 202.25 592.616 227.391 598.504 252.044C604.322 276.411 605.317 294.477 593.18 314.679C582.084 333.147 564.658 347.751 549.07 359.603C529.579 374.423 508.51 387.665 488.128 400.466C465.785 414.5 442.68 429.017 421.606 445.658C396.209 465.714 363.113 497.593 368.914 542.732C372.475 570.416 386.806 595.901 401.759 619.326C419.994 647.883 441.29 674.461 459.816 697.142C471.284 711.176 483.316 725.309 494.953 738.976C529.247 779.251 564.71 820.899 591.749 866.976C604.683 889.016 617.821 915.181 617.222 942.179C616.768 962.537 606.149 982.239 595.309 1000.68C581.118 1024.81 561.301 1046.88 542.141 1068.23C505.781 1108.73 468.183 1150.61 467.415 1208.35L467.42 1208.34Z" fill="url(#paint64_linear_15_4142)"/>
<path d="M456.691 1204.8H456.109C456.406 1178.21 464.209 1152.45 479.965 1126.03C493.999 1102.5 512.891 1080.66 531.161 1059.54C549.687 1038.12 568.847 1015.97 582.852 992.13C592.72 975.332 605.009 954.427 605.154 933.439C605.34 906.937 591.882 880.992 578.698 859.103C551.468 813.906 516.004 773.101 481.71 733.635C470.795 721.073 459.507 708.086 448.708 695.204C429.659 672.483 407.758 645.881 388.907 617.65C373.226 594.173 358.057 568.607 353.275 540.771C349.702 519.994 353.734 500.543 365.592 481.307C374.832 466.319 388.441 451.959 408.422 436.109C428.687 420.033 450.756 405.766 472.093 391.965C493.435 378.164 515.504 363.891 535.758 347.827C551.037 335.701 568.062 320.782 578.453 302.041C589.398 282.299 588.112 264.664 582.486 242.612C577.901 224.645 573.066 206.311 568.393 188.582C543.7 94.9344 518.163 -1.89579 514.689 -99.6744L515.271 -99.6977C518.745 -1.98306 544.27 94.818 568.958 188.431C573.636 206.159 578.471 224.493 583.05 242.461C588.711 264.652 590.003 282.404 578.96 302.315C568.521 321.149 551.444 336.114 536.119 348.275C515.841 364.363 493.76 378.641 472.407 392.448C451.076 406.238 429.025 420.499 408.776 436.558C381.261 458.382 345.635 492.915 353.839 540.667C358.604 568.397 373.738 593.894 389.384 617.319C408.218 645.526 430.107 672.111 449.145 694.82C459.944 707.696 471.226 720.683 482.141 733.245C516.452 772.729 551.927 813.551 579.187 858.795C592.418 880.748 605.916 906.785 605.73 933.434C605.585 954.572 593.256 975.553 583.353 992.415C569.319 1016.3 550.141 1038.47 531.598 1059.91C495.093 1102.12 457.337 1145.76 456.685 1204.79L456.691 1204.8Z" fill="url(#paint65_linear_15_4142)"/>
<path d="M446.107 1201.57H445.526C445.77 1174.35 453.567 1147.81 469.358 1120.46C483.421 1096.09 502.36 1073.38 520.671 1051.41C537.893 1030.75 555.703 1009.39 569.313 986.8L570.831 984.281C581.147 967.204 592.837 947.846 593.698 928.145C594.896 900.845 580.542 873.83 566.345 851.01C538.33 805.969 502.249 765.368 467.351 726.106C455.196 712.427 442.622 698.282 430.636 684.213C427.337 680.338 424.003 676.474 420.477 672.378C385.933 632.284 346.775 586.847 337.704 535.564C334.016 514.734 338.001 495.114 349.882 475.587C359.052 460.518 372.627 445.908 392.601 429.61C413.07 412.911 435.407 398.034 457.005 383.645C476.963 370.355 497.595 356.607 516.702 341.461C533.128 328.44 551.427 312.439 562.639 292.458C574.188 271.873 572.804 253.836 567.171 233.087C564.722 224.057 562.249 215.009 559.77 205.95C531.592 102.842 502.459 -3.78096 498.45 -111.695L499.032 -111.719C503.041 -3.86823 532.168 102.72 560.335 205.793C562.813 214.852 565.28 223.9 567.736 232.93C573.403 253.818 574.794 271.978 563.145 292.738C551.887 312.805 533.535 328.853 517.063 341.909C497.938 357.072 477.294 370.821 457.325 384.122C435.739 398.499 413.414 413.371 392.968 430.053C363.125 454.397 329.943 488.376 338.275 535.453C347.322 586.574 386.422 631.952 420.919 671.988C424.445 676.085 427.779 679.948 431.084 683.823C443.07 697.886 455.638 712.031 467.793 725.71C502.703 764.996 538.801 805.614 566.846 850.696C581.089 873.597 595.49 900.705 594.285 928.168C593.418 948.02 581.683 967.442 571.332 984.583L569.813 987.097C556.186 1009.72 538.359 1031.11 521.119 1051.79C484.509 1095.7 446.654 1141.1 446.107 1201.57Z" fill="url(#paint66_linear_15_4142)"/>
<path d="M435.6 1198.65H435.018C435.46 1136.22 473.664 1088.66 510.611 1042.67C526.879 1022.42 543.706 1001.48 556.966 979.632C557.851 978.177 558.752 976.705 559.666 975.21C569.773 958.703 581.229 940.003 582.3 920.982C583.801 894.398 570.296 868.936 555.482 845.383C527.118 800.291 490.368 759.911 454.835 720.857C443.059 707.911 430.875 694.529 419.244 681.234C397.774 656.698 375.181 630.329 355.59 601.539C340.957 580.034 326.754 556.545 321.977 530.531C318.073 509.288 322.309 489.075 334.923 468.733C344.448 453.367 358.511 438.297 379.173 421.296C398.891 405.074 420.303 390.412 441.011 376.232C462.714 361.372 485.149 346.005 505.659 328.829C520.776 316.169 537.597 300.657 547.761 281.578C559.049 260.399 557.245 242.245 550.316 218.058C533.989 161.084 518.32 105.291 506.026 49.0387C492.079 -14.7778 484.312 -71.3038 482.293 -123.78L482.874 -123.803C487.518 -3.2282 522.649 119.378 550.874 217.889C557.845 242.222 559.654 260.486 548.279 281.846C538.068 301.012 521.195 316.576 506.038 329.266C485.504 346.465 463.051 361.837 441.342 376.703C420.646 390.877 399.246 405.528 379.545 421.738C348.422 447.345 313.814 482.837 322.553 530.415C327.313 556.324 341.475 579.749 356.073 601.202C375.641 629.962 398.228 656.314 419.68 680.838C431.311 694.133 443.489 707.516 455.266 720.456C490.822 759.527 527.589 799.924 555.977 845.063C570.843 868.698 584.394 894.258 582.887 921.005C581.805 940.171 570.308 958.942 560.166 975.507C559.253 977.002 558.351 978.474 557.467 979.929C544.189 1001.81 527.35 1022.76 511.07 1043.04C474.187 1088.95 436.042 1136.43 435.606 1198.64L435.6 1198.65Z" fill="url(#paint67_linear_15_4142)"/>
<path d="M425.109 1196.01H424.527C424.876 1131.63 463.388 1081.94 500.632 1033.89C515.522 1014.69 530.923 994.824 543.561 974.378C544.87 972.254 546.243 970.078 547.645 967.855C558.357 950.877 570.5 931.636 571.227 912.26C572.228 885.775 557.63 859.58 543.217 837.453C514.212 792.913 477.073 753.045 441.156 714.492C429.141 701.598 416.719 688.263 404.849 675.014C385.294 653.189 362.794 627.6 343.191 600.131C327.028 577.48 311.353 552.798 306.128 525.853C302.201 505.587 306.21 485.182 318.05 465.207C327.179 449.812 340.579 434.76 360.216 417.845C380.895 400.035 403.563 384.087 425.487 368.662C446.998 353.529 469.248 337.877 489.583 320.486C504.595 307.65 521.369 291.9 531.924 272.431C543.846 250.444 541.955 231.819 535.037 208.888C502.04 99.5135 470.871 -17.9372 466.21 -135.766L466.792 -135.789C471.453 -18.0245 502.616 99.3856 535.595 208.72C542.554 231.79 544.457 250.542 532.436 272.705C521.835 292.255 505.019 308.052 489.961 320.922C469.603 338.331 447.347 353.988 425.825 369.134C403.913 384.552 381.256 400.495 360.6 418.282C333.108 441.957 297.61 478.857 306.698 525.736C311.9 552.577 327.54 577.189 343.669 599.788C363.248 627.233 385.742 652.805 405.286 674.618C417.155 687.867 429.572 701.197 441.587 714.091C477.521 752.661 514.678 792.546 543.712 837.127C558.171 859.336 572.821 885.629 571.815 912.272C571.076 931.804 558.892 951.116 548.146 968.152C546.743 970.375 545.37 972.545 544.061 974.669C531.406 995.138 515.999 1015.01 501.098 1034.24C463.912 1082.21 425.464 1131.81 425.115 1196L425.109 1196.01Z" fill="url(#paint68_linear_15_4142)"/>
<path d="M414.566 1193.65H413.984C414.252 1126.84 453.416 1074.57 491.288 1024.01C505.322 1005.29 519.833 985.916 532.063 966.168C533.663 963.585 535.339 960.961 537.12 958.185C547.162 942.493 558.549 924.706 559.904 906.971C561.883 881.103 547.674 855.42 533.512 833.706C504.117 788.631 465.931 748.675 429.001 710.041C417.528 698.038 405.664 685.627 394.312 673.309C372.074 649.186 348.632 623.265 327.999 595.005C311.586 572.528 295.666 548.074 290.348 521.536C286.107 500.392 290.284 479.847 303.114 458.714C312.616 443.062 326.626 427.481 347.194 409.682C367.157 392.407 388.901 376.703 409.929 361.511C431.265 346.098 453.328 330.156 473.53 312.584C488.611 299.47 505.467 283.405 516.167 263.744C526.844 244.131 527.903 225.715 519.816 200.184C490.153 106.525 455.441 -17.6288 450.216 -147.432L450.797 -147.455C456.017 -17.7219 490.723 106.379 520.368 200.009C528.508 225.709 527.438 244.259 516.679 264.024C505.933 283.766 489.03 299.871 473.908 313.026C453.683 330.616 431.614 346.564 410.266 361.989C389.25 377.175 367.518 392.873 347.573 410.13C316.24 437.238 281.481 474.389 290.913 521.431C296.213 547.858 312.092 572.249 328.465 594.673C349.08 622.91 372.51 648.814 394.737 672.925C406.083 685.237 417.947 697.648 429.421 709.645C466.368 748.303 504.571 788.276 534.001 833.397C548.215 855.193 562.476 880.986 560.486 907.024C559.119 924.904 547.692 942.755 537.614 958.505C535.84 961.281 534.158 963.899 532.564 966.482C520.322 986.253 505.805 1005.63 491.759 1024.38C453.939 1074.85 414.834 1127.05 414.572 1193.66L414.566 1193.65Z" fill="url(#paint69_linear_15_4142)"/>
<path d="M403.906 1191.54H403.324C403.511 1122.2 443.245 1067.44 481.67 1014.48C493.941 997.57 506.631 980.086 517.779 962.438C519.792 959.25 521.939 956.009 524.214 952.576C534.838 936.535 546.883 918.346 548.512 900.077C550.868 873.661 535.432 847.059 520.019 824.513C489.996 780.595 451.85 741.6 414.955 703.891C403.051 691.719 390.739 679.139 378.945 666.63C356.649 642.984 333.137 617.563 312.307 589.64C296.958 569.072 280.108 544.827 274.388 517.399C270.019 496.441 274.132 475.849 286.95 454.437C299.326 433.765 317.782 416.257 330.588 404.882C350.743 386.985 372.76 370.693 394.05 354.937C415.962 338.721 438.619 321.958 459.251 303.432C475.066 289.235 492.585 271.914 502.598 251.084C513.235 228.968 510.692 210.541 502.703 186.185C460.909 58.7671 438.532 -54.0405 434.296 -158.685L434.878 -158.708C439.114 -54.1162 461.48 58.6391 503.262 186.004C511.297 210.5 513.851 229.038 503.128 251.334C493.068 272.251 475.502 289.625 459.647 303.863C438.991 322.406 416.323 339.18 394.399 355.402C373.115 371.153 351.11 387.433 330.978 405.313C299.803 432.997 265.265 470.741 274.958 517.271C280.649 544.582 297.464 568.764 312.772 589.285C333.591 617.185 357.085 642.594 379.37 666.228C391.158 678.732 403.464 691.311 415.369 703.478C452.275 741.204 490.438 780.217 520.496 824.175C535.967 846.803 551.462 873.521 549.088 900.117C547.447 918.538 535.362 936.785 524.697 952.89C522.428 956.318 520.281 959.558 518.273 962.741C507.114 980.4 494.418 997.896 482.141 1014.82C443.768 1067.69 404.087 1122.38 403.906 1191.53V1191.54Z" fill="url(#paint70_linear_15_4142)"/>
<path d="M393.061 1189.69H392.479C392.531 1157.24 400.753 1124.92 417.603 1090.89C432.615 1060.58 452.886 1031.81 472.5 1003.99C483.124 988.924 494.11 973.336 504.088 957.737C506.421 954.095 508.97 950.342 511.67 946.374C523.021 929.687 535.892 910.771 537.306 891.931C539.162 867.162 524.621 842.236 510.151 821.115C479.494 776.377 440.01 737.056 401.829 699.033C389.424 686.68 376.595 673.903 364.335 661.201C341.719 637.759 317.875 612.577 296.83 585.073C280.183 563.318 263.991 539.608 258.295 513.652C253.785 493.118 258.009 471.794 270.851 450.277C283.221 429.54 301.659 411.707 314.448 400.099C334.784 381.643 357.027 364.805 378.544 348.525C400.218 332.123 422.63 315.162 443.076 296.549C458.774 282.259 476.23 264.774 486.65 243.467C499.067 218.076 493.726 197.257 486.045 174.612C466.513 116.998 450.989 59.9482 439.899 5.0514C427.954 -54.0696 420.745 -112.719 418.464 -169.269L419.046 -169.292C423.433 -60.5164 446.16 55.1248 486.598 174.42C494.319 197.193 499.689 218.128 487.174 243.717C476.712 265.112 459.211 282.643 443.466 296.974C423.003 315.604 400.578 332.571 378.893 348.984C357.388 365.259 335.15 382.085 314.838 400.524C283.477 428.988 248.776 467.558 258.865 513.524C264.538 539.369 280.69 563.015 297.295 584.718C318.323 612.198 342.156 637.369 364.76 660.794C377.014 673.49 389.837 686.267 402.242 698.619C440.446 736.66 479.947 775.999 510.634 820.783C525.162 841.985 539.761 867.022 537.893 891.972C536.468 910.969 523.557 929.948 512.158 946.7C509.459 950.662 506.91 954.409 504.583 958.046C494.598 973.656 483.607 989.244 472.977 1004.32C433.749 1059.97 393.183 1117.51 393.061 1189.68V1189.69Z" fill="url(#paint71_linear_15_4142)"/>
<path d="M381.971 1188.08H381.389C381.418 1153.96 389.901 1119.9 407.322 1083.93C422.839 1051.9 443.815 1021.42 464.104 991.938C473.5 978.282 483.217 964.161 492.242 950.103C494.72 946.24 497.501 942.225 500.44 937.972C511.279 922.303 523.568 904.545 525.57 887.183C528.362 862.938 514.264 838.57 500.102 817.926C468.788 772.281 427.867 732.587 388.295 694.203C375.46 681.752 362.188 668.881 349.539 656.081C328.977 635.268 305.278 610.866 284.279 584.636C267.266 563.388 248.583 538.363 242.23 510.254C237.598 489.755 241.776 468.314 254.652 446.524C266.981 425.665 285.379 407.57 298.151 395.776C318.515 376.965 340.817 359.754 362.392 343.114C385.066 325.623 408.509 307.534 429.717 287.565C444.333 273.804 460.63 256.989 470.702 236.59C482.502 212.688 479.627 193.289 470.678 167.863C430.124 52.6927 407.246 -64.0249 402.684 -179.061L403.266 -179.084C407.822 -64.1064 430.688 52.5589 471.225 167.671C480.232 193.243 483.118 212.758 471.225 236.846C461.113 257.327 444.769 274.189 430.118 287.984C408.887 307.97 385.433 326.066 362.747 343.567C341.184 360.202 318.887 377.402 298.546 396.195C267.156 425.194 232.449 464.352 242.8 510.12C249.13 538.112 267.767 563.073 284.739 584.27C305.72 610.476 329.407 634.867 349.958 655.668C362.601 668.463 375.873 681.339 388.703 693.784C428.297 732.186 469.236 771.897 500.579 817.595C514.8 838.326 528.956 862.81 526.146 887.247C524.132 904.755 511.797 922.576 500.917 938.304C497.979 942.551 495.203 946.56 492.73 950.418C483.7 964.481 473.983 978.608 464.581 992.264C423.997 1051.23 382.035 1112.2 381.971 1188.07V1188.08Z" fill="url(#paint72_linear_15_4142)"/>
<path d="M369.979 1186.67C369.991 1150.63 378.829 1114.57 396.994 1076.42C413.175 1042.44 435.076 1010.03 456.255 978.695C464.197 966.948 472.407 954.799 480.186 942.755C482.775 938.752 485.719 934.562 488.838 930.129C499.177 915.426 510.896 898.762 513.508 882.697C517.5 858.172 502.43 832.821 487.162 811.258C455.626 766.718 414.851 728.037 375.414 690.631C363.259 679.104 350.697 667.182 338.682 655.348C314.029 631.062 288.079 605.007 265.562 576.921C249.666 557.098 232.187 533.719 225.927 507.135C215.011 460.785 251.644 419.754 284.774 389.19C304.359 371.123 325.654 354.396 346.252 338.22C368.816 320.497 392.153 302.169 413.286 282.038C428.867 267.195 446.125 249.123 456.022 227.6C466.74 204.298 463.47 184.556 455.266 162.085C414.601 50.7144 391.624 -67.0272 386.981 -187.87L387.563 -187.893C392.206 -67.1145 415.165 50.569 455.813 161.881C464.069 184.486 467.351 204.356 456.552 227.839C446.614 249.449 429.31 267.573 413.687 282.451C392.531 302.606 369.188 320.94 346.612 338.668C326.021 354.838 304.731 371.56 285.17 389.609C252.156 420.068 215.645 460.937 226.491 506.995C232.723 533.458 250.16 556.778 266.016 576.549C288.51 604.611 314.448 630.654 339.089 654.929C351.098 666.758 363.666 678.68 375.815 690.206C415.27 727.63 456.063 766.328 487.634 810.915C502.971 832.577 518.105 858.056 514.084 882.784C511.448 898.983 499.689 915.705 489.315 930.46C486.202 934.888 483.258 939.072 480.681 943.069C472.896 955.119 464.686 967.268 456.744 979.021C414.391 1041.7 370.596 1106.5 370.567 1186.67H369.979Z" fill="url(#paint73_linear_15_4142)"/>
<path d="M358.779 1185.49H358.197C358.197 1147.27 367.448 1109.03 386.474 1068.57C403.435 1032.51 426.441 998.036 448.685 964.702C454.288 956.3 460.083 947.619 465.698 939.037C468.613 934.586 471.97 929.943 475.526 925.032C486.563 909.788 499.073 892.513 501.458 875.593C504.897 851.161 490.31 827.242 474.03 805.033C441.534 760.696 400.078 722.254 359.989 685.074C346.56 672.617 332.666 659.735 319.417 646.899C298.471 626.611 274.313 602.807 252.773 577.073C235.399 556.312 216.303 531.858 209.641 504.371C204.73 484.106 208.873 462.531 221.958 440.241C234.218 419.358 252.563 400.827 265.294 388.707C285.909 369.081 308.531 351.062 330.402 333.63C352.733 315.837 375.821 297.439 396.779 277.336C413.239 261.551 431.381 242.338 441.144 219.513C450.902 196.716 447.463 177.946 438.224 153.229C417.754 98.4546 401.748 40.7009 390.646 -18.4318C380.068 -74.7541 373.569 -134.341 371.329 -195.539L371.911 -195.562C376.548 -68.9822 398.42 45.0414 438.77 153.02C448.057 177.876 451.513 196.757 441.68 219.734C431.87 242.653 413.681 261.917 397.18 277.744C376.199 297.869 353.1 316.273 330.763 334.072C308.897 351.492 286.293 369.506 265.696 389.114C234.148 419.143 199.331 459.36 210.205 504.214C216.838 531.578 235.882 555.963 253.215 576.677C274.737 602.394 298.878 626.186 319.813 646.463C333.061 659.293 346.95 672.175 360.379 684.626C400.485 721.823 441.959 760.289 474.49 804.666C490.84 826.974 505.496 851.016 502.028 875.651C499.625 892.716 487.069 910.055 475.991 925.352C472.436 930.257 469.084 934.894 466.175 939.334C460.56 947.916 454.765 956.603 449.162 965.004C404.721 1031.6 358.767 1100.47 358.767 1185.47L358.779 1185.49Z" fill="url(#paint74_linear_15_4142)"/>
<path d="M345.961 1184.49C345.961 1143.68 355.777 1102.86 375.99 1059.71C394.021 1021.2 418.54 984.304 442.25 948.626C445.095 944.343 447.946 940.055 450.786 935.761C459.985 921.855 467.002 911.149 473.536 901.037C474.507 899.536 475.502 898.04 476.497 896.545C482.118 888.114 487.925 879.392 489.234 869.024C492.131 846.023 477.713 822.686 463.226 802.891C430.503 758.165 388.162 719.787 347.218 682.677C334.301 670.97 320.942 658.862 308.199 646.824C283.128 623.143 256.718 597.716 233.654 570.125C217.449 550.744 199.622 527.889 193.105 501.881C181.742 456.515 218.444 414.616 251.685 383.185C271.485 364.461 293.06 347.082 313.919 330.272C337.233 311.485 361.345 292.063 383.053 270.697C398.28 255.715 415.142 237.434 424.766 215.446C436.106 189.536 430.078 169.3 421.019 145.537C381.564 42.0624 360.216 -71.5831 355.759 -201.875L356.341 -201.893C360.798 -71.6587 382.134 41.9169 421.566 145.334C430.671 169.224 436.734 189.571 425.301 215.684C415.637 237.759 398.728 256.093 383.461 271.116C361.729 292.499 337.606 311.933 314.285 330.726C293.432 347.53 271.869 364.904 252.086 383.61C218.962 414.936 182.388 456.666 193.675 501.741C200.157 527.628 217.944 550.424 234.102 569.753C257.143 597.315 283.541 622.73 308.601 646.399C321.343 658.432 334.696 670.54 347.613 682.246C388.581 719.38 430.939 757.775 463.697 802.548C478.249 822.436 492.731 845.895 489.81 869.099C488.489 879.602 482.642 888.382 476.98 896.871C475.985 898.366 474.99 899.855 474.025 901.357C467.49 911.469 460.473 922.181 451.274 936.087C448.435 940.381 445.584 944.669 442.739 948.951C395.441 1020.13 346.537 1093.73 346.549 1184.49H345.961Z" fill="url(#paint75_linear_15_4142)"/>
<path d="M333.8 1183.67H333.218C333.218 1138.46 342.918 1094.91 362.043 1054.24C380.749 1014.46 405.728 977.217 429.88 941.201C432.009 938.024 434.139 934.853 436.263 931.677L442.355 922.577C448.08 914.029 453.997 905.191 459.792 896.475C460.892 894.817 462.056 893.135 463.185 891.512C468.922 883.221 474.851 874.65 476.294 864.474C479.523 841.788 465.052 818.683 450.477 799.075C417.318 754.465 374.436 716.389 332.962 679.564C319.9 667.962 306.39 655.965 293.502 644.037C268.04 620.466 241.217 595.156 217.792 567.722C201.315 548.428 183.184 525.678 176.522 499.821C171.216 479.224 175.749 456.963 189.975 433.648C202.601 412.964 220.911 394.45 235.451 380.631C255.205 361.861 276.704 344.399 297.499 327.503C321.052 308.372 345.402 288.589 367.303 266.84C382.535 251.712 399.38 233.273 408.87 211.192C419.954 185.399 413.676 164.994 404.32 141.005C381.412 82.2736 364.818 23.3213 354.997 -34.2113C344.902 -93.3614 341.934 -152.075 340.23 -206.716L340.811 -206.733C342.51 -152.116 345.478 -93.4255 355.567 -34.3102C365.377 23.1874 381.965 82.099 404.861 140.795C414.269 164.913 420.576 185.428 409.405 211.425C399.874 233.599 382.983 252.09 367.716 267.259C345.798 289.031 321.43 308.82 297.871 327.962C277.088 344.847 255.595 362.303 235.859 381.056C202.449 412.801 165.578 454.973 177.093 499.682C183.72 525.422 201.809 548.108 218.24 567.35C241.648 594.761 268.454 620.053 293.903 643.612C306.785 655.54 320.296 667.532 333.358 679.134C374.849 715.982 417.76 754.081 450.954 798.731C465.599 818.432 480.134 841.665 476.881 864.561C475.415 874.871 469.445 883.5 463.673 891.849C462.55 893.473 461.387 895.154 460.287 896.807C454.492 905.517 448.575 914.355 442.849 922.908L436.757 932.008C434.634 935.179 432.504 938.356 430.374 941.533C406.234 977.532 381.267 1014.76 362.578 1054.5C343.488 1095.09 333.812 1138.56 333.806 1183.68L333.8 1183.67Z" fill="url(#paint76_linear_15_4142)"/>
<path d="M320.482 1183.02H319.9C319.917 1136.95 329.017 1093.2 346.95 1053C366.692 1008.72 393.957 968.431 420.326 929.46C428.297 917.677 436.466 905.75 444.368 894.212L444.437 894.107C445.822 892.088 447.323 890.017 448.784 888.009C454.672 879.91 460.758 871.537 462.643 861.873C467.129 838.884 452.56 815.808 436.146 794.298C402.597 750.322 359.599 712.822 318.015 676.562C304.638 664.896 290.808 652.834 277.611 640.825C253.267 618.668 227.573 594.865 204.532 568.88C185.768 547.719 166.858 524.811 159.864 498.029C154.517 477.554 159.026 455.374 173.27 432.101C185.698 411.794 203.159 393.978 218.601 379.229C238.465 360.249 260.104 342.59 281.027 325.507C304.382 306.44 328.54 286.721 350.307 265.106C365.65 249.873 382.634 231.359 392.264 209.575C403.819 183.427 397.564 163.487 386.701 136.047C363.509 77.4792 347.054 18.6607 337.78 -38.773C328.523 -96.1252 326.277 -154.757 324.764 -209.893L325.346 -209.91C326.853 -154.792 329.105 -96.1776 338.35 -38.8661C347.613 18.5327 364.062 77.3105 387.236 135.832C398.152 163.406 404.442 183.45 392.793 209.808C383.129 231.679 366.098 250.24 350.714 265.513C328.924 287.146 304.76 306.871 281.393 325.949C260.476 343.026 238.849 360.679 219.002 379.642C183.603 413.458 148.954 453.949 160.428 497.872C167.393 524.532 186.251 547.375 204.968 568.484C227.992 594.446 253.669 618.238 278.001 640.383C291.192 652.392 305.022 664.448 318.399 676.114C360.006 712.397 403.028 749.915 436.612 793.937C453.107 815.558 467.74 838.762 463.213 861.978C461.305 871.77 455.178 880.201 449.255 888.347C447.736 890.441 446.299 892.42 444.92 894.427L444.85 894.532C436.949 906.07 428.78 917.997 420.809 929.78C394.451 968.728 367.204 1009 347.479 1053.23C329.582 1093.36 320.499 1137.03 320.482 1183.01V1183.02Z" fill="url(#paint77_linear_15_4142)"/>
<path d="M306.518 1182.51H305.936C305.982 1134.75 314.349 1091.91 331.508 1051.56C350.435 1007.05 376.92 967.012 404.686 926.504C411.878 916.007 419.558 904.987 429.571 890.767C431.067 888.649 432.644 886.555 434.308 884.344C440.347 876.332 446.59 868.052 448.644 858.516C453.543 835.795 438.986 812.917 422.519 791.58C388.633 747.675 345.158 710.408 303.12 674.374C289.615 662.795 275.651 650.827 262.327 638.905C237.656 616.824 211.619 593.096 188.281 567.222C169.255 546.124 150.083 523.293 143.002 496.609C137.515 475.931 141.949 454.397 156.565 430.768C168.993 410.677 186.542 392.838 202.077 378.03C221.894 359.138 243.452 341.525 264.299 324.495C287.875 305.236 312.249 285.319 334.202 263.471C349.545 248.209 366.505 229.678 375.99 207.957C388.057 180.32 379.498 159.106 369.583 134.535C345.856 75.7337 329.378 16.7464 320.604 -40.7862C311.923 -97.7311 310.602 -155.438 309.334 -211.243L309.915 -211.254C311.19 -155.467 312.505 -97.7835 321.18 -40.8734C329.948 16.6184 346.414 75.5591 370.125 134.319C375.611 147.923 379.952 159.246 381.68 170.778C383.629 183.782 381.994 195.669 376.525 208.19C367 229.998 349.993 248.582 334.615 263.884C312.638 285.75 288.253 305.672 264.666 324.943C243.83 341.967 222.278 359.574 202.478 378.449C166.869 412.394 132.017 452.96 143.567 496.458C150.619 523.014 169.738 545.781 188.718 566.826C212.032 592.678 238.058 616.393 262.716 638.469C276.029 650.385 289.993 662.359 303.498 673.932C345.559 709.989 389.052 747.267 422.979 791.226C439.533 812.672 454.166 835.684 449.214 858.638C447.131 868.296 440.848 876.628 434.773 884.693C433.109 886.904 431.538 888.987 430.049 891.099C420.035 905.313 412.36 916.333 405.163 926.83C377.415 967.309 350.941 1007.33 332.037 1051.78C314.908 1092.06 306.558 1134.82 306.512 1182.51L306.518 1182.51Z" fill="url(#paint78_linear_15_4142)"/>
<path d="M291.844 1182.15H291.262C291.349 1133.34 299.245 1090.28 315.391 1050.52C332.683 1007.94 357.086 969.223 388.406 924.182C395.999 913.261 404.395 901.467 414.077 888.12C415.683 885.909 417.394 883.715 419.209 881.399C425.406 873.469 431.817 865.271 434.058 855.868C439.428 833.322 424.102 809.664 408.398 789.503C374.233 745.621 330.327 708.557 287.87 672.716C274.249 661.213 260.162 649.326 246.739 637.479C221.766 615.445 195.409 591.776 171.815 565.988C152.55 544.937 133.146 522.152 126.018 495.545C120.503 474.971 125 453.524 139.756 429.983C152.161 410.188 169.796 392.396 185.413 377.605C205.178 358.876 226.654 341.391 247.414 324.483C271.194 305.119 295.783 285.098 317.916 263.116C334.021 247.121 350.208 229.363 359.518 207.864C371.195 180.896 362.846 160.549 352.268 134.785C328.034 75.7453 311.574 16.6301 303.358 -40.9083C295.922 -92.9425 295.038 -146.286 294.183 -197.866C294.113 -202.09 294.043 -206.32 293.967 -210.545L294.549 -210.556C294.625 -206.332 294.695 -202.102 294.764 -197.878C295.62 -146.315 296.504 -92.9949 303.934 -40.9956C312.144 16.4963 328.593 75.5649 352.809 134.558C363.433 160.45 371.829 180.896 360.053 208.091C350.703 229.678 334.475 247.488 318.329 263.523C296.172 285.523 271.572 305.556 247.781 324.931C227.026 341.834 205.562 359.306 185.809 378.024C150.043 411.911 115.033 452.354 126.571 495.393C133.67 521.873 153.022 544.594 172.24 565.593C195.816 591.363 222.162 615.02 247.123 637.043C260.546 648.883 274.627 660.776 288.248 672.274C330.728 708.138 374.651 745.225 408.858 789.148C424.643 809.42 440.051 833.223 434.622 856.008C432.353 865.538 425.906 873.789 419.669 881.766C417.859 884.082 416.148 886.264 414.554 888.469C404.878 901.81 396.482 913.604 388.889 924.52C357.592 969.525 333.207 1008.22 315.938 1050.74C299.821 1090.43 291.942 1133.42 291.849 1182.17L291.844 1182.15Z" fill="url(#paint79_linear_15_4142)"/>
<path d="M276.402 1181.92H275.82C275.965 1131.87 283.477 1088.49 298.768 1049.31C317.322 1001.77 346.397 957.865 372.382 921.18C380.435 909.817 389.174 897.842 399.106 884.571C400.689 882.458 402.353 880.381 404.116 878.188C410.423 870.333 416.946 862.21 419.093 852.656C423.963 830.925 409.591 808.559 394.888 789.55C360.536 745.132 315.862 707.935 272.654 671.965C259.097 660.677 245.081 649.006 231.733 637.392C203.828 613.1 176.738 589.058 152.161 562.009C134.804 542.907 115.702 520.389 108.796 494.87C103.245 474.36 107.865 452.966 122.929 429.453C135.433 409.938 153.266 392.221 169.063 377.466C188.607 359.207 209.775 342.113 230.244 325.583C254.641 305.882 279.863 285.511 302.497 263.035C317.695 247.942 334.434 229.602 343.308 208.027C353.827 182.444 345.821 163.377 335.685 139.23L335.511 138.817C310.439 79.1025 293.816 19.5451 286.095 -38.2028C279.613 -86.6819 279.218 -136.307 278.839 -184.303C278.775 -192.094 278.717 -199.891 278.63 -207.682H279.212C279.299 -199.897 279.363 -192.1 279.421 -184.309C279.805 -136.336 280.195 -86.7284 286.671 -38.2842C294.386 19.4113 310.998 78.9222 336.046 138.584L336.221 138.997C346.409 163.266 354.456 182.432 343.843 208.237C334.929 229.91 318.149 248.302 302.904 263.436C280.253 285.93 255.013 306.312 230.605 326.019C210.141 342.543 188.985 359.626 169.459 377.873C145.696 400.076 96.2455 446.274 109.354 494.707C116.232 520.099 135.276 542.558 152.591 561.607C177.151 588.634 204.224 612.664 232.117 636.944C245.459 648.558 259.476 660.224 273.033 671.511C316.264 707.504 360.961 744.719 395.353 789.189C410.138 808.308 424.591 830.814 419.669 852.779C417.498 862.466 410.929 870.641 404.581 878.548C402.824 880.742 401.16 882.807 399.583 884.914C389.651 898.186 380.912 910.154 372.865 921.512C346.898 958.162 317.852 1002.03 299.314 1049.51C284.047 1088.62 276.553 1131.93 276.402 1181.92V1181.92Z" fill="url(#paint80_linear_15_4142)"/>
<path d="M260.115 1181.8H259.534C259.755 1130.45 266.882 1086.85 281.329 1048.52C300.007 998.955 329.384 955.177 355.66 918.882C364.102 907.222 373.208 895.084 383.501 881.789C385.078 879.753 386.788 877.693 388.435 875.703C394.189 868.773 400.136 861.605 402.65 852.959C409.213 830.383 393.858 806.836 377.979 786.78C343.121 742.764 298.348 705.823 255.048 670.103C240.746 658.303 225.955 646.102 211.887 633.93C188.91 614.049 162.424 590.746 138.912 565.692C118.92 544.396 98.7996 521.361 91.5789 494.544C86.2085 474.593 90.584 453.896 104.967 431.269C117.023 412.306 134.24 395.206 149.49 380.986C169.79 362.053 191.86 344.452 213.196 327.433C237.016 308.43 261.652 288.787 283.913 267.218C301.228 250.438 318.428 231.889 326.928 209.918C336.197 185.975 328.587 168.241 318.951 145.793L318.562 144.886C292.646 84.4846 275.878 24.514 268.709 -33.356C263.06 -78.9783 263.193 -125.607 263.321 -170.706C263.351 -181.121 263.38 -191.891 263.339 -202.48H263.921C263.961 -191.891 263.932 -181.121 263.903 -170.706C263.775 -125.63 263.641 -79.019 269.285 -33.4317C276.442 24.3802 293.199 84.2984 319.091 144.647L319.481 145.555C329.169 168.124 336.82 185.958 327.47 210.122C318.928 232.197 301.682 250.804 284.314 267.631C262.036 289.218 237.389 308.872 213.556 327.881C192.226 344.894 170.168 362.483 149.885 381.405C134.659 395.602 117.477 412.661 105.456 431.571C91.1658 454.053 86.8078 474.598 92.1375 494.381C99.3233 521.064 119.391 544.035 139.331 565.278C162.826 590.309 189.299 613.601 212.265 633.476C226.334 645.648 241.118 657.844 255.414 669.638C298.738 705.38 343.534 742.333 378.427 786.402C394.405 806.574 409.841 830.279 403.202 853.104C400.654 861.867 394.667 869.082 388.877 876.058C387.231 878.042 385.526 880.096 383.955 882.127C373.668 895.422 364.562 907.553 356.125 919.208C329.873 955.468 300.524 999.211 281.871 1048.71C267.453 1086.97 260.337 1130.5 260.115 1181.78V1181.8Z" fill="url(#paint81_linear_15_4142)"/>
<path d="M242.94 1181.78H242.358C242.829 1128.69 249.59 1086.2 263.63 1048.04C282.488 996.802 313.616 951.238 338.624 917.276C346.38 906.745 354.776 895.759 364.283 883.698C366.145 881.335 368.216 878.938 370.218 876.617C376.182 869.704 382.349 862.559 385.259 854.158C392.974 831.861 378.015 808.262 362.363 788.124C328.157 744.102 283.483 707.394 240.275 671.895C227.102 661.073 213.481 649.878 200.512 638.754C171.757 614.089 143.87 589.71 118.775 562.561C100.063 542.319 81.2051 520.395 74.1473 494.573C68.5907 474.249 73.4666 453.169 89.0541 430.122C102.163 410.735 120.177 393.908 136.032 379.653C155.111 362.506 175.638 346.389 195.491 330.808C219.852 311.682 245.046 291.906 267.75 270.063C284.059 254.377 300.333 237.026 308.932 216.476C319.004 192.416 311.015 174.187 301.776 153.072C275.145 92.258 258.155 32.1071 251.278 -25.7164C246.076 -69.4244 246.46 -114.063 246.827 -157.236C246.931 -169.362 247.036 -181.9 247.019 -194.224H247.6C247.618 -181.895 247.513 -169.356 247.408 -157.23C247.042 -114.081 246.658 -69.4593 251.854 -25.7862C258.725 31.9791 275.698 92.0776 302.305 152.839C311.603 174.071 319.633 192.411 309.468 216.697C300.827 237.346 284.507 254.749 268.151 270.482C245.43 292.342 220.225 312.131 195.851 331.267C176.011 346.843 155.489 362.954 136.428 380.09C110.35 403.532 61.8006 447.176 74.7175 494.422C81.7404 520.122 100.551 541.982 119.211 562.172C144.283 589.297 172.159 613.67 200.896 638.317C213.859 649.436 227.48 660.625 240.647 671.447C283.878 706.969 328.575 743.695 362.823 787.769C378.567 808.035 393.614 831.792 385.805 854.35C382.861 862.862 376.653 870.048 370.654 877.001C368.566 879.421 366.587 881.708 364.737 884.058C355.23 896.114 346.84 907.094 339.089 917.625C314.099 951.558 283.006 997.076 264.171 1048.24C250.149 1086.34 243.405 1128.77 242.934 1181.78H242.94Z" fill="url(#paint82_linear_15_4142)"/>
<path d="M225.316 1182.14L224.734 1182.13C226.014 1129.11 233.31 1086.49 247.705 1048.02C266.522 997.739 296.335 953.49 320.203 920.552C327.976 909.828 336.163 898.948 345.228 887.282C347.101 884.867 349.097 882.522 351.209 880.038C357.883 872.183 364.789 864.061 367.285 854.239C372.888 832.199 358.662 809.606 344.006 790.405C309.729 745.499 264.753 707.987 221.254 671.715C207.494 660.241 193.262 648.371 179.74 636.56C155.617 615.486 130.086 592.811 106.515 567.641C86.3018 546.066 65.9083 522.711 57.9661 495.411C52.3106 475.966 56.8664 455.642 71.9012 433.288C84.4806 414.576 101.831 398.266 117.099 384.454C136.707 366.713 157.886 350.136 178.373 334.112C202.734 315.051 227.928 295.339 250.62 273.543C265.824 258.938 281.12 242.757 290.017 223.429C301.159 199.206 294.939 182.455 283.855 157.128C257.684 97.3084 240.688 37.8208 233.339 -19.6944C227.917 -62.1165 227.492 -105.51 227.079 -147.479C226.962 -159.47 226.84 -171.875 226.602 -184.065L227.183 -184.077C227.422 -171.881 227.544 -159.476 227.66 -147.484C228.074 -105.539 228.498 -62.1631 233.915 -19.77C241.258 37.6869 258.242 97.1222 284.39 156.889C295.532 182.362 301.793 199.218 290.546 223.667C281.609 243.089 266.272 259.317 251.021 273.962C228.306 295.775 203.107 315.499 178.728 334.572C158.253 350.596 137.079 367.161 117.483 384.89C92.2832 407.687 45.3867 450.126 58.513 495.26C66.4203 522.437 86.7673 545.729 106.928 567.251C130.481 592.398 156.001 615.067 180.112 636.13C193.629 647.935 207.86 659.799 221.615 671.279C265.137 707.574 310.143 745.103 344.46 790.062C359.198 809.373 373.499 832.112 367.838 854.39C365.307 864.34 358.36 872.515 351.645 880.422C349.539 882.901 347.549 885.245 345.681 887.643C336.616 899.303 328.436 910.177 320.668 920.895C296.818 953.798 267.034 998.007 248.24 1048.23C233.869 1086.63 226.578 1129.19 225.304 1182.14H225.316Z" fill="url(#paint83_linear_15_4142)"/>
<path d="M207.459 1182.97L206.877 1182.95C208.949 1130.26 216.78 1087.76 231.507 1049.19C246.152 1010.86 267.732 972.76 301.485 925.666C309.834 914.018 318.783 902.032 328.855 889.027C330.379 887.055 332.008 885.077 333.585 883.157C340.201 875.116 347.037 866.801 348.87 856.27C352.821 833.607 337.926 810.508 322.838 790.9C289.08 747.04 245.64 709.901 203.636 673.978C190.516 662.76 176.947 651.159 163.995 639.615C138.639 617.016 111.897 592.753 88.1231 566.471C70.3362 546.811 50.6816 523.613 42.5823 497.104C36.729 477.95 41.2906 457.783 56.5233 435.44C68.934 417.246 85.6619 401.513 101.732 386.967C120.898 369.611 141.554 353.348 161.534 337.621C184.668 319.409 208.594 300.575 230.314 279.949C246.338 264.739 262.443 247.86 271.741 227.565C283.174 202.593 275.639 185.295 265.219 161.352L265.062 160.991C239.903 103.214 223.122 45.4371 215.186 -10.7398C209.17 -53.2899 207.989 -96.8758 206.848 -139.024C206.528 -150.9 206.196 -163.177 205.76 -175.25L206.342 -175.273C206.778 -163.2 207.11 -150.923 207.43 -139.042C208.57 -96.9049 209.752 -53.3365 215.762 -10.8213C223.692 45.3033 240.461 103.028 265.597 160.758L265.754 161.119C276.239 185.202 283.814 202.599 272.276 227.804C262.944 248.192 246.786 265.118 230.721 280.368C208.984 301.006 185.047 319.852 161.901 338.075C141.932 353.796 121.282 370.053 102.128 387.398C76.8179 410.316 29.7061 452.971 43.1467 496.935C51.211 523.328 70.8191 546.462 88.5653 566.081C112.322 592.34 139.052 616.591 164.397 639.184C177.343 650.722 190.912 662.324 204.032 673.542C246.064 709.477 289.522 746.639 323.321 790.551C338.49 810.258 353.455 833.485 349.464 856.374C347.602 867.057 340.713 875.436 334.051 883.529C332.474 885.443 330.85 887.422 329.332 889.382C319.266 902.381 310.317 914.361 301.974 926.004C268.256 973.057 246.693 1011.12 232.071 1049.4C217.362 1087.91 209.548 1130.35 207.477 1182.97H207.459Z" fill="url(#paint84_linear_15_4142)"/>
<path d="M189.399 1184.22L188.817 1184.19C191.668 1132.09 200.006 1089.9 215.052 1051.44C229.947 1013.36 251.528 975.553 285.019 928.843C293.298 917.299 302.154 905.43 312.103 892.571C313.302 891.017 314.547 889.475 315.746 887.986C321.966 880.265 327.836 872.969 329.541 862.914C333.666 838.576 317.736 813.865 301.613 792.901C268.512 749.874 226.578 712.933 186.03 677.213C173.666 666.321 160.877 655.057 148.629 643.862C123.72 621.101 97.4267 596.652 73.8388 570.003C56.1973 550.069 36.6707 526.551 28.2397 499.641C22.2875 480.65 26.7328 460.605 41.8258 438.361C54.0503 420.342 70.7026 404.574 86.7091 389.969C105.159 373.137 125.018 357.299 144.224 341.979C167.265 323.599 191.092 304.596 212.637 283.754C228.434 268.475 244.255 251.596 253.023 231.72C263.234 208.563 257.719 192.62 247.42 169.114C221.702 110.411 204.364 51.4475 195.88 -6.12579C189.759 -47.6403 187.944 -90.1962 186.193 -131.356C185.686 -143.249 185.163 -155.543 184.54 -167.628L185.122 -167.657C185.745 -155.572 186.268 -143.272 186.774 -131.379C188.532 -90.237 190.347 -47.6926 196.456 -6.20724C204.934 51.3195 222.261 110.231 247.955 168.887C258.015 191.852 263.839 208.65 253.558 231.964C244.755 251.933 228.882 268.865 213.045 284.185C191.482 305.049 167.643 324.064 144.591 342.45C125.396 357.764 105.543 373.596 87.1048 390.417C71.1215 404.998 54.4983 420.737 42.3087 438.705C27.3263 460.791 22.9043 480.673 28.7983 499.484C37.1943 526.283 56.6744 549.743 74.281 569.631C97.8514 596.256 124.127 620.699 149.03 643.449C161.278 654.644 174.061 665.902 186.425 676.795C226.997 712.537 268.948 749.49 302.084 792.564C318.288 813.626 334.295 838.471 330.129 863.031C328.401 873.236 322.478 880.585 316.211 888.364C315.013 889.854 313.773 891.396 312.575 892.943C302.631 905.796 293.775 917.66 285.501 929.198C252.04 975.867 230.482 1013.63 215.605 1051.66C200.582 1090.07 192.255 1132.2 189.404 1184.24L189.399 1184.22Z" fill="url(#paint85_linear_15_4142)"/>
<path d="M171.146 1185.87L170.564 1185.83C174.119 1135.22 183.208 1092.3 198.336 1054.63C213.452 1016.99 234.998 979.615 268.198 933.463C276.39 922.07 285.147 910.369 294.962 897.691C295.852 896.539 296.754 895.41 297.639 894.287C303.254 887.212 308.56 880.533 310.16 871.223C314.244 847.484 300.141 823.727 285.81 803.619C253.238 757.915 210.275 718.99 168.726 681.345C155.675 669.521 142.182 657.291 129.335 645.136C107.545 624.522 82.4268 600.352 60.0316 574.402C42.652 554.258 23.3697 530.455 14.5432 502.864C0.718605 459.639 45.375 418.171 72.0583 393.391C89.8162 376.895 108.93 361.325 127.415 346.267C150.118 327.77 173.596 308.645 194.74 287.664C208.361 274.148 223.5 257.746 232.472 238.556C242.695 216.697 239.152 200.044 229.028 176.968C203.339 118.394 185.692 59.3548 176.569 1.48472C169.965 -40.4079 167.521 -83.4003 165.153 -124.979C164.478 -136.837 163.78 -149.102 162.995 -161.158L163.576 -161.193C164.362 -149.137 165.06 -136.872 165.735 -125.014C168.097 -83.4527 170.547 -40.4719 177.145 1.39164C186.257 59.2093 203.892 118.202 229.557 176.73C239.745 199.963 243.312 216.737 232.996 238.801C223.983 258.071 208.803 274.52 195.141 288.071C173.98 309.07 150.491 328.207 127.776 346.709C109.296 361.762 90.1943 377.332 72.4481 393.81C45.8696 418.491 1.38189 459.808 15.0901 502.678C23.8817 530.17 43.1233 553.915 60.468 574.012C82.8457 599.945 107.952 624.097 129.731 644.706C142.572 656.855 156.065 669.079 169.115 680.902C210.688 718.571 253.675 757.514 286.281 803.27C300.682 823.477 314.85 847.362 310.73 871.31C309.107 880.765 303.754 887.503 298.087 894.636C297.197 895.754 296.3 896.882 295.41 898.034C285.595 910.713 276.844 922.408 268.657 933.794C235.486 979.911 213.964 1017.24 198.865 1054.84C183.755 1092.46 174.684 1135.32 171.135 1185.86L171.146 1185.87Z" fill="url(#paint86_linear_15_4142)"/>
<path d="M152.719 1187.86L152.137 1187.81C156.385 1138.41 165.863 1096.36 181.107 1059.23C196.049 1022.86 217.95 985.177 250.032 940.654C258.044 929.541 266.592 918.125 276.163 905.761C276.861 904.859 277.559 903.975 278.246 903.096C284.157 895.573 289.266 889.074 290.628 879.299C294.025 854.885 280.096 830.727 266.021 810.322C234.328 764.373 192.389 724.901 151.835 686.727C139.238 674.869 126.205 662.603 113.794 650.425C92.6264 629.654 68.2123 605.304 46.3816 579.115C28.1816 557.284 10.1677 533.399 1.6379 506.664C-12.0529 463.77 31.3119 422.372 57.2213 397.638C74.3275 381.306 92.7545 365.846 110.576 350.893C132.518 332.483 155.204 313.445 175.597 292.645C188.759 279.216 203.398 262.918 212.178 243.834C222.308 221.828 218.729 204.734 209.14 182.641C183.912 124.544 166.27 65.6852 156.711 7.68134C149.769 -34.415 146.773 -77.6342 143.869 -119.434C143.043 -131.321 142.188 -143.615 141.257 -155.694L141.839 -155.741C142.77 -143.662 143.625 -131.367 144.451 -119.475C147.355 -77.6866 150.351 -34.479 157.287 7.58826C166.84 65.5398 184.465 124.358 209.676 182.409C219.334 204.652 222.93 221.869 212.707 244.072C203.886 263.238 189.207 279.582 176.01 293.046C155.599 313.87 132.902 332.914 110.949 351.335C93.1327 366.283 74.7115 381.736 57.6228 398.057C31.8123 422.698 -11.3837 463.939 2.19066 506.483C10.6914 533.126 28.6703 556.958 46.8296 578.742C68.6429 604.908 93.0454 629.246 114.201 650.006C126.612 662.184 139.639 674.444 152.236 686.302C192.814 724.494 234.776 763.989 266.504 809.99C280.643 830.488 294.636 854.763 291.209 879.375C289.825 889.307 284.425 896.178 278.711 903.451C278.025 904.324 277.327 905.208 276.628 906.11C267.057 918.469 258.516 929.879 250.509 940.986C218.456 985.468 196.578 1023.11 181.654 1059.44C166.433 1096.51 156.967 1138.5 152.725 1187.84L152.719 1187.86Z" fill="url(#paint87_linear_15_4142)"/>
<path d="M134.141 1190.18L133.559 1190.12C138.371 1142.9 147.89 1102.59 162.651 1066.87C178 1029.74 199.528 992.939 232.449 947.543C235.812 942.906 239.926 938.083 244.272 932.974C256.118 919.068 269.553 903.312 270.886 886.91C272.957 861.495 258.754 836.685 244.592 815.773C214.255 770.983 174.585 731.918 136.224 694.145C125.384 683.468 114.172 672.431 103.42 661.498C80.1925 637.881 55.6678 612.518 33.5985 585.026C15.7243 562.759 -1.98698 538.403 -10.5459 511.167C-24.0912 468.047 17.9702 426.561 43.1058 401.775C59.3567 385.745 76.8644 370.495 93.7902 355.745C114.294 337.877 135.502 319.404 154.628 299.411C167.609 285.843 182.061 269.423 190.783 250.542C201.443 227.461 198.184 210.285 189.463 190.048C164.414 131.911 146.621 72.743 136.579 14.1922C129.259 -28.486 125.739 -72.322 122.335 -114.715C121.375 -126.643 120.386 -138.984 119.327 -151.104L119.909 -151.156C120.968 -139.03 121.957 -126.695 122.917 -114.762C126.321 -72.3802 129.841 -28.5558 137.155 14.0932C147.186 72.5975 164.967 131.719 189.998 189.815C198.778 210.197 202.059 227.507 191.307 250.781C182.55 269.743 168.062 286.204 155.047 299.807C135.904 319.817 114.684 338.302 94.1683 356.176C77.2484 370.92 59.7523 386.164 43.5073 402.182C18.4706 426.876 -23.4395 468.204 -9.99895 510.987C-1.46914 538.13 16.2072 562.433 34.0407 584.66C56.0925 612.134 80.6056 637.479 103.821 661.091C114.568 672.018 125.78 683.055 136.62 693.732C174.998 731.528 214.691 770.611 245.063 815.453C259.284 836.452 273.545 861.367 271.456 886.962C270.106 903.55 256.613 919.382 244.703 933.358C240.362 938.455 236.254 943.267 232.909 947.887C200.017 993.241 178.506 1030.01 163.175 1067.09C148.431 1102.76 138.929 1143.01 134.123 1190.17L134.141 1190.18Z" fill="url(#paint88_linear_15_4142)"/>
<path d="M115.429 1192.77L114.853 1192.7C120.258 1146.85 130.091 1107.59 144.911 1072.64C160.045 1036.96 181.852 1000.06 213.551 956.533C216.809 952.058 220.719 947.462 224.856 942.592C236.499 928.901 249.695 913.383 250.626 896.853C252.11 870.548 238.279 845.36 224.548 824.187C195.281 779.042 156.786 739.331 119.56 700.929C109.197 690.235 98.4798 679.18 88.1928 668.241C84.085 663.872 79.9656 659.537 75.6076 654.952C37.4096 614.776 -5.88531 569.241 -22.2409 516.066C-35.5127 472.911 4.74495 431.426 28.7925 406.639C44.2521 390.708 60.9393 375.499 77.0738 360.79C96.6994 342.904 116.988 324.413 135.212 304.485C147.599 290.946 161.389 274.555 169.744 255.668C180.025 232.441 176.65 214.73 168.429 195.285C143.974 137.467 126.338 78.2821 116.005 19.3822C108.458 -23.6625 104.502 -67.8826 100.679 -110.648C99.6028 -122.669 98.4914 -135.097 97.3219 -147.304L97.9038 -147.362C99.0791 -135.149 100.19 -122.721 101.261 -110.7C105.084 -67.9524 109.034 -23.744 116.581 19.2775C126.903 78.1367 144.527 137.275 168.964 195.058C177.25 214.643 180.642 232.488 170.279 255.901C161.889 274.863 148.059 291.295 135.642 304.875C117.396 324.82 97.095 343.323 77.4637 361.215C61.335 375.918 44.6535 391.116 29.2056 407.035C5.24533 431.728 -34.8553 473.056 -21.6882 515.886C-5.37912 568.933 37.8692 614.415 76.0207 654.545C80.3787 659.13 84.4981 663.459 88.6118 667.834C98.8929 678.773 109.61 689.822 119.973 700.516C157.217 738.941 195.735 778.675 225.031 823.867C238.809 845.122 252.691 870.414 251.196 896.882C250.253 913.61 236.987 929.204 225.287 942.964C221.156 947.823 217.251 952.413 214.01 956.87C182.341 1000.36 160.557 1037.22 145.44 1072.86C130.644 1107.75 120.823 1146.97 115.423 1192.76L115.429 1192.77Z" fill="url(#paint89_linear_15_4142)"/>
<path d="M96.5946 1195.6L96.0186 1195.52C101.971 1151.11 112.072 1112.94 126.903 1078.82C141.984 1044.13 163.384 1008.31 194.251 966.087C197.422 961.752 201.134 957.371 205.062 952.727C216.431 939.304 229.313 924.089 229.965 907.396C231.024 880.154 216.803 853.413 204.294 833.165C176.185 787.653 138.959 747.261 102.954 708.196C93.092 697.496 82.8923 686.43 73.0998 675.485C69.4575 671.418 65.7453 667.31 61.9983 663.15C24.7836 621.938 -17.3942 575.234 -33.4588 521.402C-45.3924 481.412 -14.8631 443.831 14.6886 411.742C29.2987 395.881 45.1132 380.683 60.4098 365.986C79.087 348.042 98.3983 329.481 115.656 309.576C127.397 296.031 140.466 279.635 148.396 260.707C156.908 240.378 156.495 223.446 146.93 200.312C123.127 142.75 105.718 83.478 95.1749 24.1417C87.4539 -19.3336 83.125 -63.9842 78.9415 -107.163C77.7662 -119.294 76.5502 -131.845 75.2759 -144.174L75.8519 -144.232C77.1262 -131.903 78.3422 -119.352 79.5175 -107.221C83.701 -64.054 88.0241 -19.4151 95.7451 24.0369C106.276 83.3325 123.679 142.564 147.465 200.085C157.1 223.382 157.508 240.436 148.931 260.928C140.978 279.931 127.869 296.374 116.098 309.954C98.8231 329.883 79.5001 348.449 60.8171 366.405C45.5263 381.096 29.7235 396.282 15.125 412.132C-14.322 444.104 -44.7408 481.534 -32.8945 521.227C-16.8647 574.931 25.2666 621.589 62.4405 662.755C66.1934 666.909 69.9055 671.023 73.5478 675.09C83.3402 686.028 93.5341 697.095 103.396 707.795C139.418 746.877 176.662 787.298 204.806 832.85C217.356 853.174 231.629 880.009 230.564 907.414C229.906 924.31 216.955 939.601 205.521 953.1C201.6 957.731 197.893 962.107 194.734 966.424C163.891 1008.61 142.514 1044.4 127.45 1079.05C112.642 1113.12 102.553 1151.24 96.6063 1195.59L96.5946 1195.6Z" fill="url(#paint90_linear_15_4142)"/>
<path d="M77.6673 1198.63L77.0913 1198.54C88.5768 1121.92 119.275 1051.57 173.706 977.142C176.877 972.807 180.589 968.437 184.523 963.818C196.119 950.173 208.111 936.069 208.867 919.976C210.164 892.164 196.898 865.207 185.192 844.831C158.043 797.568 121.189 755.442 85.5454 714.702C75.0257 702.681 64.1511 690.247 53.7594 677.941C51.2516 674.968 48.7148 671.977 46.1547 668.963C10.9532 627.466 -28.9496 580.43 -44.3451 526.988C-55.8598 487.032 -27.2913 449.515 0.386945 417.491C14.2057 401.501 29.2522 386.147 43.7982 371.298C61.9866 352.731 80.7976 333.536 97.3627 312.939C107.498 300.342 120.06 283.411 127.345 264.146C135.933 241.425 132.326 223.359 124.267 203.221C101.476 146.276 84.568 87.2192 74.0017 27.6851C66.2515 -15.9706 61.6492 -60.7899 57.1981 -104.131C55.9355 -116.414 54.6322 -129.116 53.2765 -141.596L53.8525 -141.66C55.2082 -129.18 56.5115 -116.478 57.7741 -104.195C62.2252 -60.8655 66.8276 -16.0578 74.5719 27.5745C85.1323 87.0679 102.035 146.09 124.808 202.994C132.919 223.26 136.544 241.442 127.892 264.338C120.578 283.684 107.981 300.662 97.8223 313.288C81.234 333.909 62.4172 353.115 44.2172 371.688C29.6769 386.531 14.6363 401.88 0.829151 417.851C-26.7502 449.765 -55.2197 487.131 -43.7865 526.807C-28.4201 580.133 11.4361 627.117 46.5969 668.567C49.1512 671.581 51.6938 674.572 54.2016 677.545C64.5933 689.845 75.4679 702.279 85.9818 714.3C121.643 755.064 158.514 797.213 185.692 844.522C197.434 864.968 210.752 892.024 209.443 919.987C208.681 936.279 196.625 950.464 184.965 964.178C181.043 968.792 177.337 973.15 174.178 977.473C119.804 1051.82 89.1354 1122.09 77.6673 1198.62V1198.63Z" fill="url(#paint91_linear_15_4142)"/>
<path d="M58.6585 1201.83L58.0824 1201.74C64.2674 1162.98 75.6192 1126.01 92.7719 1088.73C108.197 1055.21 128.107 1021.97 153.65 987.103C156.728 982.908 160.242 978.742 163.966 974.331C175.231 960.996 186.873 947.206 187.42 931.025C188.386 902.433 175.626 875.022 164.408 854.338C138.598 806.755 103.245 763.937 69.0619 722.527C59.1182 710.483 48.837 698.026 38.998 685.703C36.9848 683.183 34.96 680.652 32.9236 678.104C-1.2073 635.455 -39.8998 587.121 -54.8299 532.951C-59.892 514.583 -57.4889 495.271 -47.4812 473.918C-38.8932 455.59 -26.0113 438.571 -13.3736 422.902C-0.491635 406.936 13.5948 391.535 27.2157 376.639C44.3277 357.933 62.0157 338.587 77.4811 317.949C86.9535 305.3 98.6951 288.298 105.444 268.917C113.422 245.992 109.861 227.903 102.058 207.8C80.0296 151.03 63.4703 91.7867 52.8401 31.7173C45.0259 -12.433 40.1792 -57.741 35.4837 -101.554C34.1513 -113.988 32.7781 -126.841 31.3526 -139.478L31.9286 -139.542C33.3541 -126.905 34.7273 -114.052 36.0597 -101.618C40.7494 -57.8167 45.6019 -12.5202 53.4103 31.6125C64.0347 91.6412 80.5823 150.85 102.599 207.585C110.448 227.81 114.027 246.01 105.991 269.103C99.2187 288.56 87.4422 305.614 77.9466 318.292C62.4695 338.953 44.7641 358.311 27.6463 377.029C14.0312 391.913 -0.049434 407.308 -12.9198 423.262C-38.7187 455.241 -65.3205 492.711 -54.2714 532.794C-39.3704 586.847 -0.724373 635.135 33.3716 677.737C35.4081 680.286 37.4387 682.817 39.446 685.336C49.2792 697.654 59.5604 710.111 69.4982 722.155C103.699 763.588 139.069 806.429 164.909 854.065C176.167 874.813 188.962 902.317 187.99 931.048C187.438 947.427 175.725 961.298 164.403 974.71C160.685 979.108 157.176 983.263 154.116 987.446C100.691 1060.37 70.3594 1128.5 58.6527 1201.83H58.6585Z" fill="url(#paint92_linear_15_4142)"/>
<path d="M39.5857 1205.16L39.0097 1205.06C45.5496 1167.57 56.942 1131.76 73.8329 1095.59C89.0307 1063.04 108.447 1030.78 133.187 996.971C136.166 992.904 139.482 988.93 143.002 984.723C153.894 971.696 165.159 958.226 165.537 941.969C166.224 912.627 154.034 884.786 143.328 863.81C118.914 815.954 85.1556 772.461 52.5142 730.4C43.1582 718.344 33.4822 705.881 24.2251 693.557C22.7123 691.544 21.1878 689.519 19.6634 687.495C-13.3096 643.676 -50.6872 594.016 -65.0645 539.136C-69.8764 520.762 -67.8341 501.497 -58.8155 480.242C-50.8094 461.373 -38.2532 443.458 -27.0237 428.418C-15.1017 412.452 -2.00441 396.981 10.6623 382.021C26.6513 363.135 43.1873 343.608 57.5006 322.906C70.5572 304.02 78.5342 288.799 83.3461 273.554C90.654 250.438 87.128 232.296 79.5874 212.17C36.7288 97.7855 22.945 -21.917 9.60917 -137.686L10.1852 -137.75C23.5152 -22.0217 37.299 97.6458 80.1343 211.966C87.7157 232.203 91.2591 250.455 83.9046 273.735C79.0695 289.031 71.075 304.305 57.9835 323.238C43.6528 343.963 27.111 363.507 11.1103 382.4C-1.5506 397.353 -14.642 412.813 -26.5524 428.767C-52.2465 463.177 -74.6242 500.345 -64.5001 538.991C-50.1519 593.76 -12.8151 643.373 20.1289 687.145C21.6533 689.176 23.1777 691.195 24.6905 693.208C33.9476 705.526 43.6179 717.989 52.9739 730.045C85.6327 772.124 119.403 815.64 143.846 863.548C154.575 884.582 166.806 912.511 166.113 941.987C165.729 958.447 154.395 971.998 143.439 985.101C139.93 989.302 136.614 993.264 133.646 997.32C81.8566 1068.11 51.9615 1134.15 39.574 1205.17L39.5857 1205.16Z" fill="url(#paint93_linear_15_4142)"/>
<path d="M20.4663 1208.58L19.8961 1208.47C26.7211 1172.18 38.0845 1137.47 54.6263 1102.35C69.5506 1070.68 88.4139 1039.33 112.293 1006.52C118.763 997.628 125.576 988.563 133.129 978.812C139.814 970.177 143.026 964.044 143.2 952.547C143.672 922.158 131.29 892.606 121.934 873.021C98.9627 824.943 66.8916 780.822 35.8793 738.161C27.1226 726.117 18.0691 713.66 9.39968 701.36L6.31592 696.99C-26.9597 649.803 -61.3698 601.015 -75.1188 545.449C-84.8705 506.035 -64.2849 468.576 -40.5806 433.968C-29.6536 418.008 -17.5745 402.479 -5.89695 387.456C8.96331 368.342 24.3298 348.583 37.4736 327.759C44.9445 315.924 55.5223 297.893 61.1371 278.029C66.7752 258.083 65.5592 240.203 56.9537 216.313C38.5093 165.099 23.9399 108.305 12.4078 42.6792C2.1092 -15.9589 -4.94274 -76.5229 -11.7619 -135.097L-11.8783 -136.121L-11.3023 -136.191L-11.1859 -135.167C3.01688 -13.1486 16.4284 102.097 57.4948 216.115C66.1468 240.133 67.3628 258.118 61.6899 278.186C56.0576 298.126 45.4507 316.203 37.9565 328.073C24.7952 348.92 9.42297 368.697 -5.44311 387.817C-17.1149 402.828 -29.1881 418.357 -40.1035 434.3C-63.7263 468.797 -84.2537 506.117 -74.5544 545.31C-60.8345 600.765 -26.4535 649.512 6.79304 696.653L9.8768 701.022C18.5463 713.323 27.5997 725.774 36.3506 737.818C67.3803 780.502 99.4689 824.641 122.463 872.771C131.843 892.408 144.259 922.041 143.788 952.553C143.608 964.213 140.355 970.421 133.594 979.161C126.048 988.912 119.24 997.972 112.77 1006.86C62.7605 1075.58 33.4298 1139.67 20.4721 1208.58L20.4663 1208.58Z" fill="url(#paint94_linear_15_4142)"/>
<path d="M1.32372 1212.05L0.753508 1211.94C7.75889 1176.93 18.9244 1143.42 34.8902 1109.48C49.3839 1078.66 67.4909 1048.27 90.2467 1016.56C96.3386 1008.07 102.785 999.374 109.971 989.96C117.692 979.841 120.415 973.878 120.34 960.542C120.194 935.563 113.142 909.444 97.4849 875.849C76.1021 829.976 47.138 787.176 19.1223 745.784C11.7154 734.833 4.05255 723.516 -3.32521 712.345L-6.21115 707.981C-38.5267 659.101 -71.9361 608.556 -85.0625 551.739C-94.2847 511.819 -75.7762 474.435 -54.4052 439.985C-44.3567 423.786 -33.1679 407.96 -22.3457 392.658C-8.91672 373.66 4.97186 354.017 16.7833 333.507C28.5423 313.091 35.4546 296.84 39.1842 280.81C44.4906 258.002 40.9647 240.011 33.552 218.372C16.184 167.688 2.33612 111.505 -8.78871 46.6066C-18.7208 -11.3449 -25.668 -71.0652 -32.3824 -128.813L-33.0632 -134.672L-32.4872 -134.742L-31.8064 -128.883C-17.8014 -8.44151 -4.57035 105.32 34.0989 218.18C41.5465 239.912 45.0899 257.996 39.7486 280.938C36.0074 297.02 29.0776 313.323 17.2895 333.792C5.46061 354.326 -8.4338 373.98 -21.8744 392.989C-32.6908 408.286 -43.8738 424.106 -53.9106 440.287C-75.2177 474.633 -93.6679 511.889 -84.4923 551.605C-71.3892 608.317 -38.0089 658.821 -5.7224 707.661L-2.83647 712.025C4.5413 723.197 12.2042 734.513 19.611 745.458C47.6326 786.868 76.6141 829.685 98.0143 875.604C113.712 909.281 120.776 935.476 120.921 960.542C121.003 974.046 118.245 980.086 110.431 990.315C103.251 999.723 96.8041 1008.42 90.718 1016.9C43.7459 1082.36 14.5083 1146.19 1.32953 1212.05L1.32372 1212.05Z" fill="url(#paint95_linear_15_4142)"/>
<path d="M-17.8247 1215.54L-18.3949 1215.42C-4.86128 1150.94 23.5676 1088.59 68.5091 1024.81C74.3275 1016.54 80.466 1008.11 87.2735 999.031C95.14 988.534 97.1415 982.495 97.031 969.659C96.7401 936.686 84.6145 904.923 75.5203 883.901C55.6387 837.947 28.4318 794.699 2.12084 752.87C-4.72163 741.996 -11.7968 730.743 -18.616 719.647L-20.1346 717.174C-50.8792 667.159 -82.6711 615.445 -95.0294 558.099C-103.798 517.416 -86.2436 478.34 -67.9156 445.483C-58.8563 429.244 -48.6973 413.29 -38.8758 397.865C-26.6222 378.623 -13.9497 358.724 -3.30776 338.098C7.17705 317.786 13.5424 300.878 16.7367 284.894C21.31 262.028 17.6444 243.24 10.7379 222.067C-26.0054 109.44 -39.6264 -9.02918 -52.7935 -123.594L-53.899 -133.229L-53.3229 -133.293L-52.2116 -123.658C-39.0445 -9.12227 -25.4294 109.312 11.2965 221.887C18.2321 243.147 21.9093 262.016 17.3127 285.005C14.1068 301.041 7.71817 317.995 -2.78991 338.36C-13.4435 359.01 -26.1218 378.92 -38.387 398.173C-48.2085 413.592 -58.3617 429.535 -67.4094 445.762C-85.685 478.526 -103.193 517.474 -94.465 557.971C-82.1242 615.218 -50.3614 666.892 -19.6459 716.866L-18.1273 719.339C-11.3081 730.435 -4.23289 741.682 2.60376 752.556C28.9263 794.402 56.1449 837.668 76.044 883.663C85.1614 904.731 97.3103 936.564 97.6071 969.648C97.7234 982.64 95.6928 988.749 87.7332 999.374C80.9314 1008.46 74.7988 1016.88 68.9804 1025.14C24.0854 1088.86 -4.31434 1151.14 -17.8305 1215.53L-17.8247 1215.54Z" fill="url(#paint96_linear_15_4142)"/>
<path d="M-36.9675 1219.01L-37.5377 1218.89C-23.7829 1155.45 3.65095 1094.36 46.3233 1032.11C51.8799 1024 57.6867 1015.81 64.0753 1007.04C71.3891 997.012 73.3557 990.704 73.216 977.718C72.9484 952.227 66.5656 924.706 53.1425 891.11C34.7853 845.174 9.41116 801.576 -15.1251 759.41C-21.3974 748.629 -27.8849 737.481 -34.1456 726.49L-34.4074 726.036C-63.4704 674.967 -93.5284 622.16 -105.066 564.383C-113.334 522.984 -97.7701 483.786 -81.4552 450.894C-73.3734 434.597 -64.2385 418.515 -55.4061 402.956C-44.3336 383.453 -32.883 363.286 -23.4048 342.538C-14.0371 322.033 -8.41068 304.956 -5.70511 288.787C-1.85331 265.816 -5.45489 246.894 -12.0472 225.558C-46.7366 113.268 -59.9852 -4.49662 -72.7974 -118.386L-74.2927 -131.658L-73.7167 -131.722L-72.2213 -118.45C-59.415 -4.58972 -46.1664 113.152 -11.4944 225.389C-4.87886 246.807 -1.26562 265.798 -5.13488 288.886C-7.85208 305.102 -13.4901 322.225 -22.8811 342.776C-32.3709 363.554 -43.8274 383.732 -54.9057 403.241C-63.7323 418.788 -72.8614 434.864 -80.9373 451.15C-97.2115 483.949 -112.735 523.037 -104.502 564.266C-92.9815 621.956 -62.9468 674.717 -33.907 725.745L-33.6452 726.199C-27.3904 737.19 -20.9028 748.332 -14.6306 759.114C9.91739 801.297 35.3032 844.912 53.6778 890.889C67.13 924.555 73.5186 952.14 73.7921 977.706C73.9317 990.85 71.9418 997.227 64.535 1007.38C58.1521 1016.14 52.3512 1024.33 46.7946 1032.43C4.16297 1094.62 -23.2418 1155.65 -36.9791 1219L-36.9675 1219.01Z" fill="url(#paint97_linear_15_4142)"/>
<path d="M-56.0751 1222.41L-56.6453 1222.29C-42.8498 1159.66 -16.7774 1099.75 23.0672 1039.16C28.1583 1031.42 33.5636 1023.46 39.5915 1014.84C47.4231 1003.64 49.2501 997.279 48.8893 982.419C48.2959 957.807 42.6927 931.543 31.2421 899.774C14.4384 853.122 -9.44037 808.57 -32.5337 765.479C-38.451 754.441 -44.5662 743.031 -50.4545 731.784C-75.8577 683.259 -104.659 628.263 -115.301 570.393C-118.687 551.989 -118.227 532.957 -113.899 512.197C-110.419 495.527 -104.502 477.868 -95.2622 456.625C-88.0706 440.095 -79.8724 423.699 -71.9477 407.844C-62.231 388.404 -52.1767 368.307 -43.9145 347.803C-35.6815 327.381 -30.73 310.216 -28.3386 293.773C-24.8999 270.191 -28.6295 250.03 -35.2858 227.007C-67.4035 115.91 -80.0586 -0.231715 -92.2948 -112.545C-92.9232 -118.317 -93.5516 -124.094 -94.1858 -129.872L-93.6097 -129.936C-92.9755 -124.158 -92.3471 -118.386 -91.7187 -112.609C-79.4826 -0.318988 -66.8333 115.794 -34.7331 226.844C-28.0593 249.937 -24.3181 270.168 -27.7684 293.855C-30.1714 310.344 -35.1346 327.555 -43.3851 348.019C-51.6589 368.546 -61.7131 388.654 -71.4415 408.1C-79.3662 423.949 -87.5586 440.334 -94.7443 456.852C-108.115 487.597 -122.469 528.273 -114.748 570.282C-104.124 628.065 -75.3457 683.02 -49.9599 731.505C-44.0717 742.752 -37.9565 754.162 -32.045 765.2C-8.94581 808.308 14.9446 852.878 31.7657 899.57C43.2338 931.403 48.8544 957.726 49.4479 982.402C49.8087 997.413 47.9642 1003.85 40.0453 1015.17C34.0174 1023.79 28.6179 1031.74 23.5268 1039.47C-16.277 1100.01 -42.3261 1159.85 -56.1042 1222.41H-56.0751Z" fill="url(#paint98_linear_15_4142)"/>
<path d="M-75.142 1225.72L-75.7123 1225.6C-61.9691 1162.96 -37.2059 1103.52 -0.0145282 1043.88C4.75658 1036.23 9.79535 1028.41 15.3927 1019.98C23.0672 1008.42 24.324 1001.45 24.0098 987.609C23.4454 962.793 18.3252 936.442 7.88108 904.673C-7.35738 858.306 -29.2521 813.65 -50.4196 770.465C-55.7492 759.591 -61.2651 748.349 -66.5715 737.271C-89.723 688.978 -115.964 634.239 -125.693 576.27C-132.907 533.289 -120.526 492.461 -108.935 461.693C-102.681 445.093 -95.4891 428.551 -88.5303 412.557C-79.9597 392.855 -71.0982 372.479 -63.9241 351.83C-48.7322 308.11 -46.8354 270.552 -57.774 230.062C-87.64 119.482 -99.6784 4.23683 -111.315 -107.209C-112.031 -114.058 -112.747 -120.9 -113.462 -127.748L-112.88 -127.807C-112.159 -120.958 -111.449 -114.11 -110.733 -107.267C-99.0965 4.15537 -87.064 119.378 -57.2097 229.91C-46.2419 270.523 -48.1387 308.186 -63.3714 352.022C-70.5513 372.689 -79.4186 393.076 -87.9891 412.789C-94.9421 428.778 -102.134 445.314 -108.383 461.903C-119.95 492.601 -132.302 533.335 -125.111 576.176C-115.394 634.064 -89.1761 688.763 -66.042 737.027C-60.7298 748.105 -55.2198 759.346 -49.8901 770.221C-28.711 813.423 -6.81045 858.091 8.43964 904.499C18.9012 936.32 24.0331 962.729 24.5974 987.603C24.9116 1001.58 23.6432 1008.63 15.8872 1020.31C10.2957 1028.73 5.25696 1036.54 0.491672 1044.19C-36.6648 1103.77 -61.3989 1163.16 -75.1304 1225.72H-75.142Z" fill="url(#paint99_linear_15_4142)"/>
<path d="M-94.1392 1228.89L-94.7094 1228.77C-81.3503 1166.37 -57.4191 1105.22 -23.5792 1047C-19.1572 1039.39 -14.5024 1031.65 -9.35892 1023.36C-2.30699 1011.98 -1.15493 1005 -1.44003 991.03C-1.95787 966.11 -6.58353 939.764 -15.9977 908.123C-29.6652 862.187 -49.5119 817.6 -68.7069 774.48C-73.4605 763.803 -78.3713 752.766 -83.1075 741.891C-102.942 696.362 -127.624 639.702 -136.404 581.844C-141.6 547.625 -137.393 512.057 -122.772 466.522C-117.413 449.847 -111.199 433.16 -105.183 417.019C-97.7408 397.05 -90.0489 376.401 -83.9279 355.606C-71.011 311.723 -69.824 273.88 -80.0761 232.889C-107.574 122.985 -118.873 8.80429 -129.8 -101.624C-130.58 -109.478 -131.354 -117.333 -132.139 -125.188L-131.558 -125.246C-130.772 -117.392 -129.998 -109.537 -129.219 -101.682C-118.297 8.72284 -106.998 122.88 -79.5117 232.744C-69.2305 273.839 -70.4175 311.781 -83.3693 355.769C-89.4961 376.581 -97.1939 397.242 -104.636 417.217C-110.646 433.352 -116.86 450.033 -122.213 466.697C-136.806 512.139 -141.007 547.625 -135.828 581.75C-127.06 639.539 -102.39 696.158 -82.5722 741.653C-77.836 752.521 -72.9252 763.559 -68.1716 774.236C-48.9708 817.373 -29.1125 861.978 -15.4334 907.949C-6.00169 939.642 -1.37022 966.04 -0.852383 991.013C-0.561462 1005.11 -1.72513 1012.16 -8.85853 1023.66C-14.002 1031.96 -18.651 1039.68 -23.073 1047.29C-56.8838 1105.46 -80.7976 1166.55 -94.1392 1228.89Z" fill="url(#paint100_linear_15_4142)"/>
<path d="M-113.055 1231.89L-113.625 1231.77C-100.638 1168.06 -78.4353 1106.33 -47.6325 1048.31C-43.5771 1040.67 -39.3354 1032.96 -34.6633 1024.73C-28.1641 1013.29 -27.2215 1005.84 -27.4659 992.45C-27.9255 967.53 -32.0333 941.3 -40.406 909.91C-52.4967 864.573 -70.2546 820.23 -87.4306 777.349C-91.6082 766.91 -95.9313 756.123 -100.097 745.498C-121.765 690.264 -140.285 640.22 -147.5 587.034C-152.178 552.559 -148.774 515.706 -136.782 471.061C-132.279 454.304 -127.002 437.477 -121.899 421.197C-115.557 400.972 -108.999 380.055 -103.879 359.108C-93.2723 315.72 -92.7021 276.44 -102.093 235.496C-127.101 126.459 -137.55 13.5347 -147.657 -95.6772C-148.472 -104.475 -149.286 -113.278 -150.107 -122.075L-149.525 -122.128C-148.704 -113.33 -147.89 -104.527 -147.075 -95.7295C-136.974 13.459 -126.525 126.366 -101.523 235.368C-92.1144 276.4 -92.6788 315.767 -103.315 359.248C-108.441 380.212 -115.004 401.135 -121.346 421.371C-126.449 437.64 -131.726 454.467 -136.224 471.212C-148.192 515.781 -151.596 552.559 -146.93 586.952C-139.721 640.074 -121.212 690.084 -99.562 745.283C-95.396 755.908 -91.0729 766.695 -86.8953 777.133C-69.7135 820.033 -51.9498 864.386 -39.8475 909.758C-31.4631 941.195 -27.3437 967.471 -26.884 992.438C-26.6338 1005.94 -27.5939 1013.45 -34.1571 1025.02C-38.8292 1033.24 -43.0651 1040.95 -47.1205 1048.59C-77.9 1106.56 -100.086 1168.23 -113.055 1231.89Z" fill="url(#paint101_linear_15_4142)"/>
<path d="M-131.866 1234.68L-132.436 1234.57C-119.973 1168.48 -99.5562 1105.26 -71.7499 1046.68C-68.0378 1038.86 -64.087 1030.83 -60.0025 1022.82C-54.8939 1012.79 -54.0037 1005.14 -54.091 993.695C-54.2888 967.658 -58.065 940.235 -65.9664 907.396C-76.5036 863.618 -91.87 820.457 -106.73 778.716C-110.216 768.935 -113.817 758.817 -117.297 748.861C-135.689 696.234 -152.719 644.624 -159.032 591.869C-163.274 556.429 -160.69 519.22 -150.91 474.779C-147.244 458.115 -142.903 441.323 -138.703 425.078C-133.321 404.254 -127.758 382.72 -123.551 361.244C-115.115 318.153 -115.062 279.512 -123.383 239.639C-146.04 131.026 -155.593 18.8644 -164.833 -89.6027C-165.648 -99.1624 -166.462 -108.722 -167.283 -118.282L-166.701 -118.334C-165.88 -108.774 -165.066 -99.2148 -164.251 -89.6551C-155.012 18.7945 -145.458 130.939 -122.807 239.517C-114.475 279.466 -114.527 318.182 -122.975 361.349C-127.182 382.836 -132.75 404.382 -138.132 425.217C-142.327 441.451 -146.668 458.243 -150.334 474.895C-160.103 519.272 -162.68 556.411 -158.444 591.787C-152.137 644.485 -135.118 696.059 -116.738 748.658C-113.259 758.613 -109.657 768.731 -106.172 778.512C-91.3056 820.265 -75.9334 863.444 -65.3904 907.251C-57.4773 940.136 -53.6953 967.599 -53.4975 993.683C-53.4102 1005.22 -54.3063 1012.94 -59.473 1023.07C-63.5576 1031.09 -67.5083 1039.11 -71.2146 1046.93C-98.9976 1105.47 -119.397 1168.63 -131.854 1234.68L-131.866 1234.68Z" fill="url(#paint102_linear_15_4142)"/>
<path d="M-150.56 1237.23L-151.137 1237.13C-139.476 1168.09 -121.224 1103.02 -96.8797 1043.72C-95.5938 1040.59 -94.2847 1037.55 -93.0221 1034.61C-86.6975 1019.92 -81.2398 1007.25 -81.3387 990.379C-81.4899 964.946 -84.8065 937.117 -91.4744 905.301C-100.452 862.484 -113.689 819.992 -126.49 778.896C-129.44 769.43 -132.488 759.643 -135.433 750.014C-151.183 698.526 -165.77 648.005 -171.152 596.075C-174.858 560.31 -172.985 521.879 -165.421 478.601C-162.494 461.862 -158.991 444.942 -155.599 428.58C-151.235 407.524 -146.726 385.745 -143.404 364.13C-136.876 321.649 -137.289 281.636 -144.672 241.803C-164.548 134.564 -173.031 24.1649 -181.241 -82.5974C-182.038 -92.9658 -182.835 -103.328 -183.644 -113.697L-183.062 -113.743C-182.253 -103.381 -181.456 -93.0123 -180.659 -82.6439C-172.455 24.1068 -163.972 134.488 -144.096 241.693C-136.701 281.595 -136.282 321.673 -142.822 364.217C-146.15 385.85 -150.659 407.634 -155.023 428.697C-158.41 445.052 -161.912 461.967 -164.839 478.7C-172.391 521.926 -174.265 560.298 -170.564 596.012C-165.188 647.894 -150.613 698.381 -134.868 749.839C-131.924 759.468 -128.875 769.255 -125.925 778.722C-113.119 819.829 -99.882 862.332 -90.8983 905.179C-84.2188 937.035 -80.9023 964.905 -80.751 990.373C-80.6521 1007.36 -86.1331 1020.09 -92.481 1034.84C-93.7436 1037.78 -95.0527 1040.81 -96.3328 1043.94C-120.66 1103.2 -138.9 1168.22 -150.555 1237.22L-150.56 1237.23Z" fill="url(#paint103_linear_15_4142)"/>
<path d="M-169.115 1239.48L-169.691 1239.4C-158.904 1165.15 -143.369 1099.04 -122.19 1037.32C-121.084 1034.1 -119.932 1030.92 -118.815 1027.84C-113.875 1014.25 -109.215 1001.4 -109.191 986.381C-109.157 962.322 -111.589 936.232 -116.622 906.617C-124.034 863.002 -135.625 819.532 -146.837 777.482C-149.531 767.382 -152.318 756.932 -155 746.651C-167.201 699.894 -179.484 650.036 -183.9 599.764C-187.123 563.068 -185.896 524.381 -180.141 481.493C-178.163 466.732 -175.917 451.848 -173.747 437.459C-163.925 372.276 -154.651 310.711 -165.194 245.44C-182.352 139.253 -189.713 30.2452 -196.834 -75.173C-197.579 -86.1931 -198.324 -97.2074 -199.075 -108.228L-198.493 -108.268C-197.736 -97.254 -196.992 -86.2339 -196.247 -75.2138C-189.125 30.1928 -181.765 139.189 -164.612 245.347C-154.052 310.705 -163.332 372.316 -173.165 437.547C-175.335 451.936 -177.575 466.813 -179.554 481.575C-185.302 524.416 -186.53 563.062 -183.307 599.718C-178.89 649.948 -166.614 699.777 -154.424 746.511C-151.742 756.792 -148.955 767.236 -146.261 777.343C-135.049 819.398 -123.452 862.891 -116.04 906.529C-111.001 936.18 -108.569 962.305 -108.604 986.393C-108.627 1001.52 -113.305 1014.41 -118.262 1028.05C-119.38 1031.13 -120.532 1034.31 -121.637 1037.52C-142.799 1099.21 -158.328 1165.27 -169.11 1239.49L-169.115 1239.48Z" fill="url(#paint104_linear_15_4142)"/>
<path d="M-187.513 1241.41L-188.089 1241.34C-178.698 1162.17 -165.723 1092.76 -148.431 1029.16C-147.517 1025.8 -146.546 1022.43 -145.603 1019.18C-141.571 1005.22 -137.76 992.025 -137.725 977.316C-137.678 953.786 -139.604 928.377 -143.613 899.634C-149.496 857.491 -158.811 815.238 -167.818 774.369C-169.947 764.711 -172.147 754.726 -174.271 744.899C-196.369 642.524 -202.845 562.183 -195.269 484.385C-193.861 469.915 -192.261 455.299 -190.713 441.154C-183.475 375.028 -176.639 312.573 -185.483 247.086C-199.552 142.948 -205.638 36.3371 -211.526 -66.7596C-212.195 -78.4314 -212.858 -90.1089 -213.539 -101.781L-212.957 -101.816C-212.282 -90.1439 -211.613 -78.4663 -210.944 -66.7945C-205.056 36.2964 -198.97 142.89 -184.907 247.005C-176.051 312.561 -182.893 375.051 -190.137 441.212C-191.685 455.351 -193.285 469.967 -194.693 484.432C-202.263 562.16 -195.787 642.454 -173.706 744.765C-171.588 754.593 -169.383 764.577 -167.254 774.23C-158.241 815.104 -148.926 857.375 -143.043 899.541C-139.028 928.313 -137.097 953.752 -137.149 977.305C-137.178 992.095 -141.001 1005.32 -145.045 1019.33C-145.981 1022.57 -146.953 1025.94 -147.867 1029.3C-165.153 1092.86 -178.117 1162.24 -187.508 1241.4L-187.513 1241.41Z" fill="url(#paint105_linear_15_4142)"/>
<path d="M-205.737 1242.99L-206.318 1242.94C-198.51 1156.17 -188.566 1084.39 -175.015 1017.04C-174.387 1013.9 -173.724 1010.75 -173.078 1007.71C-169.918 992.712 -166.934 978.55 -166.951 963.236C-166.974 940.311 -168.481 915.629 -171.565 887.782C-175.952 848.159 -182.835 808.209 -189.497 769.575C-190.853 761.703 -192.209 753.83 -193.547 745.958C-210.391 646.795 -215.855 564.278 -210.746 486.264C-209.798 471.771 -208.698 457.079 -207.639 442.871C-202.938 379.898 -198.074 314.778 -204.992 250.176C-215.954 147.876 -220.702 43.4065 -225.298 -57.6247C-225.851 -69.8143 -226.41 -81.9981 -226.974 -94.1877L-226.392 -94.2167C-225.828 -82.033 -225.275 -69.8434 -224.716 -57.6538C-220.126 43.3657 -215.372 147.824 -204.416 250.106C-197.486 314.755 -202.356 379.904 -207.057 442.905C-208.116 457.114 -209.216 471.806 -210.164 486.299C-215.273 564.266 -209.81 646.742 -192.971 745.859C-191.633 753.732 -190.277 761.604 -188.921 769.476C-182.259 808.116 -175.376 848.077 -170.983 887.718C-167.899 915.589 -166.387 940.294 -166.369 963.236C-166.358 978.608 -169.348 992.799 -172.513 1007.82C-173.153 1010.87 -173.817 1014.02 -174.451 1017.15C-187.996 1084.48 -197.946 1156.24 -205.748 1242.99L-205.737 1242.99Z" fill="url(#paint106_linear_15_4142)"/>
<path d="M-223.768 1244.17L-224.35 1244.14C-218.595 1149.62 -211.654 1073.13 -202.507 1003.42C-202.024 999.729 -201.501 996.005 -201 992.398C-198.97 977.851 -196.864 962.805 -196.858 947.712C-196.846 925.48 -197.864 901.601 -199.953 874.714C-202.868 837.249 -207.476 799.319 -211.933 762.64C-212.911 754.604 -213.888 746.569 -214.848 738.534C-225.781 647.004 -229.627 564.743 -226.607 487.044C-226.119 474.529 -225.577 461.827 -225.048 449.538C-222.209 383.377 -219.276 314.958 -224.332 247.331C-231.664 149.279 -234.899 49.3704 -238.035 -47.2446C-238.448 -59.952 -238.861 -72.6595 -239.28 -85.3611L-238.698 -85.3786C-238.279 -72.6711 -237.866 -59.9695 -237.453 -47.2621C-234.323 49.3471 -231.082 149.249 -223.75 247.29C-218.694 314.958 -221.627 383.389 -224.466 449.568C-224.996 461.85 -225.537 474.552 -226.025 487.067C-229.045 564.737 -225.199 646.969 -214.266 738.47C-213.306 746.505 -212.329 754.54 -211.357 762.576C-206.9 799.261 -202.292 837.197 -199.377 874.673C-197.282 901.578 -196.27 925.468 -196.276 947.718C-196.282 962.857 -198.388 977.921 -200.424 992.485C-200.931 996.092 -201.448 999.816 -201.931 1003.5C-211.072 1073.19 -218.014 1149.67 -223.768 1244.18V1244.17Z" fill="url(#paint107_linear_15_4142)"/>
<path d="M-241.59 1244.92L-242.172 1244.9C-238.96 1141.23 -235.387 1061.93 -230.587 987.766C-225.252 905.354 -230.715 821.964 -235.992 741.321L-236.609 731.918C-242.183 646.533 -244.278 567.647 -243.009 490.745C-242.777 476.74 -242.503 462.508 -242.241 448.747C-240.99 383.61 -239.699 316.25 -242.416 249.937C-246.326 154.602 -248.048 57.6093 -249.718 -36.1896C-249.951 -49.1996 -250.184 -62.2038 -250.416 -75.2138L-249.834 -75.2254C-249.596 -62.2154 -249.369 -49.2112 -249.136 -36.2012C-247.466 57.5976 -245.744 154.585 -241.834 249.914C-239.117 316.244 -240.409 383.61 -241.66 448.759C-241.921 462.519 -242.195 476.751 -242.428 490.756C-243.696 567.641 -241.607 646.515 -236.033 731.883L-235.416 741.286C-230.133 821.947 -224.67 905.354 -230.005 987.807C-234.805 1061.96 -238.378 1141.26 -241.584 1244.92L-241.59 1244.92Z" fill="url(#paint108_linear_15_4142)"/>
<path d="M-259.179 1245.19H-259.761C-259.004 844.865 -259.016 538.042 -259.796 250.327C-260.052 158.827 -260.162 65.7958 -260.267 -24.1687L-260.313 -63.6235H-259.732L-259.685 -24.1687C-259.58 65.7958 -259.47 158.821 -259.22 250.316C-258.434 538.031 -258.422 844.86 -259.185 1245.18L-259.179 1245.19Z" fill="url(#paint109_linear_15_4142)"/>
</g>
<defs>
<linearGradient id="paint0_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint1_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint2_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint3_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint4_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint5_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint6_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint7_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint8_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint9_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint10_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint11_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint12_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint13_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint14_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint15_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint16_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint17_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint18_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint19_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint20_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint21_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint22_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint23_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint24_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint25_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint26_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint27_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint28_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint29_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint30_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint31_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint32_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint33_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint34_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint35_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint36_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint37_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint38_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint39_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint40_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint41_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint42_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint43_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint44_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint45_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint46_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint47_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint48_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint49_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint50_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint51_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint52_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint53_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint54_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint55_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint56_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint57_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint58_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint59_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint60_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint61_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint62_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint63_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint64_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint65_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint66_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint67_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint68_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint69_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint70_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint71_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint72_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint73_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint74_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint75_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint76_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint77_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint78_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint79_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint80_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint81_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint82_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint83_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint84_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint85_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint86_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint87_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint88_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint89_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint90_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint91_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint92_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint93_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint94_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint95_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint96_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint97_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint98_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint99_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint100_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint101_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint102_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint103_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint104_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint105_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint106_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint107_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint108_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint109_linear_15_4142" x1="720" y1="-211.254" x2="720" y2="1275.25" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
</defs>
</svg>

    


}