export default function CoinSvg(props) {
    return <svg {...props} 
    width="134" height="133" viewBox="0 0 134 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_15_10380)">
    <path d="M133.068 66.9601C133.068 103.432 103.505 133.007 67.0209 133.007C30.5366 133.007 0.973633 103.432 0.973633 66.9601C0.973633 30.4879 30.5486 0.912842 67.0209 0.912842C103.493 0.912842 133.068 30.4879 133.068 66.9601Z" fill="#FAB217"/>
    <path d="M66.6594 120.043C96.7277 120.043 121.103 95.6679 121.103 65.5996C121.103 35.5312 96.7277 11.156 66.6594 11.156C36.591 11.156 12.2158 35.5312 12.2158 65.5996C12.2158 95.6679 36.591 120.043 66.6594 120.043Z" fill="#F6CA10"/>
    <path d="M70.4118 119.104C97.8525 118.193 119.36 95.2104 118.449 67.7698C117.539 40.3292 94.556 18.8221 67.1153 19.7324C39.6747 20.6427 18.1676 43.6256 19.0779 71.0663C19.9882 98.5069 42.9712 120.014 70.4118 119.104Z" fill="#FAB217"/>
    <path d="M67.0211 124.04C35.5442 124.04 9.94141 98.4371 9.94141 66.9603C9.94141 35.4834 35.5442 9.88062 67.0211 9.88062C98.4979 9.88062 124.101 35.4834 124.101 66.9603C124.101 98.4371 98.4979 124.04 67.0211 124.04ZM67.0211 15.5741C38.6859 15.5741 15.6349 38.6251 15.6349 66.9603C15.6349 95.2955 38.6859 118.346 67.0211 118.346C95.3563 118.346 118.407 95.2955 118.407 66.9603C118.407 38.6251 95.3563 15.5741 67.0211 15.5741Z" fill="#D78E1C"/>
    <path d="M96.1724 65.2514C94.8603 60.8097 92.7298 56.8014 89.9733 53.419L92.814 51.4088L97.689 47.9542L86.9279 31.8245L80.1992 36.4347L88.7214 47.0755L87.8427 47.6292L85.6159 49.0375C81.6196 45.7394 76.8529 43.5366 71.677 42.8384V50.9634C74.1085 51.3968 76.4075 52.2755 78.502 53.5153L71.677 57.8125V108.741C74.5538 108.356 77.3224 107.489 79.8862 106.238C87.5538 102.494 93.5483 95.2116 96.1844 86.232C97.2557 82.5968 96.377 78.6607 93.8372 75.832L93.8011 75.7838C96.365 72.931 97.2557 68.9468 96.1603 65.2755L96.1724 65.2514ZM90.3344 78.9135C89.4798 86.6172 85.5075 93.2255 79.8862 97.1375V96.6801L79.6816 84.0894L79.4168 67.3458L79.3085 60.9542L83.7863 57.7884C87.3131 61.6523 89.6964 66.8042 90.3344 72.594C90.4548 73.6412 82.4381 71.2699 82.4381 75.7597C82.4381 81.1403 90.4427 77.8903 90.3344 78.9135ZM43.2816 75.7597L43.2455 75.8079C40.7057 78.6366 39.8149 82.5727 40.8983 86.2079C43.5585 95.1875 49.5288 102.47 57.1964 106.213C59.7603 107.477 62.5288 108.332 65.4057 108.717V57.8005L58.5807 53.5032C60.6751 52.2634 62.9742 51.3968 65.4057 50.9514V42.8264C60.2298 43.5245 55.4631 45.7273 51.4668 49.0255L49.2399 47.6171L48.3612 47.0634L56.8835 36.4227L50.1427 31.8125L39.3816 47.9542L44.2566 51.4088L47.0973 53.419C44.3529 56.7894 42.2103 60.8097 40.8983 65.2514C39.8029 68.9227 40.7057 72.919 43.2575 75.7597H43.2816ZM54.6325 75.7597C54.6325 71.2699 46.6279 73.6292 46.7362 72.594C47.3862 66.8162 49.7816 61.6523 53.2844 57.7884L57.7622 60.9542L57.6538 67.3458L57.389 84.0894L57.1844 96.6801V97.1375C51.5631 93.2255 47.5909 86.6051 46.7362 78.9135C46.6159 77.8783 54.6325 81.1403 54.6325 75.7597Z" fill="#F6CA10"/>
    <path d="M94.1423 63.6872C92.8303 59.2455 90.6997 55.2372 87.9432 51.8548L90.784 49.8446L95.659 46.39L84.8979 30.2603L78.1691 34.8705L86.6914 45.5113L85.8127 46.065L83.5858 47.4733C79.5895 44.1751 74.8228 41.9724 69.6469 41.2742V49.3992C72.0784 49.8326 74.3775 50.7113 76.4719 51.9511L69.6469 56.2483V107.177C72.5238 106.792 75.2923 105.925 77.8562 104.673C85.5238 100.93 91.5182 93.6474 94.1543 84.6678C95.2256 81.0326 94.3469 77.0965 91.8071 74.2678L91.771 74.2196C94.3349 71.3668 95.2256 67.3826 94.1303 63.7113L94.1423 63.6872ZM88.3043 77.3492C87.4497 85.0529 83.4775 91.6613 77.8562 95.5733V95.1159L77.6516 82.5252L77.3867 65.7816L77.2784 59.39L81.7562 56.2242C85.283 60.0881 87.6664 65.24 88.3043 71.0298C88.4247 72.077 80.408 69.7057 80.408 74.1955C80.408 79.5761 88.4127 76.3261 88.3043 77.3492ZM41.2515 74.1955L41.2154 74.2437C38.6756 77.0724 37.7849 81.0085 38.8682 84.6437C41.5284 93.6233 47.4988 100.906 55.1664 104.649C57.7302 105.913 60.4988 106.768 63.3756 107.153V56.2363L56.5506 51.939C58.6451 50.6992 60.9441 49.8326 63.3756 49.3872V41.2622C58.1997 41.9603 53.433 44.1631 49.4367 47.4613L47.2099 46.0529L46.3312 45.4992L54.8534 34.8585L48.1126 30.2483L37.3515 46.39L42.2265 49.8446L45.0673 51.8548C42.3228 55.2252 40.1802 59.2455 38.8682 63.6872C37.7728 67.3585 38.6756 71.3548 41.2275 74.1955H41.2515ZM52.6025 74.1955C52.6025 69.7057 44.5978 72.065 44.7062 71.0298C45.3562 65.252 47.7515 60.0881 51.2543 56.2242L55.7321 59.39L55.6238 65.7816L55.3589 82.5252L55.1543 95.1159V95.5733C49.533 91.6613 45.5608 85.0409 44.7062 77.3492C44.5858 76.3141 52.6025 79.5761 52.6025 74.1955Z" fill="#D78E1C"/>
    <path opacity="0.2" d="M67.0222 0.912842C51.3981 0.912842 37.0499 6.34155 25.7471 15.4054C23.3518 22.1582 22.0518 29.4286 22.0518 36.9999C22.0518 72.6897 50.9888 101.615 86.6786 101.615C103.735 101.615 119.251 95.0064 130.794 84.1972C132.275 78.6962 133.069 72.9184 133.069 66.9481C133.069 30.4879 103.494 0.912842 67.0222 0.912842Z" fill="#FFFAEF"/>
    </g>
    <defs>
    <clipPath id="clip0_15_10380">
    <rect width="132.094" height="132.082" fill="white" transform="translate(0.973633 0.912842)"/>
    </clipPath>
    </defs>
    </svg>
    
}