export default function LogoSvg(props) {
    return <svg {...props} 
    width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.24911 25.5656L2.22289 25.594C0.756427 27.2326 0.244074 29.5202 0.865352 31.6333C2.39838 36.8493 5.85373 41.0777 10.2874 43.2496C11.77 43.9797 13.3656 44.4786 15.0297 44.7037L15.0297 15.1235L11.0862 12.627C12.2985 11.9071 13.6258 11.4021 15.0297 11.1466L15.0297 6.42542C12.0383 6.83102 9.28691 8.10865 6.97526 10.0251L5.18203 8.88943L10.1059 2.70406L6.21884 0.0332031L1.09325e-09 9.41265L4.46191 12.5865C2.87241 14.5495 1.63993 16.8817 0.879472 19.4593C0.248108 21.5968 0.766512 23.9107 2.24709 25.5676L2.24911 25.5656ZM8.81086 25.5656C8.81086 22.9596 4.18354 24.3305 4.25011 23.7262C4.62328 20.3699 6.00704 17.3745 8.0383 15.1296L10.6243 16.9689L10.2874 37.7213L10.2874 37.987C7.03779 35.7157 4.74229 31.8706 4.25011 27.3989C4.18354 26.8006 8.81086 28.6907 8.81086 25.5656Z" fill="#49443C"/>
<g clip-path="url(#clip0_15_4176)">
<g clip-path="url(#clip1_15_4176)">
<path d="M38.4059 25.5833C38.4059 36.2956 29.723 44.9821 19.0072 44.9821C8.29135 44.9821 -0.391602 36.2956 -0.391602 25.5833C-0.391602 14.8711 8.29489 6.18457 19.0072 6.18457C29.7194 6.18457 38.4059 14.8711 38.4059 25.5833Z" fill="#FAB217"/>
<path d="M18.8998 41.1741C27.7312 41.1741 34.8905 34.0149 34.8905 25.1835C34.8905 16.3521 27.7312 9.19287 18.8998 9.19287C10.0684 9.19287 2.90918 16.3521 2.90918 25.1835C2.90918 34.0149 10.0684 41.1741 18.8998 41.1741Z" fill="#F6CA10"/>
<path d="M20.0018 40.8986C28.0614 40.6312 34.3783 33.8809 34.1109 25.8213C33.8436 17.7617 27.0932 11.4448 19.0336 11.7122C10.974 11.9795 4.65717 18.7299 4.92454 26.7895C5.1919 34.8491 11.9422 41.1659 20.0018 40.8986Z" fill="#FAB217"/>
<path d="M19.0061 42.3484C9.76102 42.3484 2.24121 34.8286 2.24121 25.5835C2.24121 16.3384 9.76102 8.8186 19.0061 8.8186C28.2512 8.8186 35.771 16.3384 35.771 25.5835C35.771 34.8286 28.2512 42.3484 19.0061 42.3484ZM19.0061 10.4908C10.6838 10.4908 3.91346 17.2611 3.91346 25.5835C3.91346 33.9058 10.6838 40.6761 19.0061 40.6761C27.3284 40.6761 34.0987 33.9058 34.0987 25.5835C34.0987 17.2611 27.3284 10.4908 19.0061 10.4908Z" fill="#D78E1C"/>
<path d="M27.5697 25.0806C27.1843 23.7761 26.5586 22.5988 25.749 21.6053L26.5833 21.0149L28.0151 20.0003L24.8545 15.2628L22.8782 16.6169L25.3813 19.7422L25.1232 19.9048L24.4691 20.3184C23.2954 19.3497 21.8954 18.7028 20.3751 18.4977L20.3751 20.8841C21.0893 21.0114 21.7646 21.2695 22.3797 21.6336L20.3751 22.8958L20.3751 37.854C21.2201 37.7409 22.0332 37.4864 22.7863 37.1187C25.0383 36.0192 26.799 33.8803 27.5732 31.2428C27.8879 30.1751 27.6298 29.0191 26.8838 28.1883L26.8732 28.1741C27.6263 27.3362 27.8879 26.166 27.5662 25.0877L27.5697 25.0806ZM25.855 29.0933C25.604 31.356 24.4373 33.2969 22.7863 34.4459L22.7863 34.3116L22.7262 30.6135L22.6484 25.6958L22.6166 23.8185L23.9318 22.8887C24.9676 24.0235 25.6676 25.5367 25.855 27.2372C25.8904 27.5448 23.5358 26.8483 23.5358 28.167C23.5358 29.7474 25.8868 28.7928 25.855 29.0933ZM12.0351 28.167L12.0245 28.1812C11.2785 29.012 11.0169 30.1681 11.3351 31.2358C12.1164 33.8732 13.87 36.0121 16.122 37.1116C16.8751 37.4828 17.6882 37.7338 18.5332 37.847L18.5332 22.8922L16.5286 21.6301C17.1438 21.2659 17.819 21.0114 18.5332 20.8806L18.5332 18.4942C17.013 18.6992 15.6129 19.3462 14.4392 20.3149L13.7851 19.9013L13.5271 19.7386L16.0301 16.6133L14.0503 15.2593L10.8896 20.0003L12.3215 21.0149L13.1558 21.6053C12.3498 22.5952 11.7205 23.7761 11.3351 25.0806C11.0134 26.1589 11.2785 27.3327 12.028 28.167L12.0351 28.167ZM15.369 28.167C15.369 26.8483 13.018 27.5413 13.0498 27.2372C13.2407 25.5402 13.9442 24.0235 14.973 22.8887L16.2882 23.8185L16.2564 25.6958L16.1786 30.6135L16.1185 34.3116L16.1185 34.4459C14.4675 33.2969 13.3008 31.3524 13.0498 29.0933C13.0144 28.7893 15.369 29.7474 15.369 28.167Z" fill="#F6CA10"/>
<path d="M26.973 24.6212C26.5877 23.3166 25.9619 22.1393 25.1523 21.1459L25.9866 20.5554L27.4185 19.5408L24.2578 14.8033L22.2815 16.1574L24.7846 19.2827L24.5265 19.4453L23.8725 19.859C22.6987 18.8903 21.2987 18.2433 19.7785 18.0382L19.7785 20.4246C20.4926 20.5519 21.1679 20.81 21.783 21.1741L19.7785 22.4363L19.7785 37.3946C20.6234 37.2814 21.4366 37.0269 22.1896 36.6592C24.4417 35.5597 26.2023 33.4208 26.9765 30.7834C27.2912 29.7157 27.0331 28.5596 26.2871 27.7288L26.2765 27.7146C27.0296 26.8767 27.2912 25.7065 26.9695 24.6282L26.973 24.6212ZM25.2583 28.6338C25.0073 30.8965 23.8406 32.8374 22.1896 33.9864L22.1896 33.8521L22.1295 30.1541L22.0517 25.2363L22.0199 23.359L23.3351 22.4292C24.371 23.5641 25.071 25.0772 25.2583 26.7778C25.2937 27.0853 22.9391 26.3889 22.9391 27.7076C22.9391 29.2879 25.2902 28.3333 25.2583 28.6338ZM11.4384 27.7076L11.4278 27.7217C10.6819 28.5525 10.4202 29.7086 10.7384 30.7763C11.5198 33.4137 13.2733 35.5526 15.5254 36.6521C16.2784 37.0234 17.0916 37.2744 17.9365 37.3875L17.9365 22.4327L15.9319 21.1706C16.5471 20.8065 17.2224 20.5519 17.9365 20.4211L17.9365 18.0347C16.4163 18.2398 15.0163 18.8867 13.8425 19.8554L13.1885 19.4418L12.9304 19.2792L15.4334 16.1539L13.4536 14.7998L10.293 19.5408L11.7248 20.5554L12.5592 21.1459C11.7531 22.1358 11.1238 23.3166 10.7384 24.6212C10.4167 25.6995 10.6819 26.8732 11.4314 27.7076L11.4384 27.7076ZM14.7723 27.7076C14.7723 26.3889 12.4213 27.0818 12.4531 26.7778C12.644 25.0808 13.3476 23.5641 14.3764 22.4292L15.6915 23.359L15.6597 25.2363L15.5819 30.1541L15.5218 33.8521L15.5218 33.9864C13.8708 32.8374 12.7041 30.893 12.4531 28.6338C12.4177 28.3298 14.7723 29.2879 14.7723 27.7076Z" fill="#8B5500"/>
<path opacity="0.2" d="M19.0071 6.18457C14.4181 6.18457 10.2039 7.77904 6.8842 10.4412C6.18065 12.4246 5.79883 14.5599 5.79883 16.7837C5.79883 27.2662 14.2979 35.7618 24.7804 35.7618C29.7901 35.7618 34.3472 33.8208 37.7377 30.646C38.1725 29.0304 38.4059 27.3334 38.4059 25.5798C38.4059 14.8711 29.7194 6.18457 19.0071 6.18457Z" fill="#FFFAEF"/>
</g>
</g>
<defs>
<clipPath id="clip0_15_4176">
<rect width="20.204" height="38.7824" fill="white" transform="translate(18.1885 6.18506)"/>
</clipPath>
<clipPath id="clip1_15_4176">
<rect width="38.7975" height="38.794" fill="white" transform="translate(-0.391602 6.18457)"/>
</clipPath>
</defs>
</svg>




}